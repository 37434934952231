import Web3 from 'web3';
import { HttpProviderOptions } from 'web3-core-helpers';
import { ChainId } from '../types/mod';
import { getChain } from '../config/chains';

export const getWeb3NoAccount = (chainId: ChainId): Web3 => {
    const httpProvider = new Web3.providers.HttpProvider(
        getChain(chainId)?.rpcUrl ?? '',
        {
            timeout: 10000,
        } as HttpProviderOptions
    );
    return new Web3(httpProvider);
};
