import connectors from './connectors';
import { cleanWalletConnectCache, getWalletConnectProvider } from './walletConnect';

export const clearUserCache = (): void => {
    if (window?.localStorage?.getItem('walletconnect')) {
        connectors.walletconnect.close();
        connectors.walletconnect.walletConnectProvider = null;
    }
    window?.localStorage?.removeItem('walletconnect');

    const walletConnect = getWalletConnectProvider();
    if (walletConnect) {
        walletConnect.disconnect();
        cleanWalletConnectCache();
    }
};
