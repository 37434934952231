import React from 'react';
import CustomButton from '../../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { setApprovalForAll } from '../../../../../../net/contractCall/farm/fixRange/setApproveForAll';
import { useCallback } from 'react';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import { i_text_copy_bold } from '../../../../../../style';
import { useGasPrice } from '../../../../../../hooks/useGasPrice';
import { useLiquidityManagerContract } from '../../../../../../utils/contractFactory';
import { Contract } from 'web3-eth-contract';
import { getChain, getTxLink } from '../../../../../../config/chains';

const ApprovalForAllButton: React.FC<{
    miningContract: string;
    onSuccess?(): void;
}> = ({ miningContract, onSuccess }) => {
    const { account, chainId, web3 } = useWeb3WithDefault();
    const { gasPrice } = useGasPrice();
    const mgrContract = useLiquidityManagerContract(chainId, web3);
    const toast = useCustomToast();

    console.log('gasprice: ', gasPrice);

    const onApprove = useCallback(() => {
        if (mgrContract && miningContract && account) {
            console.log('approve');
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            setApprovalForAll(mgrContract as unknown as Contract, miningContract, account, true, gasPrice, chainId)
                .on('transactionHash', (hash: string) => {
                    if (chain) {
                        toastLink.title = 'View on ' + chain.name;
                        toastLink.link = getTxLink(hash, chain);
                    }
                    toast('info', 'Approving Mining Contract for Transferring iZiSwap NFTs.', undefined, toastLink);
                })
                .then(
                    () => {
                        console.log('set approve success');
                        toast('success', 'Approve successfully', undefined, toastLink);
                        onSuccess?.();
                    },
                    (e: any) => {
                        console.log(e);
                    }
                );
        }
    }, [mgrContract, miningContract, account, gasPrice, chainId, toast, onSuccess]);

    return (
        <CustomButton onClick={onApprove} variant="tertiary" text="Approve" fontClass={i_text_copy_bold} w="96px" h="40px"></CustomButton>
    );
};

export default ApprovalForAllButton;
