import { useSelector } from 'react-redux';
import { AiOutlineInfoCircle } from 'react-icons/ai';

import { noto_t3 } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { RootDispatch, RootState } from '../../../../../state/store';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import { isGasOrWrappedGasToken, getGasToken } from '../../../../../config/tokens';
import { ProControlState } from '../../../../../state/models/pro/controls/controlState';
import { HStack, Stack, Text, Image, useColorMode, Tooltip, IconButton, Icon, BoxProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = {
    themeColor: any;
    setFlashMode: (v: boolean) => void;
} & BoxProps;

const Controls: React.FC<Props> = (props) => {
    const { themeColor, setFlashMode, ...rest } = props;
    const { chainId } = useWeb3WithDefault();
    const { t } = useTranslation();

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { proControlState, proOrderFormState } = useSelector((state: RootState) => state);

    const checked = proControlState.tradeFlashMode;
    const useGasToken = proControlState.useGasToken;

    const gasToken = getGasToken(chainId);

    return (
        <Stack className={noto_t3} bg={themeColor} borderRadius="6px" py="6px" px="10px" alignItems="flex-end" spacing="-6px" {...rest}>
            <HStack ml="auto" spacing="13px">
                <HStack
                    cursor="pointer"
                    onClick={() => {
                        const v = !checked;
                        dispatch.proControlState.setNewState({ tradeFlashMode: v } as ProControlState);
                        setFlashMode(v);
                    }}
                >
                    <Text className={noto_t3} color="#7F4AFE">
                        {t('Flash Mode')}
                    </Text>
                    <Stack
                        borderRadius="2px"
                        _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                    >
                        <Image
                            boxSize="16px"
                            src={checked ? '/assets/pro/orders/checked.svg' : '/assets/pro/orders/unCheck.svg'}
                            fallbackSrc="/assets/pro/orders/unCheck.svg"
                        ></Image>
                    </Stack>
                </HStack>
                <Tooltip
                    label={
                        'If switch on, you accept the inconsistency of the liquidity condition between the time you place an order and the actual mining time. Use at your own risk.'
                    }
                >
                    <IconButton
                        size="sm"
                        aria-label=""
                        variant="ghost"
                        isRound={true}
                        icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                    />
                </Tooltip>
            </HStack>
            <HStack
                ml="auto"
                spacing="13px"
                hidden={
                    !isGasOrWrappedGasToken(proOrderFormState.baseToken, chainId) &&
                    !isGasOrWrappedGasToken(proOrderFormState.quoteToken, chainId)
                }
            >
                <HStack
                    cursor="pointer"
                    onClick={() => {
                        const v = !useGasToken;
                        dispatch.proControlState.setNewState({ useGasToken: v } as ProControlState);
                    }}
                >
                    <Text className={noto_t3} color="#7F4AFE">
                        Use {gasToken.symbol}
                    </Text>
                    <Stack
                        borderRadius="2px"
                        _hover={{ bg: colorTheme('linear-gradient(90deg, #F1F1F1 -6.17%, #F2F2F2 102.06%)', '#1B1631') }}
                    >
                        <Image
                            boxSize="16px"
                            src={useGasToken ? '/assets/pro/orders/checked.svg' : '/assets/pro/orders/unCheck.svg'}
                            fallbackSrc="/assets/pro/orders/unCheck.svg"
                        ></Image>
                    </Stack>
                </HStack>
                <Tooltip label={`If switch on, you will use ${gasToken.symbol} instead of the wrapped ERC-20 version token.`}>
                    <IconButton
                        size="sm"
                        aria-label=""
                        variant="ghost"
                        isRound={true}
                        icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                    />
                </Tooltip>
            </HStack>
        </Stack>
    );
};

export default Controls;
