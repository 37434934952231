import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol } from '../../../../types/mod';
import { tokenOrder } from './config';


export const getBaseQuoteOrder = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted, chainId: ChainId) : {
    baseToken: TokenInfoFormatted,
    quoteToken: TokenInfoFormatted,
    tokenAIsQuoteToken:boolean,
} => {

    const tokenAIdx = tokenOrder[chainId].findIndex((v: TokenSymbol)=> {
        return v === tokenA.symbol;
    });
    const tokenBIdx = tokenOrder[chainId].findIndex((v: TokenSymbol)=>{
        return v === tokenB.symbol;
    });
    let tokenAIsQuoteToken = false;
    if (tokenAIdx < 0 && tokenBIdx < 0) {
        tokenAIsQuoteToken = tokenA.symbol?.toLowerCase() < tokenB.symbol?.toLowerCase();
    } else if (tokenAIdx >= 0 && tokenBIdx < 0) {
        // tokenA is quote token
        tokenAIsQuoteToken = true;
    } else if (tokenAIdx < 0 && tokenBIdx >= 0) {
        // tokenB is quote token
        tokenAIsQuoteToken = false;
    } else {
        tokenAIsQuoteToken = tokenAIdx < tokenBIdx;
    }
    const quoteToken = tokenAIsQuoteToken ? {...tokenA} : {...tokenB};
    const baseToken = tokenAIsQuoteToken ? {...tokenB}: {...tokenA};
    return {baseToken, quoteToken,tokenAIsQuoteToken};
};


export const getBaseQuoteOrderBySymbol = (tokenA: string, tokenB: string, chainId: ChainId) : {
    baseToken: string,
    quoteToken: string,
    tokenAIsQuoteToken:boolean,
} => {

    const tokenAIdx = tokenOrder[chainId].findIndex((v: TokenSymbol)=> {
        return v === tokenA;
    });
    const tokenBIdx = tokenOrder[chainId].findIndex((v: TokenSymbol)=>{
        return v === tokenB;
    });
    let tokenAIsQuoteToken = false;
    if (tokenAIdx < 0 && tokenBIdx < 0) {
        tokenAIsQuoteToken = tokenA?.toLowerCase() < tokenB?.toLowerCase();
    } else if (tokenAIdx >= 0 && tokenBIdx < 0) {
        // tokenA is quote token
        tokenAIsQuoteToken = true;
    } else if (tokenAIdx < 0 && tokenBIdx >= 0) {
        // tokenB is quote token
        tokenAIsQuoteToken = false;
    } else {
        tokenAIsQuoteToken = tokenAIdx < tokenBIdx;
    }
    const quoteToken = tokenAIsQuoteToken ? tokenA : tokenB;
    const baseToken = tokenAIsQuoteToken ? tokenB: tokenA;
    return {baseToken, quoteToken,tokenAIsQuoteToken};
};