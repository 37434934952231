import { useTranslation } from 'react-i18next';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import { formatNumber } from '../../../utils/tokenMath';

export const TVL: React.FC<{ tvl?: number; type?: string }> = ({ tvl, type }) => {
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return (
        <Info
            w={{ base: 'unset', sm: '140px' }}
            label={t('TVL')}
            value={tvl === undefined ? '...' : `$${formatNumber(tvl)}`}
            tooltip={isMobile ? '' : t('The TVL is an estimation based on the liquidity provided in ' + type + ' and locked value.')}
            labelColor={isMobile ? '#A398BD' : 'unset'}
            valueStyle={isMobile ? { fontFamily: 'Montserrat-Medium' } : {}}
        />
    );
};
