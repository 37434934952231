import { BoxProps, useColorMode, HStack, Text, Stack } from '@chakra-ui/react';
import { TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h2 } from '../../../style';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { TokenIcons } from '../../Trade/components/TokenIcons';
import { FeeRate } from '../../Trade/components/FeeRate';
import CurrentPriceBlock from '../../components/CurrentPriceBlock';
import { formatNumber } from '../../../utils/tokenMath';
import { useMemo } from 'react';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { NONFUNGIBLE_POSITION_MANAGER_ADDRESSES } from '../../../config/miscellaneous/uniswapContracts';
import { getNftUrl } from '../../../config/links';
import { useTranslation } from 'react-i18next';

type ModalHeadProps = {
    index: number;
    entry: any;
    dispatch: any;
} & BoxProps;

export const ModalHead: React.FC<ModalHeadProps> = (props) => {
    const { entry, index, dispatch } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();

    const position = useMemo(() => {
        return [...(entry.positionList ?? []), ...(entry.stakedPositionList ?? [])][index];
    }, [entry, index]);

    const uniswapNftAddr = NONFUNGIBLE_POSITION_MANAGER_ADDRESSES[chainId];
    const viewNft = (nftId: string) => window.open(getNftUrl(chainId, uniswapNftAddr, nftId));

    return (
        <>
            <Card variant="deep" w="100%" h={{ base: 'unset', sm: '108px' }} mt="-10px">
                <Stack
                    w="100%"
                    direction={{ base: 'column', sm: 'row' }}
                    spacing="10px"
                    p={{ base: '14px 20px 14px 25px', sm: '14px 20px 14px 40px' }}
                    justifyContent="space-between"
                >
                    <HStack>
                        <TokenIcons
                            tokenA={entry.meta.tokenA || TokenSymbol.BIT}
                            tokenB={entry.meta.tokenB || TokenSymbol.ETH}
                            initialToggle={entry.meta.initialToggle}
                        />
                        <FeeRate
                            tokenA={entry.meta.tokenA || TokenSymbol.BIT}
                            tokenB={entry.meta.tokenB || TokenSymbol.ETH}
                            feeTier={entry.meta.feeTier as FeeTier}
                            initialToggle={entry.meta.initialToggle}
                        />
                    </HStack>
                    <CurrentPriceBlock
                        tokenA={entry.meta.tokenA || TokenSymbol.BIT}
                        tokenB={entry.meta.tokenB || TokenSymbol.ETH}
                        price={entry.data.priceAByBDecimal}
                        toggle={entry.meta.initialToggle}
                        handleToggle={() => {
                            dispatch.togglePoolMetaInitialToggle(entry.meta.positionPoolKey);
                        }}
                        w={{ base: '100%', sm: '241px' }}
                    />
                </Stack>
            </Card>

            <Card variant="deep" w="100%" h={{ base: 'unset', sm: '60px' }} mt="20px">
                <Stack
                    w="100%"
                    direction={{ base: 'column', sm: 'row' }}
                    p={{ base: '14px', sm: '10px 46px 14px 46px' }}
                    spacing="30px"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <HStack w={{ base: 'unset', sm: '40%' }} justifyContent="space-between" alignItems="center">
                        <HStack spacing="20px" alignItems="center">
                            <Text className={i_text_copy} color={colorTheme('#0166FF', 'tertiary.300')}>
                                NFT ID:
                            </Text>
                            <Text className={i_h2} fontSize="14px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                                {position.nftId}
                            </Text>
                        </HStack>
                        <CustomButton
                            variant="primary2"
                            text={t('View')}
                            onClick={() => viewNft(position.nftId)}
                            fontClass={i_text_copy_bold}
                            fontSize="12px"
                            w={{ base: '50px', sm: '100px' }}
                            h="30px"
                            ml={{ base: '25px !important', sm: '8px !important' }}
                        />
                    </HStack>

                    <HStack
                        //w="40%"
                        justifyContent="space-between"
                        mt={{ base: '10px !important', sm: '0 !important' }}
                    >
                        <Text className={i_text_copy} color={colorTheme('tertiary.500', 'tertiary.300')}>
                            {t('Price Range')}
                        </Text>
                        <Text className={i_text_copy} color={colorTheme('tertiary.900', 'tertiary.300')}>
                            {entry.meta.initialToggle
                                ? formatNumber(1 / position.maxPriceDecimal, 2, 1 / position.maxPriceDecimal > 1 ? 4 : 6) +
                                  ' - ' +
                                  formatNumber(1 / position.minPriceDecimal, 2, 1 / position.minPriceDecimal > 1 ? 4 : 6)
                                : formatNumber(position.minPriceDecimal, 2, position.minPriceDecimal > 1 ? 4 : 6) +
                                  ' - ' +
                                  formatNumber(position.maxPriceDecimal, 2, position.maxPriceDecimal > 1 ? 4 : 6)}
                        </Text>
                    </HStack>
                </Stack>
            </Card>
        </>
    );
};
