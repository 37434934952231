import React, { useMemo, useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Center, Heading, HStack } from '@chakra-ui/react';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { LimitOrder } from '../../../../../state/models/trade/limitOrder/types';
import ActiveListEntry from './ActiveListEntry';
import { LimitOrderHandler } from './MyLimitOrdersContainer';
import { ListSortByEnum } from '../../../../../types/mod';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../state/store';
import moment from 'moment';
import { TokenIcons } from '../../../components/TokenIcons';
import { FeeRate } from '../../../components/FeeRate';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';

export type ActiveListProps = {
    activeOrderList: LimitOrder[];
} & LimitOrderHandler;

export const ActiveList: React.FC<ActiveListProps> = (props) => {
    const { activeOrderList, handleCancelOrder, handleClaimOrder, handleRefreshOrderList } = props;
    const {
        tradeLimitOrder: { control },
    } = useSelector((state: RootState) => state);
    const [toggle] = useState(false);

    const activeListFiltered: LimitOrder[] = useMemo(() => {
        let resultList = [...activeOrderList];
        switch (control.sortBy) {
            case ListSortByEnum.TimeAsc:
                resultList.sort((a, b) => moment(a.createTime).unix() - moment(b.createTime).unix());
                break;
            case ListSortByEnum.TimeDesc:
                resultList.sort((b, a) => moment(a.createTime).unix() - moment(b.createTime).unix());
                break;
            default:
                break;
        }
        if (control.searchKey) {
            resultList = resultList.filter((pool) => {
                return pool.tokenX.symbol.includes(control.searchKey) || pool.tokenY.symbol.includes(control.searchKey);
            });
        }

        return resultList;
    }, [activeOrderList, control.searchKey, control.sortBy]);

    const groupedActiveList = useMemo(() => {
        return activeListFiltered.reduce((acc: any, e: LimitOrder) => {
            const key = e.tokenX.symbol + e.tokenY.symbol + String(e.feeTier);
            const r = acc.find((e: any) => e.key === key);
            r
                ? r.data.push(e)
                : acc.push({
                      key,
                      data: [e],
                      tokenX: e.tokenX,
                      tokenY: e.tokenY,
                      feeTier: e.feeTier,
                  });
            return acc;
        }, []);
    }, [activeListFiltered]);
    console.log(groupedActiveList);

    return (
        <Accordion allowMultiple={true} allowToggle={true}>
            {activeListFiltered.length && groupedActiveList.length > 0 ? (
                control.showByPair ? (
                    groupedActiveList.map(
                        (
                            entry: {
                                key: React.Key | null | undefined;
                                tokenX: TokenInfoFormatted;
                                tokenY: TokenInfoFormatted;
                                feeTier: FeeTier;
                                data: LimitOrder[];
                            },
                            i: React.Key | null | undefined
                        ) => (
                            <Card key={i} mt="0px" mb="15px">
                                <AccordionItem border="0">
                                    <AccordionButton h={{ base: '61px', xl: '82px' }} p="0">
                                        <HStack py="8px" pl="40px" pr="30px" spacing="20px" minW={{ base: '100%', sm: '660px' }} w="100%">
                                            <HStack spacing="28px" flexShrink={0} w="100%">
                                                <TokenIcons tokenA={entry.tokenX} tokenB={entry.tokenY} initialToggle={toggle} />
                                                <FeeRate
                                                    tokenA={entry.tokenX}
                                                    tokenB={entry.tokenY}
                                                    feeTier={entry.feeTier}
                                                    initialToggle={toggle}
                                                />
                                                <AccordionIcon ml="auto !important" />
                                            </HStack>
                                        </HStack>
                                    </AccordionButton>
                                    <AccordionPanel py={3}>
                                        {entry.data.map((e, i) => (
                                            <ActiveListEntry
                                                key={i}
                                                entry={e}
                                                handleCancelOrder={handleCancelOrder}
                                                handleClaimOrder={handleClaimOrder}
                                                handleRefreshOrderList={handleRefreshOrderList}
                                                isZip={control.showByPair}
                                                mb="15px"
                                            ></ActiveListEntry>
                                        ))}
                                    </AccordionPanel>
                                </AccordionItem>
                            </Card>
                        )
                    )
                ) : (
                    activeListFiltered.map((entry, i) => (
                        <ActiveListEntry
                            key={i}
                            entry={entry}
                            handleCancelOrder={handleCancelOrder}
                            handleClaimOrder={handleClaimOrder}
                            handleRefreshOrderList={handleRefreshOrderList}
                            isZip={control.showByPair}
                            mb="15px"
                        ></ActiveListEntry>
                    ))
                )
            ) : (
                <Center>
                    <Heading size="lg" color="tertiary.100">
                        No active limit order yet.
                    </Heading>
                </Center>
            )}
        </Accordion>
    );
};
