import React from 'react';
import { BoxProps, Stack, Image, useColorMode } from '@chakra-ui/react';
import { Text } from '../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { getColorThemeSelector } from '../../utils/funcs';
import { i_text_copy_bold } from '../../style';
import { TokenInfoFormatted } from '../../hooks/useTokenListFormatted';

type Props = {
    token: TokenInfoFormatted;
    variant?: 'base';
    icon?: string;
} & BoxProps;

export const TokenIconSymbol: React.FC<Props> = (props) => {
    const { token, variant, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const theme = {
        base: {
            imageSize: '15px',
            imageBorder: '5px',
            textColor: colorTheme('tertiary.900', 'tertiary.50'),
            textClass: i_text_copy_bold,
        },
    };

    return (
        <Stack direction="row" justifyContent="space-between" alignItems="center" {...rest}>
            <Image
                borderRadius={theme[variant || 'base'].imageBorder}
                h={theme[variant || 'base'].imageSize}
                w={theme[variant || 'base'].imageSize}
                flexShrink={0}
                src={token.icon}
            />
            <Text color={theme[variant || 'base'].textColor} variant="caption" className={theme[variant || 'base'].textClass}>
                {token.symbol}
            </Text>
        </Stack>
    );
};
