import { useWeb3React } from '@web3-react/core';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Web3 from 'web3';
import { provider } from 'web3-core';
import chains, { BaseChain, getChain } from '../config/chains';
import { RootState } from '../state/store';
import { ChainId } from '../types/mod';
import { HttpProviderOptions } from 'web3-core-helpers';

import { particle } from '../utils/particle';
import { ParticleProvider } from '@particle-network/provider';
import { ConnectorNames, DEFAULTCHAINID } from '../utils/connectors';
import { getWalletConnectProvider } from '../utils/walletConnect';
import { useSafeAppsSDK } from '@safe-global/safe-apps-react-sdk';
import { SafeAppProvider } from '@safe-global/safe-apps-provider';

export interface Web3WithOfflineChainData {
    web3: Web3;
    account?: string | null;
    chainId: ChainId;
    walletChainId?: ChainId;
    chainName: string;
    chainIcon: string;
    provider: provider;
}

interface Web3Config {
    chainId: number;
    provider: provider;
    web3: Web3;
}

export const getProvider = (connector: ConnectorNames) => {
    if (connector == ConnectorNames.BitKeep) {
        const provider = (window as any).bitkeep && (window as any).bitkeep.ethereum;
        if (!provider) {
            return window.open('https://bitkeep.com/en/download?type=2');
        }
        return provider;
    }

    if (connector == ConnectorNames.WalletConnect) {
        const provider = getWalletConnectProvider();
        if (provider) return provider;
    }
};

const CURRENT_CONNECTOR: { connector: ConnectorNames } = { connector: ConnectorNames.Injected };
const CHAINID_ETHEREUM: { value: number } = { value: DEFAULTCHAINID };
const ADDRESS_ETHEREUM: { value: string } = { value: '' };

export const getCurrentConnector = () => {
    return CURRENT_CONNECTOR.connector;
};

export const setCurrentConnector = (connector: ConnectorNames, account?: string) => {
    CURRENT_CONNECTOR.connector = connector;
    console.info('login by ', connector);

    if (account) {
        ADDRESS_ETHEREUM.value = account;
    }

    const provider = getProvider(connector);
    if (provider) {
        if (CHAINID_ETHEREUM.value == 0) {
            provider
                .request({
                    method: 'eth_chainId',
                })
                .then((chainId: any) => {
                    CHAINID_ETHEREUM.value = parseInt(chainId, 16);
                });
        }
        provider.autoRefreshOnNetworkChange = false;

        // provider.removeAllListeners();
        provider.on('accountsChanged', (accounts: any) => {
            ADDRESS_ETHEREUM.value = accounts[0];
        });

        provider.on('chainChanged', (chainId: any) => {
            CHAINID_ETHEREUM.value = parseInt(chainId, 16);
            console.info('chainId: ', CHAINID_ETHEREUM.value);
        });
    }
};

const ONLINE_CONTEXT = {} as { web3?: Web3; provider?: provider };
const OFFLINE_CONTEXT: { [index: number]: Web3 } = {};

const getWeb3FromContext = (library: provider, offlineChainId: ChainId): Web3 => {
    if (library) {
        if (ONLINE_CONTEXT.web3 !== undefined && ONLINE_CONTEXT.provider === library) {
            return ONLINE_CONTEXT.web3;
        }
        console.log('new web3 by provider: ', library);
        ONLINE_CONTEXT.web3 = new Web3(library);
        ONLINE_CONTEXT.provider = library;
        // print revert reason
        ONLINE_CONTEXT.web3.eth.handleRevert = false;
        return ONLINE_CONTEXT.web3;
    } else {
        if (!(offlineChainId in OFFLINE_CONTEXT)) {
            console.log('new offline provider for chain:', offlineChainId);
            const httpProvider = new Web3.providers.HttpProvider(getChain(offlineChainId)!.rpcUrl, {
                timeout: 10000,
            } as HttpProviderOptions);
            OFFLINE_CONTEXT[offlineChainId] = new Web3(httpProvider);
        }
        return OFFLINE_CONTEXT[offlineChainId];
    }
};

const PARTICLE_CONTEXT: { [index: number]: ParticleProvider } = {};
const PARTICLE_WEB3_CONTEXT: { [index: number]: Web3 } = {};

const getPraticleWeb3 = (chainId: any): [ParticleProvider, Web3] => {
    if (!PARTICLE_WEB3_CONTEXT[chainId]) {
        const provider = new ParticleProvider(particle.auth);
        PARTICLE_CONTEXT[chainId] = provider;
        PARTICLE_WEB3_CONTEXT[chainId] = new Web3(provider as any);
    }
    return [PARTICLE_CONTEXT[chainId], PARTICLE_WEB3_CONTEXT[chainId]];
};

const WEB3_CONTEXT: { [connector: string]: Web3Config } = {};

const getWeb3Config = (connector: ConnectorNames, library: provider, chainId: any): [provider, Web3] => {
    if (!WEB3_CONTEXT[connector] || WEB3_CONTEXT[connector].chainId != chainId) {
        WEB3_CONTEXT[connector] = {
            chainId: chainId,
            provider: library,
            web3: new Web3(library),
        };
    }
    return [WEB3_CONTEXT[connector].provider, WEB3_CONTEXT[connector].web3];
};

export const useWeb3WithDefault = (): Web3WithOfflineChainData => {
    const { chainId: walletChainId, account, library } = useWeb3React();
    const { sdk, safe } = useSafeAppsSDK();
    const { account: accountModel } = useSelector((state: RootState) => state);
    const chainId = walletChainId ?? accountModel.offlineChainId;

    const refEth = useRef(library);
    const [web3, setWeb3] = useState(getWeb3FromContext(library, chainId));
    const [address, setAddress] = useState('');

    const [particleChainId, setParticleChainId] = useState(DEFAULTCHAINID);

    useEffect(() => {
        if (library !== refEth.current || !library) {
            setWeb3(getWeb3FromContext(library, chainId));
            refEth.current = library;
        }
    }, [library, chainId]);

    if (particle.auth.isLogin()) {
        let particleProvider: ParticleProvider;
        let particleWeb3: Web3;

        [particleProvider, particleWeb3] = getPraticleWeb3(particleChainId);

        if (particleWeb3) {
            particleWeb3.eth.getAccounts((error, accounts) => {
                if (error) throw error;
                setAddress(accounts[0]);
            });
            particleWeb3.eth.getChainId((error, chainId) => {
                if (error) throw error;
                setParticleChainId(chainId);
            });
        }

        const chain = chains.all.find((e) => {
            return e.id === particleChainId;
        }) as BaseChain;

        return {
            web3: particleWeb3,
            account: address,
            chainId: particleChainId,
            walletChainId: particleChainId,
            chainName: chain.name,
            chainIcon: chain.icon,
            provider: particleProvider as any,
        };
    }

    let provider = getProvider(CURRENT_CONNECTOR.connector);
    if ((provider && provider.request) || CURRENT_CONNECTOR.connector == ConnectorNames.Safe) {
        let connectMethod = 'eth_requestAccounts';
        if (CURRENT_CONNECTOR.connector == ConnectorNames.Safe) {
            connectMethod = 'eth_accounts';
            provider = new SafeAppProvider(safe, sdk);
            CHAINID_ETHEREUM.value = safe.chainId;
        }
        if (CHAINID_ETHEREUM.value == 0) {
            provider
                .request({
                    method: 'eth_chainId',
                })
                .then((chainId: any) => {
                    CHAINID_ETHEREUM.value = parseInt(chainId, 16);
                });
        }

        if (ADDRESS_ETHEREUM.value == '') {
            provider
                .request({
                    method: connectMethod,
                })
                .then((accounts: any) => {
                    ADDRESS_ETHEREUM.value = accounts[0];
                });
        }

        const [web3Provider, w3] = getWeb3Config(CURRENT_CONNECTOR.connector, provider as any, CHAINID_ETHEREUM.value);
        const chain = chains.all.find((e) => {
            return e.id === CHAINID_ETHEREUM.value;
        }) as BaseChain;

        return {
            web3: w3,
            account: ADDRESS_ETHEREUM.value,
            chainId: CHAINID_ETHEREUM.value,
            walletChainId: CHAINID_ETHEREUM.value,
            chainName: chain.name,
            chainIcon: chain.icon,
            provider: web3Provider as any,
        };
    }

    // TODO use map
    const chain = chains.all.find((e) => {
        return e.id === chainId;
    }) as BaseChain;
    // console.info("chainId:   ", chainId, "account:    ", account, "library:   ", library)
    return {
        web3,
        account: account,
        chainId: chainId,
        walletChainId: walletChainId,
        chainName: chain.name,
        chainIcon: chain.icon,
        provider: library,
    };
};
