import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, FarmFixRangeContractVersion, TokenSymbol } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    initialToggle?: boolean;

    veiZiBoost?: boolean;
    iZiBoost?: boolean;
    contractVersion?: FarmFixRangeContractVersion;

    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;
    isEnded?: boolean;
    additionalKey?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.EthereumMainnet),
                      miningContract: '0x8981c60ff02CDBbF2A6AC1a9F150814F9cF68f62',
                      feeTier: 0.01,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: true,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.USDT, ChainId.EthereumMainnet),
                      miningContract: '0x9f58193b717449d00c7dcaf5d9f6f5af48a09894',
                      feeTier: 0.05,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.BIT, TokenSymbol.ETH, ChainId.EthereumMainnet),
                      miningContract: '0x461b154b688D5171934D70F991C17d719082710C',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.16,
                      isEnded: true,
                  },
              ],
              [ChainId.Matic]: [
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.Matic),
                      miningContract: '0x01Cc44fc1246D17681B325926865cDB6242277A5',
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      isEnded: true,
                      feeCharged: 0.4,
                  },
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.USDT, ChainId.Matic),
                      miningContract: '0xC0840394978CbCDe9fCCcDE2934636853A524965',
                      feeTier: 0.05,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                  },
              ],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.Arbitrum),
                      miningContract: '0xB2DeceA19D58ebe10ab215A04dB2EDBE52E37fA4',
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.Arbitrum),
                      miningContract: '0xbE138aD5D41FDc392AE0B61b09421987C1966CC3',
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                      isEnded: true,
                      additionalKey: '02',
                  },
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.USDT, ChainId.Arbitrum),
                      miningContract: '0x0893eB041c20a34Ce524050711492Fa8377d838b',
                      feeTier: 0.05,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                  },
              ],
          }
        : {
              [ChainId.Rinkeby]: [
                  {
                      ...sortedToken(TokenSymbol.USDC, TokenSymbol.USDT, ChainId.Rinkeby),
                      miningContract: '0x7B619028b64d7675806A42Dd5B17dfE8aD4830D2',
                      feeTier: 0.05,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                  },
              ],
              [ChainId.BSCTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.USDT, ChainId.BSCTestnet),
                      miningContract: '0xCE7fe0A153EF73759d253372b783E12a04849F35',
                      feeTier: 0.05,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                  },
                  {
                      ...sortedToken(TokenSymbol.IONS, TokenSymbol.MATIC, ChainId.BSCTestnet),
                      miningContract: '0x48E1a4913B65ad2d3E92B84cB764974a31559137',
                      feeTier: 0.3,
                      twoRewards: false,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                  },
              ],
              [ChainId.Goerli]: [
                  {
                      ...sortedToken(TokenSymbol.ETH, TokenSymbol.swETH, ChainId.Goerli),
                      miningContract: '0x6953DEe956eEe39973F1e1090368Ba434CCa0d94',
                      feeTier: 0.05,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                  },
              ],
          };
