import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    Button,
    Flex,
    Input,
    InputGroup,
    InputRightElement,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Text,
    useColorMode,
} from '@chakra-ui/react';
import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch, RootState } from '../../../state/store';
import { i_text_copy_bold, i_text_copy } from '../../../style';
import { TransListSetting } from '../OrderHistory';
import LimitOrderList from './LimitOrderList';
import SwapList from './SwapList';
import LiquidityList from './LiquidityList';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useTranslation } from 'react-i18next';
import useIsPC from '../../../hooks/useIsPC';
import { Filter } from '../../Farm/Gallery/components/Filter';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import FarmList from './FarmList';
import _ from 'lodash';
import { tokenAddr2Token } from '../../../config/tokens';
import { useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';

export interface HistoryFormProps {
    tab: string;
    data: any;
    loading: boolean;
    totalPage: number;
    setting: TransListSetting;
    setCurrentPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
}

const HistoryForm: React.FC<HistoryFormProps> = (pros) => {
    const { tab, loading, setting, data, totalPage, setCurrentPage, setPageSize } = pros;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isPC = useIsPC();
    const isMobile = useIsMobile();
    const { chainId } = useWeb3WithDefault();
    const { tokenList } = useTokenListFormatted();

    const { historyOrder } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const {
        historyOrder: { control },
    } = useSelector((state: RootState) => state);

    const onSearchKeyChange = useCallback(
        (e: any) => {
            const control = { ...historyOrder.control };
            control.searchKey = e.target.value;
            dispatch.historyOrder.setControl(control);
        },
        [historyOrder, dispatch]
    );

    const onTimeRemainChange = useCallback(
        (value: number) => {
            const control = { ...historyOrder.control };
            control.timeRemain = value;
            dispatch.historyOrder.setControl(control);
        },
        [historyOrder, dispatch]
    );

    const onSortByChange = useCallback(
        (value: string) => {
            const control = { ...historyOrder.control };
            control.sortBy = value;
            dispatch.historyOrder.setControl(control);
        },
        [historyOrder, dispatch]
    );
    const getToken = (address: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.address === address),
        };
        if (_.isEmpty(tempToken)) {
            const findToken = tokenAddr2Token(address, chainId);
            if (findToken.address) {
                return findToken;
            }
        }
        return tempToken;
    };

    const swap_HistoryData = useMemo(() => {
        if (data && data.length > 0 && (tab === 'Swap' || tab === 'Liquidity' || tab === 'Limit Order')) {
            let resultList = [...data];

            if (control.searchKey) {
                resultList = resultList.filter((item) => {
                    return (
                        item.tokenX.toLowerCase().includes(control.searchKey.toLowerCase()) ||
                        item.tokenY.toLowerCase().includes(control.searchKey.toLowerCase())
                    );
                });
            }

            return resultList;
        }
        return data;
    }, [control.searchKey, data]);
    const farm_HistoryData = useMemo(() => {
        if (data && data.length > 0 && tab === 'Farm') {
            let resultList = [...data];

            if (control.searchKey) {
                resultList = resultList.filter((item) => {
                    const tokenA = getToken(item.tokenx_addr);
                    const tokenB = getToken(item.tokeny_addr);
                    if (!tokenA || !tokenB) {
                        return [];
                    }
                    return (
                        (tokenA.symbol && tokenA.symbol.toLowerCase().includes(control.searchKey.toLowerCase())) ||
                        (tokenB.symbol && tokenB.symbol.toLowerCase().includes(control.searchKey.toLowerCase()))
                    );
                });
            }

            return resultList;
        }
        return data;
    }, [control.searchKey, data]);

    const ShowingBlock = () => (
        <Flex alignItems="center">
            <Text className={i_text_copy} mr="13px">
                {t('Showing')}
            </Text>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_copy_bold}
                            fontSize="14px"
                            w={{ base: '155px', sm: '178px' }}
                            h="40px"
                        >
                            {t('Last')} {control.timeRemain} {t('days')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem
                                onClick={() => {
                                    onTimeRemainChange(30);
                                }}
                            >
                                {t('Last') + ' 30 ' + t('days')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    onTimeRemainChange(7);
                                }}
                            >
                                {t('Last') + ' 7 ' + t('days')}
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </Flex>
    );

    const TimeBlock = () => (
        <Flex alignItems="center" ml="auto">
            <Text className={i_text_copy} mr={{ base: '22px', sm: '13px' }}>
                {t('Sort by')}
            </Text>

            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_copy_bold}
                            fontSize="14px"
                            w={{ base: '155px', sm: '178px' }}
                        >
                            {control.sortBy === '-time' ? t('Time Desc') : t('Time Asc')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem
                                onClick={() => {
                                    onSortByChange('-time');
                                }}
                            >
                                {t('Time Desc')}
                            </MenuItem>
                            <MenuItem
                                onClick={() => {
                                    onSortByChange('time');
                                }}
                            >
                                {t('Time Asc')}
                            </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </Flex>
    );

    return (
        <Flex
            h={{ base: 'unset', xxl: '639px' }}
            ml={{ base: '0px', xxl: '0px' }}
            mb="60px"
            direction="column"
            mt={{ base: '0px', sm: '16px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
            pl={{ base: '0px', sm: '7px', xxl: '0px' }}
        >
            <Flex w="100%">
                {isMobile && (
                    <Filter
                        h="100%"
                        ml="auto !important"
                        filterTitle={t('Order Filter')}
                        filterElement={
                            isMobile
                                ? [
                                      <InputGroup key={1} w={{ base: '100%', sm: '310px' }} h="41px" ml="15px">
                                          <Input
                                              h="100%"
                                              variant="filled"
                                              placeholder={t('Search Tokens or Pools')}
                                              size="lg"
                                              className={i_text_copy_bold}
                                              fontSize="12px"
                                              onChange={(e) => onSearchKeyChange(e)}
                                              value={historyOrder.control.searchKey || ''}
                                          />
                                          <InputRightElement h="100%" mt={{ base: '0px', sm: '4px' }}>
                                              <SearchIcon boxSize={5} />
                                          </InputRightElement>
                                      </InputGroup>,
                                      <ShowingBlock key={2} />,
                                      <TimeBlock key={3} />,
                                  ]
                                : []
                        }
                    ></Filter>
                )}
                {!isMobile && (
                    <Flex w="100%">
                        <ShowingBlock />
                        <InputGroup w={{ base: '100%', sm: '310px' }} h="41px" ml="15px">
                            <Input
                                h="100%"
                                variant="filled"
                                placeholder={t('Search Tokens or Pools')}
                                size="lg"
                                className={i_text_copy_bold}
                                fontSize="12px"
                                onChange={(e) => onSearchKeyChange(e)}
                                value={historyOrder.control.searchKey || ''}
                            />
                            <InputRightElement h="100%" mt={{ base: '0px', sm: '4px' }}>
                                <SearchIcon boxSize={5} />
                            </InputRightElement>
                        </InputGroup>
                        <TimeBlock />
                    </Flex>
                )}
            </Flex>

            {tab === 'Swap' && (
                <SwapList
                    data={swap_HistoryData}
                    netWorkloading={loading}
                    totalPage={totalPage}
                    setting={setting}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                ></SwapList>
            )}

            {tab === 'Liquidity' && (
                <LiquidityList
                    data={swap_HistoryData}
                    netWorkloading={loading}
                    totalPage={totalPage}
                    setting={setting}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                ></LiquidityList>
            )}

            {tab === 'Limit Order' && (
                <LimitOrderList
                    data={swap_HistoryData}
                    netWorkloading={loading}
                    totalPage={totalPage}
                    setting={setting}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                ></LimitOrderList>
            )}
            {tab === 'Farm' && (
                <FarmList
                    data={farm_HistoryData}
                    netWorkloading={loading}
                    totalPage={totalPage}
                    setting={setting}
                    setCurrentPage={setCurrentPage}
                    setPageSize={setPageSize}
                ></FarmList>
            )}
        </Flex>
    );
};
export default HistoryForm;
