import { Flex, VStack, Text, useColorMode, Stack, HStack, useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../../hooks/useIsMobile';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import {
    getIziSwapGenericSummaryRecord,
    ResponseIziSwapSummaryNormalRecord,
    SummaryRecordTypeEnum,
} from '../../../../net/iZUMi-endpoints/src/restful/izumiSwapSummary';
import { i_text_24, i_text_cardTitle } from '../../../../style';
import { ChainId } from '../../../../types/mod';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { formatAmount } from '../../../../utils/tokenMath';
import '../../Home.css';

const HomePiece2: React.FC = () => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const isMobile = useIsMobile();
    const isMd = useBreakpointValue({ base: false, md: true, '2xl': true });
    const [cardFlow, setCardFlow] = useState(false);
    const [latestPoolInfo, setLatestPoolInfo] = useState<ResponseIziSwapSummaryNormalRecord>({} as ResponseIziSwapSummaryNormalRecord);

    useEffect(() => {
        getIziSwapGenericSummaryRecord<ResponseIziSwapSummaryNormalRecord[]>({
            chain_id: chainId,
            type: SummaryRecordTypeEnum.IZI_POOL_OF_CHAIN_LATEST,
        }).then((r) => {
            const latest = r.data.is_success ? r.data.data : [];
            setLatestPoolInfo(latest?.[0] ?? []);
        });
    }, [chainId]);

    const tabs = [
        {
            title: 'Total Value Locked',
            content: `$ ${formatAmount(latestPoolInfo?.tvl, 2, 2)}`,
            width: { base: '69px', md: '25%' },
        },
        {
            title: '24hrs Trading Volume',
            content: `$ ${formatAmount(latestPoolInfo?.volDay, 2, 2)}`,
            width: { base: '87px', md: '30%' },
        },
        {
            title: 'History Txn',
            content: latestPoolInfo?.swapCount ?? '-',
            width: { base: '81px', md: '25%' },
        },
        {
            title: 'History Users',
            content: latestPoolInfo?.uniqueUser ?? '-',
            width: { base: '46px', md: '20%' },
        },
    ];
    return !isMd ? (
        <Flex w="100%" justifyContent="center">
            <Stack
                className={cardFlow ? 'cardFlow' : 'cardLeave'}
                w={{ base: '363px', md: '720px', xl: '967px' }}
                bgColor={colorTheme('#FDFEFF', '#1B172C')}
                boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                alignItems="center"
                mb="32px"
                mt="15px"
                mx="13px"
                pt="13px"
                pb="17px"
                pl="37px"
                pr="32px"
                borderRadius="6px"
                justifyContent="center"
                onMouseOver={() => {
                    setCardFlow(true);
                }}
                onMouseLeave={() => {
                    setCardFlow(false);
                }}
            >
                {tabs.map((item, index) => {
                    return (
                        <VStack w="100%" h="100%" key={index} mx={{ base: '5px', md: '25px' }} justifyContent="center">
                            <HStack w="100%" justifyContent="space-between">
                                <Text className={i_text_cardTitle} color={colorTheme('tertiary.500', 'tertiary.400')}>
                                    {t(item.title)}
                                </Text>
                                <Text className={i_text_24} fontWeight="600">
                                    {item.content}
                                </Text>
                            </HStack>
                        </VStack>
                    );
                })}
            </Stack>
        </Flex>
    ) : (
        <Flex w="100%" justifyContent="center">
            <Flex
                className={cardFlow ? 'cardFlow' : 'cardLeave'}
                w={{ base: '363px', md: '720px', xl: '967px' }}
                h="91px"
                bgColor={colorTheme('#FDFEFF', '#1B172C')}
                boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                alignItems="center"
                mb="32px"
                mt="15px"
                mx="13px"
                borderRadius="6px"
                justifyContent="center"
                onMouseOver={() => {
                    setCardFlow(true);
                }}
                onMouseLeave={() => {
                    setCardFlow(false);
                }}
            >
                {tabs.map((item, index) => {
                    return (
                        <VStack w={item.width} h="100%" key={index} mx={{ base: '5px', md: '25px' }} justifyContent="center">
                            <VStack h="100%" textAlign="start">
                                <Text
                                    w="100%"
                                    mt="auto !important"
                                    className={i_text_cardTitle}
                                    color={colorTheme('tertiary.500', 'tertiary.400')}
                                >
                                    {t(item.title)}
                                </Text>
                                <Text
                                    w="100%"
                                    className={i_text_24}
                                    fontWeight="600"
                                    pt="3px"
                                    mb={{
                                        base: '20px !important',
                                        md: '27px !important',
                                    }}
                                >
                                    {item.content}
                                </Text>
                            </VStack>
                        </VStack>
                    );
                })}
            </Flex>
        </Flex>
    );
};

export default HomePiece2;
