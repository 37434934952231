import Web3 from 'web3';
import { UNIV3_QUOTERV2 } from '../../../../config/miscellaneous/uniswapContracts';
import { isGasToken, isWrappedGasToken } from '../../../../config/tokens';
import { QUOTER_ADDRESS } from '../../../../config/trade/tradeContracts';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId } from '../../../../types/mod';
import { BasePathQueryPlugin, BasePreQueryPlugin } from './BaseDexPlugin';
import { iZiSwapPathQueryPlugin } from './iZiSwap/iZiSwapPathQueryPlugin';
import { iZiSwapPreQueryPlugin } from './iZiSwap/iZiSwapPreQueryPlugin';
import { initiZiPreResult, iZiPreQueryResult } from './iZiSwap/types';
import { PANCAKE_SWAP_ROUTER_ADDRESS } from './pancake/config';
import { PancakePathQueryPlugin } from './pancake/PancakePathQueryPlugin';
import { PancakePreQueryPlugin } from './pancake/PancakePreQueryPlugin';
import { initPancakePreQueryResult, PancakePreQueryResult } from './pancake/types';
import { pancakeAvailable } from './pancake/utils';
import { initUniV3PreResult, UniV3PreQueryResult } from './uniswapv3/types';
import { UniV3PathQueryPlugin } from './uniswapv3/uniV3PathQueryPlugin';
import { UniV3PreQueryPlugin } from './uniswapv3/uniV3PreQueryPlugin';
import { PreQueryResult } from './utils';
import { WethPathQueryPlugin } from './weth/WethPathQueryPlugin';

export enum SwapTag {
    iZiSwap = 'iZiSwap',
    pancake = 'pancake',
    uniswapV3 = 'uniswapV3',
    weth9 = 'weth9',
}


export const SWAP_PROXY_ADDRESS = {
    [ChainId.BSCTestnet]: '0x022df311555BC4D410EE7bDb00d50a5aD27Ef37C',
    [ChainId.BSC]: '0xF2bd6078D51576B5c8d7e80AF3C4E2292e916985', //2023.03.21
    [ChainId.Arbitrum]: '0xe4A0b241D8345d86FB140D40c87C5fbDd685B9dd', //2023.03.21
    [ChainId.Matic]: '0x879bF5D67fAB468879618AcD69E85C02E33b1c0B', // not verify
} as Record<ChainId, string>;

export const getTokenPairKey = (tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): string => {
    if (tokenA.symbol < tokenB.symbol) {
        return tokenA.symbol + '-' + tokenB.symbol;
    }
    return tokenB.symbol + '-' + tokenA.symbol;
};
export const getSwapTags = (chainId: ChainId, tokenA: TokenInfoFormatted, tokenB: TokenInfoFormatted): SwapTag[] => {
    // check weth9 at first
    if ((isGasToken(tokenA, chainId) && 
        isWrappedGasToken(tokenB, chainId))) {
            return [SwapTag.weth9];
        }
    if ((isGasToken(tokenB, chainId) &&
        isWrappedGasToken(tokenA, chainId))) {
            return [SwapTag.weth9];
        } 
    const ret = [] as SwapTag[];
    if (QUOTER_ADDRESS[chainId]) {
        ret.push(SwapTag.iZiSwap);
    }
    if (pancakeAvailable(chainId, tokenA, tokenB)) {
        ret.push(SwapTag.pancake);
    }
    if (UNIV3_QUOTERV2[chainId] && SWAP_PROXY_ADDRESS[chainId]) {
        ret.push(SwapTag.uniswapV3);
    }
    // todo: fill other swap tags
    return ret;
};

export const initPreQueryResultList = (swapTag: SwapTag[], chainId: ChainId): PreQueryResult[] => {
    const ret: PreQueryResult[] = swapTag.map((value: SwapTag, idx: number): PreQueryResult => {
        if (value === SwapTag.iZiSwap) {
            return initiZiPreResult(chainId);
        } else if (value === SwapTag.pancake) {
            return initPancakePreQueryResult(chainId);
        } else if (value === SwapTag.uniswapV3) {
            return initUniV3PreResult(chainId);
        } else {
            // todo: fill other pre query result
            return undefined as unknown as PreQueryResult;
        }
    });
    return ret;
};

export const getPreQueryPlugins = (swapTag: SwapTag[], preQueryResult: PreQueryResult[], chainId: ChainId, web3: Web3): BasePreQueryPlugin[] => {

    const ret: BasePreQueryPlugin[] = swapTag.map((value: SwapTag, idx: number): BasePreQueryPlugin => {

        if (value === SwapTag.iZiSwap) {
            return new iZiSwapPreQueryPlugin(preQueryResult[idx] as iZiPreQueryResult, chainId, web3);
        } else if (value === SwapTag.pancake) {
            return new PancakePreQueryPlugin(preQueryResult[idx] as PancakePreQueryResult, chainId, web3);
        } else if (value === SwapTag.uniswapV3) {
            return new UniV3PreQueryPlugin(preQueryResult[idx] as UniV3PreQueryResult, chainId, web3);
        } else if (value === SwapTag.weth9) {
            return undefined as unknown as BasePreQueryPlugin;
        } else {
            // todo: fill other swap plugins
            return undefined as unknown as BasePreQueryPlugin;
        }
    });
    return ret;
};

export const getPathQueryPlugins = (swapTag: SwapTag[], preQueryResult: PreQueryResult[], config: {[swapTag: string]: any}, chainId: ChainId, web3: Web3): BasePathQueryPlugin[] => {
    const ret: BasePathQueryPlugin[] = swapTag.map((value: SwapTag, idx: number): BasePathQueryPlugin => {
        if (value === SwapTag.iZiSwap) {
            return new iZiSwapPathQueryPlugin(preQueryResult[idx] as iZiPreQueryResult, config[value], chainId, web3);
        } else if (value === SwapTag.pancake) {
            return new PancakePathQueryPlugin(preQueryResult[idx] as PancakePreQueryResult, undefined, chainId, web3);
        } else if (value === SwapTag.uniswapV3) {
            return new UniV3PathQueryPlugin(preQueryResult[idx] as UniV3PreQueryResult, undefined, chainId, web3);
        } else if (value === SwapTag.weth9) {
            return new WethPathQueryPlugin(preQueryResult[idx], undefined, chainId, web3);
        } else {
            // todo: fill other swap plugins
            return undefined as unknown as BasePathQueryPlugin;
        }
    });
    return ret;
};
