import { BoxProps } from '@chakra-ui/layout';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { HStack, Image, Stack, Tooltip, Icon, IconButton, Center, VStack, useColorMode } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_text_d } from '../../../style';
import { TokenIconSymbol } from '../../components/TokenIconSymbol';
import { formatNumber } from '../../../utils/tokenMath';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import useWindowDimensions from '../../../hooks/useWindowDimension';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { useTranslation } from 'react-i18next';

export interface VeiZiNftEntry {
    veiZiDecimal: number;
    nftId: string;
}

export type Props = {
    entry: VeiZiNftEntry;
    aprRaw?: any;
    aprBoost?: any;
    extraveiZi?: number;
} & BoxProps;

export const VeNFTBoostBlock: React.FC<Props> = (props) => {
    const { entry, aprRaw, aprBoost, extraveiZi, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { width } = useWindowDimensions();
    const changeLineWidth = 960;
    const isChangeLine = changeLineWidth > width ? true : false;

    const safeAprRaw = aprRaw ?? 0;
    const safeAprBoost = aprBoost ?? 0;

    const extraValue = extraveiZi ?? 0;

    return (
        <VStack
            bg={colorTheme('#FDFDFD', '#423854')}
            boxShadow={colorTheme('0px 0px 27px 5px rgba(218, 206, 230, 0.25)', 'undefined')}
            borderRadius="6px"
            pt="16px"
            px="30px"
            pb="16px"
            mt="25px !important"
            w="100%"
            {...rest}
        >
            <Stack direction={isChangeLine ? 'column' : 'row'} w="100%" alignItems="center">
                <VStack alignItems="start" w={isChangeLine ? '100%' : 'unset'}>
                    <Stack direction="row" h="35px" justifyContent="space-between" alignItems="center">
                        <Text color={colorTheme('tertiary.500', 'tertiary.300')} variant="caption">
                            {t('Boost APR with your veiZi NFT')}
                        </Text>
                        <Tooltip label={'You can stake a veiZi NFT. The veiZi in the NFT can then be used to boost up to 2.5X APR.'}>
                            <IconButton
                                size="sm"
                                aria-label=""
                                variant="ghost"
                                isRound={true}
                                icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                            />
                        </Tooltip>
                    </Stack>
                    {Number(entry.nftId) ? (
                        <VStack
                            w={isChangeLine ? '100%' : 'unset'}
                            bg={colorTheme('#fff', '#34294A')}
                            boxShadow={colorTheme('0px 0px 9px 5px rgba(240, 226, 254, 0.25)', '0px 0px 27px 5px rgba(29, 6, 51, 0.25)')}
                            borderRadius="6px"
                            border={colorTheme('0.5px solid #DDDAE1', '1px solid #8664CE')}
                            pb="10px"
                        >
                            <Stack
                                direction={isChangeLine ? 'column' : 'row'}
                                alignItems="center"
                                h={isChangeLine ? 'unset' : '31px'}
                                px="17px"
                                justifyContent="space-between"
                                w="100%"
                                borderBottom={colorTheme('1px solid', '0.5px solid')}
                                borderColor={colorTheme('tertiary.100', 'tertiary.600')}
                            >
                                <TokenIconSymbol
                                    mt={isChangeLine ? '10px' : 'unset'}
                                    token={
                                        {
                                            name: 'veiZi NFT',
                                            icon: '/assets/tokens/veiZi.svg',
                                        } as unknown as TokenInfoFormatted
                                    }
                                />

                                <Text
                                    color="tertiary.300"
                                    variant="caption"
                                    w={isChangeLine ? '100%' : 'unset'}
                                    pb={isChangeLine ? '10px' : 'unset'}
                                    textAlign={isChangeLine ? 'center' : 'unset'}
                                >
                                    NFT id: {entry.nftId}
                                </Text>
                            </Stack>

                            <Center w="300px" h="58px">
                                <HStack>
                                    <Text
                                        fontSize="20px"
                                        variant="caption"
                                        className={i_text_copy}
                                        color={colorTheme('tertiary.800', 'tertiary.50')}
                                    >
                                        {formatNumber(entry.veiZiDecimal, 2, 6)}
                                    </Text>

                                    <Text
                                        fontSize="12px"
                                        variant="caption"
                                        className={i_text_copy}
                                        color={colorTheme('tertiary.400', 'tertiary.400')}
                                        mt="4px !important"
                                    >
                                        veiZi
                                    </Text>
                                </HStack>
                            </Center>
                        </VStack>
                    ) : (
                        <Card variant="light" w="300px" h="100px" px="20px">
                            <Center w="100%" h="100%">
                                <Text
                                    fontSize="12px"
                                    variant="caption"
                                    className={i_text_copy}
                                    color={colorTheme('tertiary.400', 'tertiary.400')}
                                    mt="4px !important"
                                >
                                    Currently you have no staked veiZi NFT. Try to stake one to boost your APR.
                                </Text>
                            </Center>
                        </Card>
                    )}

                    <Center w="100%">
                        <JumpButton
                            variant="tertiary"
                            fontClass={i_text_copy_bold}
                            text={Number(entry.nftId) ? t('Lock More') : t('Manage veiZi NFT')}
                            w="150px"
                            h="30px"
                            fontSize="12px"
                            target={Number(entry.nftId) ? '/veiZi/veNFT-manage' : '/veiZi/locker'}
                            param={{ nftId: entry.nftId }}
                        />
                    </Center>
                </VStack>

                <VStack w="300px">
                    <Center mt="20px !important">
                        <VStack alignItems="center">
                            <Text variant="caption" color="tertiary.400">
                                Base APR
                            </Text>
                            <Text variant="caption" color={colorTheme('tertiary.700', 'tertiary.100')} fontSize="18px">
                                {formatNumber(safeAprRaw * 100)}%
                            </Text>

                            <HStack mt="16px !important" mb="12px !important">
                                <Image h="13px" w="11px" src="/assets/farm/lightToV.svg" />
                                <Text variant="caption" color={colorTheme('secondary.600', 'secondary.300')}>
                                    {safeAprRaw > 0 ? formatNumber(safeAprBoost / safeAprRaw, 0, 2) : '1.0'}x
                                </Text>
                            </HStack>

                            <Text variant="caption" color={colorTheme('secondary.600', 'secondary.300')}>
                                Boosted APR
                            </Text>

                            <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.100')} fontSize="24px">
                                {formatNumber(safeAprBoost * 100)}%
                            </Text>
                        </VStack>
                    </Center>
                </VStack>
            </Stack>

            {extraValue > 0 && (
                <Card w="100%" variant="light" position="relative" p="10px" mt="16px !important">
                    <Center>
                        <Text variant="caption" color={colorTheme('tertiary.700', 'tertiary.100')} className={i_text_d} fontSize="14px">
                            An extra
                            <Text
                                variant="caption"
                                color={colorTheme('secondary.600', 'secondary.300')}
                                as="span"
                                mx="10px"
                                fontSize="16px"
                            >
                                {formatNumber(Number(extraValue))} veiZi
                            </Text>
                            is needed to boost 2.5x.
                        </Text>
                    </Center>
                </Card>
            )}
        </VStack>
    );
};
