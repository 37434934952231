import { SimpleGrid, Stack } from '@chakra-ui/react';
import { BRIDGE } from '../../config/bridge/config';
import { useWeb3WithDefault } from '../../hooks/useWeb3WithDefault';
import { PartnerCard } from '../Portal/components/PartnerCard';

export const BridgeBlock: React.FC = () => {
    const { chainId } = useWeb3WithDefault();

    return (
        <Stack w="100%">
            <SimpleGrid columns={{ base: 2, lg: 3, xxl: 4 }} spacing="18px" my="25px">
                {BRIDGE[chainId] &&
                    BRIDGE[chainId].map((item: any, index: number) => {
                        return (
                            <PartnerCard
                                key={index}
                                titleImgSrc={item.titleImgSrc}
                                title={item.title}
                                content={item.content}
                                link={item.link}
                            ></PartnerCard>
                        );
                    })}
            </SimpleGrid>
        </Stack>
    );
};
