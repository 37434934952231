import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmOneSideContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';
import { tokenSymbol2token } from '../../../tokens';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    initialToggle?: boolean;

    miningContract?: any;
    swapPoolAddress: string;

    tokenUni: TokenInfoFormatted;
    tokenLock: TokenInfoFormatted;

    useTimestamp?: boolean;

    twoRewards?: boolean;
    lockBoost?: boolean;
    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    noFee?: boolean;
    feeCharged?: number;
    allowStakeNFT?: boolean;
    contractVersion?: FarmOneSideContractVersion;
    isEnded?: boolean;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [],
              [ChainId.Matic]: [],
              [ChainId.Arbitrum]: [
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.slUSDC, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.slUSDC, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.Arbitrum),
                      miningContract: '0xbe53AC7D965D94B3786Bd7A9B0d4858E09cc3506',
                      swapPoolAddress: '0x40Ca43f1C90919a53bC0E5482E2347ED64cdfe6B',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.slUSDT, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.slUSDT, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.Arbitrum),
                      miningContract: '0x31406859b696D122d4B30f1f617226886793BD7c',
                      swapPoolAddress: '0x5B2292B257beB805F9ef3983B584E98641353E48',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.IUSD, ChainId.Arbitrum),
                      tokenUni: tokenSymbol2token(TokenSymbol.IUSD, ChainId.Arbitrum),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.Arbitrum),
                      miningContract: '0x02F91e7f9F0B2a0B62Ef0a2a5986870d99451C62',
                      swapPoolAddress: '0xD01Cb43b6CC8860dE7e8e7eF3F4992DC56aAe256',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
              ],
              [ChainId.BSC]: [
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.slUSDC, ChainId.BSC),
                      tokenUni: tokenSymbol2token(TokenSymbol.slUSDC, ChainId.BSC),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.BSC),
                      miningContract: '0xCe9Faae9E698c4E6A5E762dA82758850a45386A4',
                      swapPoolAddress: '0x1890b22f59a778b9a7be1539b3721effc7e22864',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.slUSDT, ChainId.BSC),
                      tokenUni: tokenSymbol2token(TokenSymbol.slUSDT, ChainId.BSC),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.BSC),
                      miningContract: '0x7c81bB6b240945675464Ead730cF8F871B014198',
                      swapPoolAddress: '0x8d898AAc91E05f502219cAC527577e2470b41bD5',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
                  {
                      ...sortedToken(TokenSymbol.slBTC, TokenSymbol.IUSD, ChainId.BSC),
                      tokenUni: tokenSymbol2token(TokenSymbol.IUSD, ChainId.BSC),
                      tokenLock: tokenSymbol2token(TokenSymbol.slBTC, ChainId.BSC),
                      miningContract: '0xE1216332fEa23404A526747EeaF204a12194A3B8',
                      swapPoolAddress: '0xc45b22a9e0ae7945c61b46edd71299cfd8276422',
                      feeTier: 0.2,
                      twoRewards: false,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: false,
                      allowStakeNFT: false,
                      useTimestamp: false,
                  },
              ],
          }
        : {
              [ChainId.Rinkeby]: [],
              [ChainId.BSCTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.BNB, TokenSymbol.IZI, ChainId.BSCTestnet),
                      tokenUni: tokenSymbol2token(TokenSymbol.BNB, ChainId.BSCTestnet),
                      tokenLock: tokenSymbol2token(TokenSymbol.IZI, ChainId.BSCTestnet),
                      miningContract: '0x8Bf3B3F83dA41ed74DB5BDAEE2FB0B4f26d8Fb6B',
                      swapPoolAddress: '0xeAC5db5c8AF54DfC361338083D05dB847708e7A5',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: true,
                      allowStakeNFT: false,
                      useTimestamp: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.USDT, TokenSymbol.IZI, ChainId.BSCTestnet),
                      tokenUni: tokenSymbol2token(TokenSymbol.USDT, ChainId.BSCTestnet),
                      tokenLock: tokenSymbol2token(TokenSymbol.IZI, ChainId.BSCTestnet),
                      miningContract: '0x9F5F0d69237bfb21bB83D26F760976411c328F07',
                      swapPoolAddress: '0x2e2fd832cB727365676316508BB2140e7f04602B',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: true,
                      allowStakeNFT: false,
                  },
              ],
              [ChainId.AuroraTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.IZI, TokenSymbol.ETH, ChainId.AuroraTestnet),
                      tokenUni: tokenSymbol2token(TokenSymbol.ETH, ChainId.AuroraTestnet),
                      tokenLock: tokenSymbol2token(TokenSymbol.IZI, ChainId.AuroraTestnet),
                      miningContract: '0xb9ED6cE58e498DB25cA2659A177E48D9b46FE393',
                      swapPoolAddress: '0x8F32F1d69E8dd996d4e5D0E9937B388cE67d9551',
                      feeTier: 0.2,
                      twoRewards: true,
                      iZiBoost: true,
                      lockBoost: false,
                      noFee: true,
                      allowStakeNFT: false,
                  },
              ],
          };
