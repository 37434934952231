import React, { useCallback } from 'react';

import { usePoolEntry } from './PoolListEntry';

import { BoxProps } from '@chakra-ui/react';
import { i_text_copy_bold } from '../../../../../../style';
import CustomButton from '../../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';

import { useGasPrice } from '../../../../../../hooks/useGasPrice';
import { useFixRangeiZiEntity, FixRangeStakeParams } from '../../../../../../state/models/hooks/farm/useFixRangeiZiEntity';
import { MiningCallbacks } from '../../../../../../state/models/hooks/farm/common/callbacks';
import { getChain, getTxLink } from '../../../../../../config/chains';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';

export type StakeButtonProps = {
    tokenId?: string;
    onSuccess?(): void;
} & BoxProps;

export const StakeButton: React.FC<StakeButtonProps> = (props) => {
    const { tokenId, onSuccess, ...rest } = props;
    const { account, chainId } = useWeb3WithDefault();
    const entry = usePoolEntry();
    const toast = useCustomToast();
    const { gasPrice } = useGasPrice();

    const fixRangeEntity = useFixRangeiZiEntity(entry);
    const onStake = useCallback(() => {
        if (tokenId && account) {
            const chain = getChain(chainId);
            const toastLink = {} as ToastLink;
            fixRangeEntity.stake(
                {
                    nftId: tokenId,
                    niZi: '0',
                } as FixRangeStakeParams,
                {
                    onTransactionHash: (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', 'Staking NFT to mining contract.', undefined, toastLink);
                    },
                    then: () => {
                        console.log('stake success');
                        toast('success', 'Staking successfully.', undefined, toastLink);
                        onSuccess?.();
                    },
                } as MiningCallbacks,
                gasPrice
            );
        }
    }, [tokenId, account, fixRangeEntity, gasPrice, toast, onSuccess]);

    return <CustomButton onClick={onStake} variant="primary2" text="Stake" fontClass={i_text_copy_bold} {...rest}></CustomButton>;
};

export default StakeButton;
