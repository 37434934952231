import { BoxProps, useColorMode, Divider, HStack, VStack, Text, Image, Box, Center, Stack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { i_text_copy_bold, i_text_copy, i_h5, i_h2 } from '../../../../../style';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { Modal } from '../../../../../iZUMi-UI-toolkit/src/components/Modal/Modal';
import { TokenIcons } from '../../../../Trade/components/TokenIcons';
import { FeeRate } from '../../../../Trade/components/FeeRate';
import CurrentPriceBlock from '../../../../components/CurrentPriceBlock';
import { PoolEntryState, PositionEntry } from '../../../../../state/models/farm/iZiSwap/oneSide/types';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../../../state/store';
import { formatNumber } from '../../../../../utils/tokenMath';
import { getFeeEarnedFromiZiLiquidity, getTokenAmountiZiLiquidity } from '../../../../../net/contractCall/uniV3Interface/getTokenOwned';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';

import { useEffect, useState, useMemo } from 'react';

import { getNftUrl } from '../../../../../config/links';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { LIQUIDITY_MANAGER_ADDRESS } from '../../../../../config/trade/tradeContracts';
import { useLiquidityManagerContract } from '../../../../../utils/contractFactory';
import { useTranslation } from 'react-i18next';

type PositionDetailModalProps = {
    isOpen: boolean | any;
    onClose: any;
    entry: PoolEntryState;
    index: number;
} & BoxProps;

export const PositionDetailModal: React.FC<PositionDetailModalProps> = (props) => {
    const { isOpen, onClose, entry, index, ...rest } = props;

    const { chainId, web3 } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();
    const positionManagerContract = useLiquidityManagerContract(chainId, web3);
    const [tokenAGainDecimal, setTokenAGainDecimal] = useState(0);
    const [tokenBGainDecimal, setTokenBGainDecimal] = useState(0);

    const position = useMemo(() => {
        const p = [...(entry.positionList ?? []), ...(entry.stakedPositionList ?? [])][index];
        if (p === undefined) {
            return {} as PositionEntry;
        }
        return p;
    }, [entry, index]);

    useEffect(() => {
        getFeeEarnedFromiZiLiquidity(positionManagerContract, position.nftId).then((r: { amountX: string; amountY: string }) => {
            console.log(' -------- fee: amountX: ', r.amountX);
            console.log(' -------- fee: amountY: ', r.amountY);
            setTokenAGainDecimal((parseFloat(r.amountX) / 10 ** entry.meta.tokenA.decimal) * (1 - (entry.meta.feeCharged ?? 0))); // after charge fee
            setTokenBGainDecimal((parseFloat(r.amountY) / 10 ** entry.meta.tokenB.decimal) * (1 - (entry.meta.feeCharged ?? 0)));
        });
        getTokenAmountiZiLiquidity(positionManagerContract, position.nftId, position.liquidity).then(
            (r: { amountX: string; amountY: string }) => {
                console.log(' -------- liquidity: amountX: ', r.amountX);
                console.log(' -------- liquidity: amountY: ', r.amountY);
            }
        );
    }, [position, positionManagerContract, entry, setTokenAGainDecimal, setTokenBGainDecimal]);

    const tokenAGainValue = tokenAGainDecimal * entry.data.tokenPriceADecimal;
    const tokenBGainValue = tokenBGainDecimal * entry.data.tokenPriceBDecimal;

    // console.log('tokenA gain value: ', tokenAGainValue);
    // console.log('tokenB gain value: ', tokenBGainValue);
    // console.log('tokenA: ', entry.meta.tokenA);
    // console.log('tokenB: ', entry.meta.tokenB);

    const tokenIconBlock = (token: TokenInfoFormatted, amount: any, value: any, key?: number) => {
        let amountDisplay = amount;
        let valueDisplay = value;
        if (amountDisplay !== 0) {
            amountDisplay = formatNumber(amountDisplay, 2, 4);
        }
        if (valueDisplay !== 0) {
            valueDisplay = formatNumber(valueDisplay, 2, 4);
        }

        return (
            <Stack w="100%" direction={{ base: 'column', sm: 'row' }} justifyContent="space-between" key={key}>
                <HStack>
                    <Image borderRadius="9px" w="18px" h="18px" src={token?.icon} />
                    <Text className={i_text_copy_bold} fontSize="16px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {token?.symbol}
                    </Text>
                </HStack>
                <VStack alignItems="end" spacing="0px">
                    <Text className={i_text_copy} fontSize="16px" color={colorTheme('tertiary.700', 'tertiary.50')}>
                        {amountDisplay}
                    </Text>
                    <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.300', 'tertiary.300')}>
                        ~ {valueDisplay} USD
                    </Text>
                </VStack>
            </Stack>
        );
    };

    const tokenLockedBlock = (token: TokenInfoFormatted) => (
        <HStack>
            <Image borderRadius="7px" w="14px" h="14px" src={token.icon} />
            <Text className={i_text_copy} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.400')}>
                {token.symbol} locked
            </Text>
        </HStack>
    );
    const iZiSwapNftAddr = LIQUIDITY_MANAGER_ADDRESS[chainId];

    const viewNft = (nftId: string) => window.open(getNftUrl(chainId, iZiSwapNftAddr, nftId));

    let earnedDecimal = position.earnedDecimal;
    if (!earnedDecimal) {
        earnedDecimal = [];
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} w={{ base: '73%', lg: '744px' }} h="750px" title={t('Liquidity Mining Status')} {...rest}>
            <Card variant="deep" w="100%" h={{ base: 'unset', sm: '108px' }} mt="-10px">
                <Stack
                    w="100%"
                    direction={{ base: 'column', sm: 'row' }}
                    spacing="10px"
                    p={{ base: '14px 20px 14px 25px', sm: '14px 20px 14px 40px' }}
                    justifyContent="space-between"
                >
                    <HStack>
                        <TokenIcons tokenA={entry.meta.tokenA} tokenB={entry.meta.tokenB} initialToggle={entry.meta.initialToggle} />
                        <FeeRate
                            tokenA={entry.meta.tokenA}
                            tokenB={entry.meta.tokenB}
                            feeTier={entry.meta.feeTier}
                            initialToggle={entry.meta.initialToggle}
                        />
                    </HStack>
                    <CurrentPriceBlock
                        maxPrecision={6}
                        tokenA={entry.meta.tokenA}
                        tokenB={entry.meta.tokenB}
                        price={entry.data.priceAByBDecimal}
                        toggle={entry.meta.initialToggle}
                        handleToggle={() => {
                            dispatch.farmOneSideiZi.togglePoolMetaInitialToggle(entry.meta.positionPoolKey);
                        }}
                        w={{ base: '100%', sm: '241px' }}
                    />
                </Stack>
            </Card>

            <Stack w="100%" direction={{ base: 'column', sm: 'row' }} spacing="16px" h={{ base: 'unset', sm: '454px' }} mt="30px">
                <Card variant="deep" w={{ base: '100%', sm: '328px' }} h={{ base: 'unset', sm: '454px' }}>
                    <VStack w="100%" p="25px 46px 14px 46px" spacing="30px" alignItems="center">
                        <Text className={i_h5} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.50')}>
                            {t('Your Liquidity')}
                        </Text>

                        <HStack w="100%" justifyContent="space-between" alignItems="center">
                            <HStack spacing="10px" alignItems="center">
                                <Text className={i_text_copy} color={colorTheme('#0166FF', 'tertiary.300')}>
                                    NFT ID:
                                </Text>
                                <Text className={i_h2} fontSize="14px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                                    {position.nftId}
                                </Text>
                            </HStack>
                            <CustomButton
                                variant="primary2"
                                text={t('View')}
                                onClick={() => viewNft(position.nftId)}
                                fontClass={i_text_copy_bold}
                                fontSize="12px"
                                w="70px"
                                h="30px"
                            />
                        </HStack>

                        <HStack w="100%" justifyContent="space-between" mt="0 !important">
                            <Text className={i_text_copy} color={colorTheme('tertiary.500', 'tertiary.300')}>
                                {t('Price Range')}
                            </Text>
                            <Text className={i_text_copy} color={colorTheme('tertiary.900', 'tertiary.300')}>
                                {entry.meta.initialToggle
                                    ? formatNumber(1 / position.maxPriceDecimal, 2, 4)
                                    : formatNumber(position.minPriceDecimal, 2, 4)}{' '}
                                -{' '}
                                {entry.meta.initialToggle
                                    ? formatNumber(1 / position.minPriceDecimal, 2, 4)
                                    : formatNumber(position.maxPriceDecimal, 2, 4)}
                            </Text>
                        </HStack>
                    </VStack>

                    {colorTheme(<Divider mt="0 !important" />, <></>)}

                    <VStack
                        w="100%"
                        p="30px 46px 14px 46px"
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start" spacing="20px">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                {t('Token in pools')}
                            </Text>
                            {tokenIconBlock(entry.meta.tokenB, position.amountBInUniswapDecimal, position.worthBInUniswap)}
                            {tokenIconBlock(entry.meta.tokenA, position.amountAInUniswapDecimal, position.worthAInUniswap)}
                            <Box h="0px"></Box>
                        </VStack>
                    </VStack>

                    <VStack
                        w="100%"
                        p="25px 46px 14px 46px"
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('linear-gradient(180deg, #FAFAFA 0%, rgba(250, 250, 250, 0) 100%)', 'undefined')}
                        position="relative"
                    >
                        <Box
                            position="absolute"
                            w="36px"
                            h="36px"
                            borderRadius="18px"
                            transform="translateX(-50%) translateY(-50%)"
                            top="0"
                            left="50%"
                            bg={colorTheme('#F8F8F8', 'tertiary.600')}
                        >
                            <Center>
                                <Text color={colorTheme('tertiary.400', 'tertiary.200')} fontSize="36px" mt="-12px !important">
                                    +
                                </Text>
                            </Center>
                        </Box>

                        <VStack w="100%" alignItems="center" alignSelf="center" spacing="5px" mt="0px !important">
                            {tokenLockedBlock(entry.meta.tokenLock)}
                            <Text className={i_h2} fontSize="24px" color={colorTheme('tertiary.700', 'tertiary.50')} fontWeight="500">
                                {formatNumber(position.amountLockDecimal, 2, 4)}
                            </Text>
                            <Text
                                className={i_text_copy}
                                fontSize="12px"
                                color={colorTheme('tertiary.400', 'tertiary.400')}
                                mb="-5px !important"
                            >
                                {t('Estimate Value')}
                            </Text>

                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.400', 'tertiary.400')} mb="4px">
                                {formatNumber(position.amountLockWorth, 2, 4)} USD
                            </Text>
                        </VStack>
                    </VStack>
                </Card>

                <Card variant="deep" w={{ base: '100%', sm: '328px' }} h={{ base: 'unset', sm: '454px' }}>
                    <VStack w="100%" p="25px 46px 14px 46px" spacing="30px" alignItems="center">
                        <Text className={i_h5} fontSize="14px" color={colorTheme('tertiary.500', 'tertiary.50')}>
                            {t('Your Reward')}
                        </Text>
                    </VStack>

                    {(entry.meta.feeCharged ?? 0) < 1 && (
                        <>
                            <VStack
                                w="100%"
                                p="20px 46px 14px 46px"
                                spacing="30px"
                                alignItems="center"
                                bg={colorTheme('undefined', 'tertiary.800')}
                            >
                                <VStack w="100%" alignItems="start" spacing="20px">
                                    <Text
                                        className={i_text_copy}
                                        fontSize="12px"
                                        color={colorTheme('tertiary.500', 'tertiary.200')}
                                        mb="4px"
                                    >
                                        {t('Fee Gains')}
                                    </Text>

                                    {tokenIconBlock(entry.meta.tokenA, tokenAGainDecimal, tokenAGainValue)}
                                    {tokenIconBlock(entry.meta.tokenB, tokenBGainDecimal, tokenBGainValue)}
                                </VStack>
                            </VStack>

                            {colorTheme(<Divider mt="15px !important" />, <></>)}
                        </>
                    )}

                    <VStack
                        w="100%"
                        p="30px 46px 14px 46px"
                        spacing="30px"
                        alignItems="center"
                        bg={colorTheme('undefined', 'tertiary.800')}
                    >
                        <VStack w="100%" alignItems="start" spacing="20px">
                            <Text className={i_text_copy} fontSize="12px" color={colorTheme('tertiary.500', 'tertiary.200')} mb="4px">
                                {t('Farming Incentives')}
                            </Text>
                            {earnedDecimal.map((amount, i) => {
                                return tokenIconBlock(entry.data.rewardTokens[i], amount, position.earnedWorth[i], i);
                            })}
                        </VStack>
                    </VStack>
                </Card>
            </Stack>
        </Modal>
    );
};
