import { useBreakpointValue } from '@chakra-ui/react';

function useIsPC(): boolean {
    let v = useBreakpointValue({ base: false, xl: true });
    if (v === undefined) {
        v = false;
    }
    return v;
}

export default useIsPC;
