import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { BasePathQueryPlugin } from './BaseDexPlugin';
import { SwapTag } from './config';

export interface DagNode {
    
    // all elements of preIdx should be strictly smaller than current node idx!
    preIdx?: number[]

    calling?: string
    targetAddress?: string

    // if preIdx is not undefined and its' length > 0,
    // after get and parse response of pre nodes, controller will call 'getCallingAndTargetAddress'
    // to get calling and targetAddress of this node
    getCallingAndTargetAddress?: () => {targetAddress: string, calling: string}

    // when get response of calling, the controller will call 'parseCallingResponse'
    // to parse the response
    parseCallingResponse: (response: string) => void
}

// each PreQueryPlugin will extends this data structure to store
// data they needed for exchange path searching
export interface PreQueryResult {}

export enum SwapDirection {
    ExactIn = 'ExactIn',
    ExactOut = 'ExactOut'
}

export enum CallingProperty {
    Long = 'long',
    Short = 'short'
}

export interface Path {
    tokenChain: TokenInfoFormatted[]
    // 0.003 means 0.3%
    feeRate: number[]
    feeContractNumber: number[]
}

export interface PathQueryCalling {
    calling: string
    targetAddress: string
    callingProperty?: CallingProperty
}

export interface PathQueryResult {
    // specify which exchange we will use
    swapTag?: SwapTag
    // corresponding PathQueryPlugin for that exchagne
    swapPathQueryPlugin?: BasePathQueryPlugin
    // computed amount (amountIn for desire mode and amountOut otherwise)
    amount: string
    path: Path
    noSufficientLiquidity: boolean
    initDecimalPriceEndByStart: number
    priceImpact: number
    feesDecimal: number
    feeRate: number
}

export interface PathQuery {
    path: Path
    // if pathQueryResult is undefined, 
    // our plugin should provide pathQueryCalling
    pathQueryCalling?: PathQueryCalling
    pathQueryResult?: PathQueryResult
}