import React, { useState } from 'react';
import { Box, Image, Text, Flex, useColorMode } from '@chakra-ui/react';
import { i_h3 } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { CustomTag } from '../../../components/Tag';
import '../HomePiece3/HomePiece3.css';
import '../HomePiece6/HomePiece6.css';
import { useTranslation } from 'react-i18next';
import { useInterval } from 'ahooks';
import { i_h5 } from '../../../../iZUMi-UI-toolkit/src/style';
const LiquidityPartners: React.FC = () => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const shortShowLength = 20;
    const allShowLength = 29;
    const [showLength, setShowLength] = useState(shortShowLength);
    const [isShowAll, setIsShowAll] = useState(false);

    useInterval(() => {
        if (isShowAll) {
            if (showLength + 4 < allShowLength) {
                setShowLength(showLength + 4);
            } else {
                setShowLength(allShowLength);
            }
        } else {
            if (showLength > shortShowLength) {
                setShowLength(showLength - 4);
            } else {
                setShowLength(shortShowLength);
            }
        }
    }, 20);

    const singleItem = (item: any, index: number) => {
        return (
            <Flex
                //flexBasis="25%"
                w={{ base: '25%' }}
                flexShrink={0}
                h={{ base: '100px', sm: '120px' }}
                justify="center"
                alignItems="center"
                key={index}
                position="relative"
            >
                <Image
                    w={{ base: '80%', lg: '80%' }}
                    src={process.env.PUBLIC_URL + '/assets/home/homePiece6/liquidityPartners/' + index + colorTheme('', 'Dark') + '.png'}
                />
            </Flex>
        );
    };

    return (
        <Box
            className={isShowAll ? '' : 'overflowAnchor'}
            w="100%"
            pt="40px"
            h={!isShowAll ? { base: '650px', sm: '760px' } : { base: '960px', sm: '1280px' }}
            transitionProperty="height"
            transitionDuration="1s"
            transitionTimingFunction="ease"
        >
            <Flex justifyContent="space-between" alignItems="center" h="26px" mx="auto" w={{ base: '90%', md: '670px', xl: '967px' }}>
                <Box w={{ base: '30%', lg: '180px', xl: '300px' }} h="1px" backgroundColor="#BEB6C6"></Box>
                <Text
                    mt="8px"
                    mb="12px"
                    bgGradient={colorTheme('linear(98.73deg, #1F0C4E -2.37%, #4F1FB4 89.44%)', 'undefined')}
                    bgClip={colorTheme('text', 'undefined')}
                    className={i_h3}
                    color={colorTheme('undefined', '#EBE6F0')}
                    textAlign="center"
                    fontWeight="bold"
                >
                    {t('Partners')}
                </Text>
                <Box w={{ base: '30%', lg: '180px', xl: '300px' }} h="1px" backgroundColor="#BEB6C6"></Box>
            </Flex>

            <Flex w={{ base: '90%', md: '670px', xl: '967px' }} flexWrap="wrap" mx="auto" mt="50px">
                {Array.from({ length: showLength }).map((item, index) => singleItem(item, index))}
            </Flex>
        </Box>
    );
};

export default LiquidityPartners;
