import { Flex, HStack, Stack, useBreakpointValue } from '@chakra-ui/react';
import CenterCard from './CenterCard';
import './HomePiece3.css';

const HomePiece3: React.FC<unknown> = () => {
    const isMobile = useBreakpointValue({ base: true, md: false });
    const isPC = useBreakpointValue({ base: false, xl: true });
    const breakLine = isPC ? 4 : isMobile ? 4 : 4;
    const tabs = [
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/Farm.gif',
            topIconDark: process.env.PUBLIC_URL + '/assets/home/homePiece3/darkFarm.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon2.svg',
            title: 'SAFU',
            content: 'Stable operation for over 500 days on Multi-Chains, Ethereum, Polygon, BNB Chain, Arbitrum, etc.',
        },
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/Learn.gif',
            topIconDark: process.env.PUBLIC_URL + '/assets/home/homePiece3/darkLearn.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon1.svg',
            title: '$40M Fundraising',
            content: 'Backed by leading institutions like Bybit, HashKey, IOSG, FENBUSHI, NGC, GSR …',
        },
        {
            topIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/VeiZi.gif',
            topIconDark: process.env.PUBLIC_URL + '/assets/home/homePiece3/darkVeiZi.gif',
            leftIcon: process.env.PUBLIC_URL + '/assets/home/homePiece3/leftIcon4.svg',
            title: 'Innovative DL-AMM',
            content: 'Maximize your capital efficiency and earn more with the Discretized-Liquidity-AMM model',
        },
    ];

    return (
        <Flex
            className="imageRender"
            w="100%"
            alignItems="center"
            justifyContent="center"
            direction={{ base: 'column', xl: 'row' }}
            mt={{ base: '60px', xl: '160px' }}
        >
            <Stack direction={isMobile ? 'column' : 'row'} spacing={{ base: '20px', xl: '34px' }}>
                {tabs.slice(0, breakLine).map((item, index) => {
                    return <CenterCard key={index} item={item} index={index}></CenterCard>;
                })}
            </Stack>
            <Flex>
                {tabs.slice(breakLine, tabs.length).map((item, index) => {
                    return <CenterCard key={index} item={item} index={index}></CenterCard>;
                })}
            </Flex>
        </Flex>
    );
};

export default HomePiece3;
