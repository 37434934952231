import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/bridge/';

export const BRIDGE = {
    [ChainId.Base]: [
        {
            titleImgSrc: baseURL + 'base.png',
            title: 'Base Bridge',
            content: "Base's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.base.org/deposit',
        },

        {
            titleImgSrc: baseURL + 'cbridge.png',
            title: 'cBridge',
            content:
                'cBridge is powered by Celer network, which is a decentralized and non-custodial asset bridge that supports 40+ blockchains.',
            link: 'https://cbridge.celer.network/8453/1/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?amountIn=600&chainIn=Base&chainOut=ZkSync%20Era&tokenIn=ETH&tokenOut=0x16A9494e257703797D747540f01683952547EE5b',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Cross-chain technology aggregator + Tools to enable it for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=BASE&toChain=ZK_SYNC&from=ETH&to=iZi',
        },
    ],
} as any;
