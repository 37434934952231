export const links = {
    docs: 'https://docs.izumi.finance',
    developerDocs: 'https://developer.izumi.finance',
    github: 'https://github.com/IzumiFinance/',
    //miningProtocol: 'https://assets.izumi.finance/paper/liquidity-mining.pdf',
    miningProtocol:
        'https://izumi-finance.medium.com/announcement-of-izi-eth-and-usdc-usdt-liquidity-mining-program-on-uniswap-v3-397705303e08',
    dswapProtocol: 'https://assets.izumi.finance/paper/dswap.pdf',
    telegram: 'https://t.me/joinchat/e7idO_QU_21iY2E9',
    telegramCN: 'https://t.me/iZUMi_Finance_CN',
    telegramEN: 'https://t.me/izumifinance',
    telegramChannel: 'https://t.me/iZUMi_Finance_Channel',
    twitter: 'https://twitter.com/izumi_Finance',
    medium: 'https://izumi-finance.medium.com',
    discord: 'https://discord.gg/izumifinance',
    reddit: 'https://www.reddit.com/r/izumiFinance/',
    defipulse: 'https://www.defipulse.com',
    research: 'https://docs.izumi.finance/research',
    iZiToken: 'https://etherscan.io/address/0x9ad37205d608b8b219e6a2573f922094cec5c200#readContract',
    mediaKit: 'https://drive.google.com/drive/folders/1XLvJbB5y6DWkl7O8qPXe1DJqSsaXVl5F?usp=sharing',
    liquidBoxDoc: 'https://docs.izumi.finance/product/liquidbox/why-do-protocols-need-liquidbox-to-gain-liquidity',
    disclaimer: 'https://docs.izumi.finance/disclaimer',
    gateiZiLink: 'https://www.gateio.ch/cn/trade/IZI_USDT',
    bybitiZiLink: 'https://www.bybit.com/en-US/trade/spot/IZI/USDT',
    mexciZiLink: 'https://www.mexc.com/zh-CN/exchange/IZI_USDT',
    analyticLink: process.env.REACT_APP_ENV === 'development' ? 'https://analytic-alpha.izumi.finance' : 'https://analytics.izumi.finance',
    veiZilink: 'https://etherscan.io/address/0xb56a454d8dac2ad4cb82337887717a2a427fcd00',
};
