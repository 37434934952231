import { BoxProps, Stack, Text, useColorMode } from '@chakra-ui/react';
import { RiskEnum } from '../../../../../config/bizConfig';
import { i_text_copy } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';

type RiskProps = {
    level: RiskEnum;
} & BoxProps;
const Risk: React.FC<RiskProps> = (props) => {
    const { level, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Stack
            // w="52px"
            h="17px"
            borderRadius="30px"
            textAlign="center"
            bg={
                level === RiskEnum.High
                    ? colorTheme('#D9D9D9', '#999999')
                    : level === RiskEnum.Low
                    ? colorTheme('#EFEFEF', '#434243')
                    : colorTheme('#F3F3F3', '#666666')
            }
            border={
                // level === RiskEnum.High ? '0.4px solid #D9D9D9' : level === RiskEnum.Low ? '0.4px solid #EFEFEF' : '0.4px solid #F3F3F3'
                ''
            }
            color={colorTheme(
                level === RiskEnum.High ? '#6A5E86' : level === RiskEnum.Low ? '#6A5E86' : '#6A5E86',
                level === RiskEnum.High ? '#444444' : level === RiskEnum.Low ? 'gray.300' : 'gray.400'
            )}
            px="11px"
            {...rest}
        >
            <Text className={i_text_copy} fontWeight="600">
                {level}
            </Text>
        </Stack>
    );
};

export default Risk;
