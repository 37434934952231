import { Stack, HStack, Text, useColorMode } from '@chakra-ui/react';
import BigNumber from 'bignumber.js';
import React from 'react';
import { useSelector } from 'react-redux';
import { LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES } from '../../../../../config/trade/tradeContracts';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import useWindowDimensions from '../../../../../hooks/useWindowDimension';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import { useGasOrWrappedToken } from '../../../../../state/models/pro/utils/useGasOrWrappedToken';
import { RootDispatch, RootState } from '../../../../../state/store';
import { noto_t3 } from '../../../../../style';
import { QuoterContract } from '../../../../../types/abis/iZiSwap/Quoter';
import { getQuoterContract } from '../../../../../utils/contractFactory';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { decimal2Amount, formatNumber } from '../../../../../utils/tokenMath';
import { trades } from '../OrderBook';
import CurrentPriceBlock from './CurrentPriceBlock';

type OrderSellBlockProps = {
    currentType: string;
    trades: trades[];
    realPrice: number | false | undefined;
};
const OrderSellBlock: React.FC<OrderSellBlockProps> = (props) => {
    const { currentType, trades, realPrice } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId, web3 } = useWeb3WithDefault();

    const { proOrderBook } = useSelector((state: RootState) => state);
    const { proControlState, proOrderFormState } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const tradeFlashMode = proControlState.tradeFlashMode;
    const useGasToken = proControlState.useGasToken;
    const limitOrderWithSwapManagerAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];
    const quoterContract = getQuoterContract(chainId, web3, false) as QuoterContract;
    const baseToken = useGasOrWrappedToken(proOrderFormState.baseToken, useGasToken);
    const quoteToken = useGasOrWrappedToken(proOrderFormState.quoteToken, useGasToken);
    const baseTokenEntity = useTokenEntity(baseToken, limitOrderWithSwapManagerAddress, undefined);
    const quoteTokenEntity = useTokenEntity(quoteToken, limitOrderWithSwapManagerAddress, undefined);

    const quoteAmountDecimalBalance = quoteTokenEntity.tokenBalance();
    const baseAmountDecimalBalance = baseTokenEntity.tokenBalance();

    const baseAmountBalance = decimal2Amount(new BigNumber(baseAmountDecimalBalance), baseToken) ?? new BigNumber(0);
    const quoteAmountBalance = decimal2Amount(new BigNumber(quoteAmountDecimalBalance), quoteToken) ?? new BigNumber(0);

    const { height } = useWindowDimensions();

    const chartHeight =
        currentType === 'all'
            ? height > 920
                ? 200
                : height > 865
                ? (height - 520) / 2
                : 178
            : // current type = buy
            height > 920
            ? 401
            : height > 865
            ? height - 520
            : 358;
    const sliceLength = Math.min(currentType === 'all' ? 10 : proOrderBook.upQuantity.length, proOrderBook.upQuantity.length);
    const reverseData = proOrderBook.upQuantity.reverse();
    const maxQuantity = Math.max(...reverseData);
    const minQuantity = Math.min(...reverseData);
    const summary = [] as number[];

    const currentQuantity = proOrderBook.upQuantity.slice(0, sliceLength).reverse();

    currentQuantity.map((quantity, index) => {
        const i = sliceLength - index - 1;
        if (summary.length === 0) {
            summary.push(currentQuantity[i]);
        } else {
            summary.push(currentQuantity[i] + summary[index - 1]);
        }
    });
    return (
        <Stack w="100%">
            <Stack
                ref={(el) => el && currentType === 'all' && el.scrollTo(0, el.offsetHeight)}
                h={chartHeight}
                overflow={currentType === 'all' ? 'hidden' : 'auto'}
                spacing="2px"
            >
                {proOrderBook.upQuantity
                    .slice(0, sliceLength)
                    .reverse()
                    .map((quantity, index) => {
                        const sellPriceDecimal = proOrderBook.upPriceDecimal[sliceLength - index - 1];
                        let bgWidth = 0;
                        if (quantity === maxQuantity) {
                            bgWidth = 1;
                        } else if (quantity === minQuantity) {
                            bgWidth = 0.2;
                        } else {
                            bgWidth = 0.2 + ((quantity - minQuantity) / (maxQuantity - minQuantity)) * 0.8;
                        }

                        return (
                            <Stack w="100%" key={index} alignItems="end">
                                <Stack w={bgWidth * 100 + '%'} h="20px" bg={colorTheme('#F8EBEB', '#2E1927')} zIndex="1"></Stack>

                                <HStack
                                    w="100%"
                                    h="20px"
                                    className={noto_t3}
                                    borderRadius="2px"
                                    cursor="pointer"
                                    mt="-20px !important"
                                    zIndex="2"
                                    px="8px"
                                    spacing="0px"
                                    _hover={{ bg: colorTheme('rgba(203, 20, 20, 0.1)', '#5A1E2D') }}
                                    onClick={() => {
                                        if (sellPriceDecimal > 0) {
                                            dispatch.proOrderFormState.setPrice({
                                                priceDecimalBaseByQuote: sellPriceDecimal,
                                                baseAmountBalance,
                                                quoteAmountBalance,
                                                quoterContract,
                                                flashMode: tradeFlashMode,
                                            });
                                        }
                                    }}
                                >
                                    <Text w="33%" color={colorTheme('#CB1414', '#CB1414')}>
                                        {formatNumber(sellPriceDecimal, 2, 2, true)}
                                    </Text>
                                    <Text w="33%" textAlign="end" color={colorTheme('#767676', '#C9C3D7')}>
                                        {formatNumber(quantity, 2, 2, true)}
                                    </Text>
                                    <Text w="33%" textAlign="end" color={colorTheme('#767676', '#C9C3D7')}>
                                        {formatNumber(summary[sliceLength - index - 1], 2, 2, true)}
                                    </Text>
                                </HStack>
                            </Stack>
                        );
                    })}
            </Stack>
            {currentType === 'sell' && (
                <CurrentPriceBlock position="sticky" bottom="0px" trades={trades} realPrice={realPrice}></CurrentPriceBlock>
            )}
        </Stack>
    );
};

export default OrderSellBlock;
