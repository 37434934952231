import { useEffect, useState } from 'react';
import { useWeb3WithDefault } from './useWeb3WithDefault';
import { TokenInfoFormatted } from './useTokenListFormatted';
import tokens  from '../config/tokens';

export const useIZiToken = (): { iZiToken: TokenInfoFormatted } => {
    const { chainId } = useWeb3WithDefault();
    const [iZiTokenFormatted, setiZiTokenFormatted] = useState(undefined as unknown as any);

    useEffect(() => {
        setiZiTokenFormatted({
            chainId,
            name: 'iZUMi Token',
            symbol: 'iZi',
            icon: '/assets/tokens/izumi.svg',
            address: tokens.iZi.contracts[chainId]?.address ?? '',
            decimal: 18,
            custom: false,
        });
    }, [chainId]);

    return {
        iZiToken: iZiTokenFormatted,
    };
};
