import { Flex, useDisclosure, Box, Image, Text, BoxProps, useColorMode } from '@chakra-ui/react';
import React from 'react';
import truncateWalletAddress from '../../../../utils/truncateWalletAddress';
import { formatNumber } from '../../../../utils/tokenMath';
import WalletModal from '../../../../components/Modal/WalletModal/WalletModal';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { getColorThemeSelector } from '../../../../utils/funcs';
import NetworkSelect from '../../../../components/Select/NetworkSelect/NetworkSelect';
import { useSelector } from 'react-redux';
import { RootState, RootDispatch } from '../../../../state/store';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import useInterval from 'ahooks/lib/useInterval';
import Identicon from '../../../../components/Identicon';
import ConnectButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/ConnectButton/ConnectButton';
import { useConnectModal } from '../../../../providers/ConnectProvider';
import WalletButton from '../../../../components/Buttons/WalletButton/WalletButton';
import useIsMobile from '../../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';
import LanguageSelect from '../../../../components/Sidebar/LanguageSelect/LanguageSelect';

const Header: React.FC<BoxProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onOpenModal: onConnect } = useConnectModal();
    const { chainId, chainName, web3, account } = useWeb3WithDefault();

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();

    const disConnect = () => {
        onOpen();
    };

    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));

    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, 5000);

    const { account: accountModel } = useSelector((state: RootState) => state);
    return (
        <>
            <Flex alignItems="center" flexShrink={0} flexGrow={0} h="54px" {...props} zIndex="3">
                <Flex w={{ base: '100%', lg: '960px' }} m="0 auto" justifyContent="flex-end" align="center" flexDirection="row">
                    {account ? <NetworkSelect isHome={true} /> : null}
                    {account ? (
                        isMobile ? (
                            <WalletButton mr="24px" />
                        ) : (
                            <>
                                <Box
                                    h="32px"
                                    boxSize="border-box"
                                    borderRadius="2px"
                                    _hover={{
                                        boxShadow: '0px 2px 12px 0px rgba(164, 165, 180, 0.3), 0px 2px 9px 0px rgba(219, 219, 219, 0.32)',
                                    }}
                                    onClick={disConnect}
                                >
                                    <Flex height="32px" padding="3px 6px" alignItems="center">
                                        <Box boxSize="22px">
                                            {' '}
                                            <Identicon size={22} />
                                        </Box>
                                        <Flex alignItems="center" ml="12px">
                                            <Text
                                                width="80px"
                                                lineHeight="12px"
                                                fontSize="12px"
                                                fontFamily="Avenir"
                                                fontWeight="600"
                                                color={colorTheme('#2A2A2A', '#EBEBEB')}
                                            >
                                                {truncateWalletAddress(account || '')}
                                            </Text>
                                            <Image
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    colorTheme('/assets/header/etherIcon.png', '/assets/wallet/balanceIconDark.png')
                                                }
                                                width="5px"
                                                height="9px"
                                                marginRight="4px"
                                                marginTop="1px"
                                            />
                                            <Text
                                                // width="80px"
                                                lineHeight="12px"
                                                fontSize="12px"
                                                fontFamily="Avenir"
                                                fontWeight="600"
                                                color={colorTheme('#2A2A2A', 'tertiary.50')}
                                            >
                                                {formatNumber(accountModel.ethBalance ?? 0, 2, 2)}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                </Box>
                                <LanguageSelect ml="20px" menuList={{ bottom: 'none', right: '0px', left: 'none', top: '-7px' }} />
                            </>
                        )
                    ) : (
                        <ConnectButton mr="10px" Identicon={Identicon} onClick={onConnect} />
                    )}
                </Flex>
            </Flex>
            <WalletModal
                account={account as unknown as string}
                chainId={chainId!}
                isOpen={isOpen}
                onClose={onClose}
                onCopyAddress={onCopyAddress}
                scanName={chainName}
            />
        </>
    );
};

export default Header;
