import React from 'react';
import { Text, useColorMode, Image, BoxProps, HStack, StackDirection } from '@chakra-ui/react';
import Info from '../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';
import { formatNumber } from '../../../utils/tokenMath';
import { useTranslation } from 'react-i18next';

type VeiZiAmountProps = {
    balance: number;
    direction?: StackDirection;
    variant?: string;
} & BoxProps;

export const VeiZiAmount: React.FC<VeiZiAmountProps> = ({ balance, direction, variant, ...rest }) => {
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return variant === 'bottom-icon' ? (
        <Info
            direction={direction || 'column'}
            label={
                <HStack spacing="10px">
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.300')}>
                        {t('veiZi balance')}
                    </Text>
                </HStack>
            }
            value={
                <HStack>
                    <Image h="17px" w="17px" src={process.env.PUBLIC_URL + '/assets/tokens/veiZi.svg'} />
                    <Text className={i_text_copy} fontSize="14px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                        {formatNumber(balance, 2, 2)}
                    </Text>
                </HStack>
            }
            w="170px"
            {...rest}
        />
    ) : (
        <Info
            direction={direction || 'column'}
            label={
                <HStack spacing="10px">
                    <Image h="17px" w="17px" src={process.env.PUBLIC_URL + '/assets/tokens/veiZi.svg'} />
                    <Text className={i_text_copy} color={colorTheme('tertiary.800', 'tertiary.300')}>
                        {t('veiZi balance')}
                    </Text>
                </HStack>
            }
            value={
                <Text className={i_text_copy} fontSize="14px" color={colorTheme('tertiary.800', 'tertiary.100')}>
                    {formatNumber(balance, 2, 2)}
                </Text>
            }
            w="170px"
            {...rest}
        />
    );
};
