import { Web3Modal } from '@web3modal/standalone';
import { EthereumProvider } from '@walletconnect/ethereum-provider';
import chains from '../config/chains';
import { ChainId } from '../types/mod';
import { setCurrentConnector } from '../hooks/useWeb3WithDefault';
import { ConnectorNames } from './connectors';

const projectId = '4029de8664d00dd15ccf8df3d2b7285c';
const WALLETCONNECTPROVIDER = {} as { value: any };
const ISCONNECTDISCARD = { value: true } as { value: Boolean };

// const web3Modal = new Web3Modal({
//   walletConnectVersion: 2,
//   projectId,
//   standaloneChains: ['eip155:56']
// })

export const getWalletConnectProvider = () => {
    if (WALLETCONNECTPROVIDER.value !== undefined) {
        return WALLETCONNECTPROVIDER.value;
    }
};

export const cleanWalletConnectCache = () => {
    if (WALLETCONNECTPROVIDER.value !== undefined) {
        WALLETCONNECTPROVIDER.value = undefined;
    }
    ISCONNECTDISCARD.value = true;
};

export const loginWalletConnect = async () => {
    try {
        if (WALLETCONNECTPROVIDER.value == undefined) {
            const provider = await EthereumProvider.init({
                projectId, // REQUIRED your projectId
                chains: [324],
                rpcMap: {'324': 'https://zksync2-mainnet.zksync.io'},
                showQrModal: true
            });
            WALLETCONNECTPROVIDER.value = provider;
        }

        if (WALLETCONNECTPROVIDER.value.accounts.length == 0 || ISCONNECTDISCARD.value) {
            await WALLETCONNECTPROVIDER.value.connect({
                chains: [ChainId.EthereumMainnet, ChainId.BSC, ChainId.Arbitrum, ChainId.Aurora, ChainId.Matic, ChainId.ZkSyncEra],
            });
            ISCONNECTDISCARD.value = false;
        } else {
            await WALLETCONNECTPROVIDER.value.enable();
        }

        WALLETCONNECTPROVIDER.value.on('session_delete', (event: any) => {
            console.info('event:  ', event);
            WALLETCONNECTPROVIDER.value.disconnect();
            // cleanWalletConnectCache();
            ISCONNECTDISCARD.value = true;
            setCurrentConnector(ConnectorNames.Injected);
        });
    } catch (e) {
        console.info('error:  ', e);
    }
};

export const logoutWalletConnect = async () => {
    WALLETCONNECTPROVIDER.value.disconnect();
    cleanWalletConnectCache();
    setCurrentConnector(ConnectorNames.Injected);
};
