import { createModel } from '@rematch/core';
import produce from 'immer';
import { RootModel } from '..';
import { TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
export interface PoolsControl {
    sortBy: any;
    searchKey: any;
}
export interface PoolSetting {
    page: number;
    pageSize: number;
    orderBy?: string;
}

export interface TokenSetting {
    page: number;
    pageSize: number;
    orderBy?: string;
}

export interface TransactionsSetting {
    page: number;
    pageSize: number;
    filterType?: TransRecordTypeEnum;
}

export interface TransactionsData {
    totalPage?: number;
}

export interface Poolstate {
    poolsControl: PoolsControl;
    poolEntryList:[];
    poolSetting: PoolSetting;
    poolAprList:PoolAprList[];
}
export interface PoolAprList {
    pool_address:string;
    apr:number;
    leftRange:number;
    rightRange:number;
    slideApr:number;
}


export const pools = createModel<RootModel>()({
    state: {
        poolsControl: {
            sortBy: undefined,
            searchKey: undefined,
        },
        poolEntryList: [],
        poolAprList:[] as PoolAprList[],
        poolSetting: {
            page: 1,
            pageSize: 30,
            orderBy:'-vol_day',
        },
      
    } as Poolstate,
    reducers: {
        setFarmState: (state: Poolstate, payload: Poolstate) => {
            return { ...state, ...payload };
        },
        setPoolsControl: (state: Poolstate, poolsControl: PoolsControl) => produce(state, draft => {
            draft.poolsControl = { ...poolsControl };
        }),
       
        setPoolEntryList: (state: Poolstate, payload: []) => produce(state, draft => {
            draft.poolEntryList = [...payload];
        }),
        setPoolAprList: (state: Poolstate, payload:  PoolAprList[]) => produce(state, draft => {
            draft.poolAprList = [...payload];
        }),
        setPoolSetting: (state: Poolstate, newPoolDisplay: PoolSetting) => produce(state, draft => {
            draft.poolSetting = { ...draft.poolSetting, ...newPoolDisplay };
        }),
    },
  
});
