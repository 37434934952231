import { Contract } from 'web3-eth-contract';
import { ChainId } from '../../../../types/mod';
import { buildSendingParams } from '../../../../utils/contractHelpers';

export const setApprovalForAll = (
    contract: Contract,
    operator: string,
    account: string,
    approved: boolean,
    gasPrice: number,
    chainId: ChainId
): any => {
    return contract.methods
        .setApprovalForAll(operator, approved)
        .send(
            buildSendingParams(
                chainId,
                { from: account, gasPrice: gasPrice },
                gasPrice
            )
        );
};
