import { PositionDetail } from '../../../state/models/farm/UniswapV3/fixRange/types';
import { decodeMethodResult } from '../../../utils/contractHelpers';

export const getTokenOwned = async (positionManagerContract: any, nftID: number): Promise<any> => {
    if (isNaN(nftID)) {
        return {
            tokensOwed0: '0',
            tokensOwed1: '0',
        } as PositionDetail;
    }

    const nftOwner: string = await positionManagerContract.methods.ownerOf(nftID).call();

    console.log('nftOwner: ', nftOwner);
    const ret = await positionManagerContract.methods
        .collect({
            tokenId: nftID,
            recipient: nftOwner,
            amount0Max: '0xffffffffffffffffffffffffffffffff',
            amount1Max: '0xffffffffffffffffffffffffffffffff',
        })
        .call({ from: nftOwner });

    return {
        tokensOwed0: ret.amount0,
        tokensOwed1: ret.amount1,
    } as PositionDetail;
};

export const getFeeEarnedFromiZiLiquidity = async (
    liquidityManagerContract: any,
    nftID: string
): Promise<{ amountX: string; amountY: string }> => {
    if (!nftID) {
        return {
            amountX: '0',
            amountY: '0',
        };
    }

    const nftOwner: string = await liquidityManagerContract.methods.ownerOf(nftID).call();

    console.log('nftOwner: ', nftOwner);
    try {
        const ret = await liquidityManagerContract.methods
            .collect(nftOwner, nftID, '0xffffffffffffffffffffffffffffffff', '0xffffffffffffffffffffffffffffffff')
            .call({ from: nftOwner });
        return {
            amountX: ret.amountX,
            amountY: ret.amountY,
        };
    } catch (err: any) {
        return { amountX: '0', amountY: '0' };
    }
};

export const getTokenAmountiZiLiquidity = async (
    liquidityManagerContract: any,
    nftID: string,
    liquidity: string
): Promise<{ amountX: string; amountY: string }> => {
    if (!nftID) {
        return {
            amountX: '0',
            amountY: '0',
        };
    }

    const nftOwner: string = await liquidityManagerContract.methods.ownerOf(nftID).call();

    console.log('nftOwner: ', nftOwner);
    try {
        const ret = await liquidityManagerContract.methods.decLiquidity(nftID, liquidity, '0', '0', '0xffffffff').call({ from: nftOwner });
        return {
            amountX: ret.amountX,
            amountY: ret.amountY,
        };
    } catch (err: any) {
        return { amountX: '0', amountY: '0' };
    }
};
