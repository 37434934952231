import { BoxProps, HStack, Stack, Circle, Text, useColorMode } from '@chakra-ui/react';
import { i_text_copy } from '../../../../../style';
import { getColorThemeSelector } from '../../../../../utils/funcs';
type StepProps = {
    isSelected: boolean;
    stepIndex: number;
    content: string;
} & BoxProps;
const Step: React.FC<StepProps> = (props) => {
    const { isSelected, stepIndex, content, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Stack
            w="235px"
            h="60px"
            bg={isSelected ? colorTheme('#FCF7FF', '#2F2542') : colorTheme('#F4F4F4', '#2B223F')}
            borderRadius="8px"
            border={isSelected ? '1px solid #7F4AFE' : ''}
            spacing="4px"
            px="28px"
            py="10px"
            {...rest}
        >
            <HStack>
                <Circle w="6px" h="6px" bg={isSelected ? colorTheme('#7F4AFE', '#834FFF') : colorTheme('#C6C4D4', '#615A73')}></Circle>
                <Text className={i_text_copy} color={isSelected ? colorTheme('#7F4AFE', '#834FFF') : colorTheme('#B0ADC7', '#615A73')}>
                    Step{stepIndex}
                </Text>
            </HStack>

            <Text
                className={i_text_copy}
                fontSize="16px"
                fontWeight="600"
                letterSpacing="0em"
                color={isSelected ? colorTheme('#2E1070', '#BE97FF') : colorTheme('#ADACC7', '#6B6887')}
            >
                {content}
            </Text>
        </Stack>
    );
};
export default Step;
