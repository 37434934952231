import { BoxProps } from '@chakra-ui/react';
import React from 'react';

const WarningIcon: React.FC<React.PropsWithChildren<BoxProps>> = () => {
    return (
        <svg width="26" height="24" viewBox="0 0 26 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.40816 23.25C1.179 23.25 0.970663 23.1925 0.783163 23.0775C0.595663 22.9625 0.44983 22.8117 0.345663 22.625C0.241496 22.4375 0.183996 22.2342 0.173163 22.015C0.16233 21.7958 0.21983 21.5825 0.345663 21.375L11.9082 1.375C12.0332 1.16667 12.1948 1.01042 12.3932 0.90625C12.5915 0.802083 12.7944 0.75 13.0019 0.75C13.2102 0.75 13.4136 0.802083 13.6119 0.90625C13.8102 1.01042 13.9715 1.16667 14.0957 1.375L25.6582 21.375C25.7832 21.5833 25.8407 21.7971 25.8307 22.0163C25.8207 22.2354 25.7632 22.4383 25.6582 22.625C25.554 22.8125 25.4082 22.9638 25.2207 23.0788C25.0332 23.1938 24.8248 23.2508 24.5957 23.25H1.40816ZM3.56441 20.75H22.4394L13.0019 4.5L3.56441 20.75ZM13.0019 19.5C13.3561 19.5 13.6532 19.38 13.8932 19.14C14.1332 18.9 14.2527 18.6033 14.2519 18.25C14.2519 17.8958 14.1319 17.5988 13.8919 17.3588C13.6519 17.1188 13.3552 16.9992 13.0019 17C12.6477 17 12.3507 17.12 12.1107 17.36C11.8707 17.6 11.7511 17.8967 11.7519 18.25C11.7519 18.6042 11.8719 18.9012 12.1119 19.1412C12.3519 19.3812 12.6486 19.5008 13.0019 19.5ZM13.0019 15.75C13.3561 15.75 13.6532 15.63 13.8932 15.39C14.1332 15.15 14.2527 14.8533 14.2519 14.5V10.75C14.2519 10.3958 14.1319 10.0987 13.8919 9.85875C13.6519 9.61875 13.3552 9.49917 13.0019 9.5C12.6477 9.5 12.3507 9.62 12.1107 9.86C11.8707 10.1 11.7511 10.3967 11.7519 10.75V14.5C11.7519 14.8542 11.8719 15.1513 12.1119 15.3913C12.3519 15.6313 12.6486 15.7508 13.0019 15.75Z"
                fill="#F1972D"
            />
        </svg>
    );
};

export default WarningIcon;
