import React from 'react';
import { BoxProps } from '@chakra-ui/react';
import { TokenEntity } from '../../state/models/hooks/useTokenEntity';
import { i_text_copy_bold } from '../../style';
import CustomButton from '../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { ToastLink, useCustomToast } from '../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { useTranslation } from 'react-i18next';
import { getChain, getTxLink } from '../../config/chains';

type ApproveTokenButtonProps = {
    tokenEntity: TokenEntity;
    approveAmount?: string;
} & BoxProps;

export const ApproveTokenButton: React.FC<ApproveTokenButtonProps> = (props) => {
    const { tokenEntity, approveAmount, ...rest } = props;
    const toast = useCustomToast();
    const { t } = useTranslation();
    return (
        <CustomButton
            variant="orange"
            hidden={tokenEntity.isApproved(approveAmount ?? '1')}
            fontClass={i_text_copy_bold}
            text={t('Approve') + ' ' + tokenEntity.token?.symbol}
            w="100%"
            h="51px"
            onClick={() => {
                const chain = getChain(tokenEntity.token.chainId);
                const toastLink = {} as ToastLink;
                tokenEntity
                    .handleApprove()
                    .on('transactionHash', (hash: string) => {
                        if (chain) {
                            toastLink.title = 'View on ' + chain.name;
                            toastLink.link = getTxLink(hash, chain);
                        }
                        toast('info', t('Approve') + ' ' + tokenEntity.token?.symbol, undefined, toastLink);
                    })
                    .then(() => {
                        tokenEntity.handleApproveSuccess();
                        toast('success', t('Approve') + ' ' + tokenEntity.token?.symbol + ' successfully', undefined, toastLink);
                    })
                    .catch((e: any) => {
                        console.info('error   :', e.message);
                    });
            }}
            {...rest}
        />
    );
};

export default ApproveTokenButton;
