import BigNumber from 'bignumber.js';
import { useCallback } from 'react';
import { isGasToken } from '../../../../config/tokens';
import { useMiningOneSideiZiBoostContractWithVersion } from '../../../../hooks/useContracts';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import { ChainId } from '../../../../types/mod';
import { buildSendingParams } from '../../../../utils/contractHelpers';
import { PoolEntryState } from '../../farm/iZiSwap/oneSide/types';
import { MiningCallbacks } from './common/callbacks';

export interface OneSideDepositParams {
    uniAmount: string;
    // for ve, numIZi maybe undefined
    numIZi?: string;
    // todo, we need add fields for ve in the future
    // ve?: any;
    stdPoint: number;
}

export interface OneSideEntity {
    deposit: (params: OneSideDepositParams, callbacks: MiningCallbacks, gasPrice: number) => void;
}

export interface MintCallParams {
    chainId: ChainId;
    account: any;
    poolEntry: PoolEntryState;
    miningContract: any;
    gas?: string;
    gasPrice?: number;
    depositParams: OneSideDepositParams;
}

const _getMintCallV1 = (params: MintCallParams): any => {
    const { account, poolEntry, miningContract, gas, gasPrice, depositParams, chainId } = params;
    const deadline = new BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);
    const isTokenSymbol = isGasToken(poolEntry.meta.tokenUni, chainId);
    const sendValue = isTokenSymbol ? new BigNumber(depositParams.uniAmount).toFixed(0) : '0';
    const options = {
        from: account,
        value: sendValue,
        gas: gas,
        maxFeePerGas: gasPrice,
    };

    console.log('miningContract: ', miningContract)

    return [miningContract?.methods.depositWithoneSideToken(
        new BigNumber(depositParams.uniAmount).toFixed(0),
        new BigNumber(String(depositParams.numIZi)).toFixed(0),
        depositParams.stdPoint,
        deadline
    ), options];
};

// const _getMintCallVeiZi = (params: MintCallParams): any => {
//     const { account, poolEntry, miningContract, gas, gasPrice, depositParams, chainId } = params;
//     const deadline = new BigNumber('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff').toFixed(0);
//     const isTokenSymbol = isGasToken(poolEntry.meta.tokenUni, chainId);
//     const sendValue = isTokenSymbol ? new BigNumber(depositParams.uniAmount).toFixed(0) : '0';
//     const options = {
//         from: account,
//         value: sendValue,
//         gas: gas,
//         maxFeePerGas: gasPrice,
//     };

//     return [miningContract?.methods.depositWithuniToken(
//         new BigNumber(depositParams.uniAmount).toFixed(0), 
//         poolEntry.data.oracleTick,
//         deadline
//     ), options];
// };

const useOneSideiZiEntity = (poolEntry: PoolEntryState): OneSideEntity => {
    const { chainId, account } = useWeb3WithDefault();
    console.log('address: ', poolEntry.meta.miningContract)
    const { contract } = useMiningOneSideiZiBoostContractWithVersion(poolEntry.meta.miningContract, poolEntry.meta.contractVersion);
    console.log('contract: ', contract)
    const version = poolEntry.meta.contractVersion;

    const deposit = useCallback(
        (params: OneSideDepositParams, callbacks: MiningCallbacks, gasPrice: number) => {

            let getMintCall = _getMintCallV1;
            // if (version === FarmOneSideiZiContractVersion.VEIZI) {
            //     getMintCall = _getMintCallV3;
            // }
            const [mintCall, options] = getMintCall(
                {
                    chainId, account, poolEntry, gasPrice, miningContract: contract, depositParams: params
                }
            );

            mintCall.estimateGas(buildSendingParams(chainId, options, gasPrice))
                .then((gas: number) => {
                    const gasLimit = new BigNumber(gas * 1.1).toFixed(0, 2);

                    const [sendMintCall, sendOptions] = getMintCall(
                        {
                            chainId, account, poolEntry, miningContract: contract, depositParams: params, gas: gasLimit, gasPrice
                        }
                    );
                    sendMintCall.send(buildSendingParams(chainId, sendOptions, gasPrice))
                        .on('transactionHash', (e: any) => { callbacks.onTransactionHash?.(e); })
                        .then((e: any) => { callbacks.then?.(e); })
                        .catch((e: any) => { callbacks.catch?.(e); });
                })
                .catch((e: any) => { callbacks.catch?.(e); });
        },
        [chainId, version, account, poolEntry, contract]
    );
    return {
        deposit
    };
};

export default useOneSideiZiEntity;