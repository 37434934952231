import { HStack, Stack, Text, VStack, Image, useColorMode, Center } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRematchDispatch } from '../../../../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { favInfoKey } from '../../../../../../state/models/pro/favorite/favorite';
import { RootDispatch, RootState } from '../../../../../../state/store';
import { noto_t3_bold, noto_t3 } from '../../../../../../style';
import { getColorThemeSelector, toFeeNumber } from '../../../../../../utils/funcs';
import { formatNumber } from '../../../../../../utils/tokenMath';
import { ResponseKlineInfoMetaWithReverse } from '../../KlineInfo';
import { FavButton } from './FavButton';
import TokenEntry from './TokenEntry';
import { TokenInfoFormatted } from '../../../../../../hooks/useTokenListFormatted';
type SearchTokenBlockProps = {
    data: ResponseKlineInfoMetaWithReverse[];
    tokenList: TokenInfoFormatted[];
};
const SearchTokenBlock: React.FC<SearchTokenBlockProps> = (props) => {
    const { data, tokenList } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { favorite } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { chainId } = useWeb3WithDefault();

    const [filterData, setFilterData] = useState(data);
    const [allFeeRate, setAllFeeRate] = useState(true);
    const [feeRate, setFeeRate] = useState({});
    const [filterKey, setFilterKey] = useState({ key: '', way: '' });
    const tabs = [
        {
            title: 'Coins',
            width: '200px',
        },
        {
            title: 'Price',
            width: '90px',
        },
        {
            title: '24H Change',
            width: '100px',
        },
    ];

    const fee = Array.from(
        new Set(
            data
                .map((item) => {
                    return item.pool.fee;
                })
                .sort()
        )
    );

    useEffect(() => {
        if (!allFeeRate) {
            // fee rate filter
            const result = data.filter((item) => item.pool.fee === feeRate);
            setFilterData(result);
        } else {
            setFilterData(data);
        }
    }, [allFeeRate, data, feeRate, filterKey.key, filterKey.way]);

    const FavBt = (favInfo: any) => {
        return (
            <FavButton
                w="20px"
                h="20px"
                variant="base"
                isFav={favInfoKey(favInfo) in favorite.favPool}
                setIsFav={(isAdd: boolean) =>
                    dispatch.favorite.modFavPool({
                        isAdd,
                        favInfo,
                    })
                }
                boxSize="15px"
                m="0!important"
                ml={{ base: '0px !important', sm: '0px !important' }}
            />
        );
    };

    return (
        <Stack w="100%">
            <HStack>
                <Text className={noto_t3_bold} color="#83789B" mr="13px">
                    Fee
                </Text>
                <CustomButton
                    w="46px"
                    h="22px"
                    className={allFeeRate ? noto_t3_bold : noto_t3}
                    text={'All'}
                    bg={allFeeRate ? colorTheme('#EFEBF1', '#160F29') : 'inherit'}
                    color={allFeeRate ? '#7F4AFE' : colorTheme('#9984A6', '#6A598C')}
                    borderRadius="2px"
                    onClick={() => {
                        setAllFeeRate(true);
                    }}
                ></CustomButton>
                {fee.map((item, index) => {
                    return (
                        <CustomButton
                            w="46px"
                            h="22px"
                            key={index}
                            className={feeRate === item && allFeeRate === false ? noto_t3_bold : noto_t3}
                            text={toFeeNumber(item) + '%'}
                            bg={feeRate === item && allFeeRate === false ? colorTheme('#EFEBF1', '#160F29') : 'inherit'}
                            color={feeRate === item && allFeeRate === false ? '#7F4AFE' : colorTheme('#9984A6', '#6A598C')}
                            borderRadius="2px"
                            onClick={() => {
                                setAllFeeRate(false);
                                setFeeRate(item);
                            }}
                        ></CustomButton>
                    );
                })}
            </HStack>

            <Stack spacing="12px">
                {filterData.length === 0 && (
                    <Center w="100%" mt="80px">
                        <Text className={noto_t3} color="#83789B">
                            Oh, there's nothing here !
                        </Text>
                    </Center>
                )}
                {filterData.map((item, index) => {
                    return (
                        <HStack key={index} spacing="0px">
                            <HStack w={tabs[0].width} spacing="6px">
                                {FavBt({
                                    chainId: chainId,
                                    address: item.pool.address,
                                })}
                                <TokenEntry
                                    key={index}
                                    tokenList={tokenList}
                                    tokenX={item.tokenX}
                                    tokenY={item.tokenY}
                                    currentFee={item.pool.fee}
                                    pool={item.pool.address}
                                    isReverse={item.isReverse}
                                ></TokenEntry>
                            </HStack>
                            <Text className={noto_t3_bold} w={tabs[1].width} textAlign="end" color={colorTheme('#332D43', '#C9C3D7')}>
                                {formatNumber(item.pool.latestDealPrice, 2, 2, true)}
                            </Text>
                            <Text
                                className={noto_t3}
                                w={tabs[2].width}
                                textAlign="end"
                                color={item.pool.dayPriceChange >= 0 ? '#12931F' : '#CB1414'}
                            >
                                {item.pool.dayPriceChange >= 0 ? '+' : ' '}
                                {(item.pool.dayPriceChange * 100).toFixed(2)}
                                {'%'}
                            </Text>
                        </HStack>
                    );
                })}
            </Stack>
        </Stack>
    );
};
export default SearchTokenBlock;
