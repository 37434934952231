import React from 'react';
import { Center, useColorMode, BoxProps, Icon } from '@chakra-ui/react';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';
import { getColorThemeSelector } from '../../../../../../utils/funcs';

export type FavButtonProps = {
    isFav: boolean;
    setIsFav: (e: boolean) => void;
    variant?: string;
    boxSize?: string;
} & BoxProps;

export const FavButton: React.FC<FavButtonProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { isFav, setIsFav, variant, boxSize, ...rest } = props;

    return (
        <Center
            w="63px"
            h="40px"
            bgColor={variant === 'base' ? 'unset' : !isFav ? colorTheme('gray.50', '#33354B') : colorTheme('#FFA723', '#4850AE')}
            borderRadius="4px"
            onClick={() => {
                setIsFav(!isFav);
            }}
            {...rest}
        >
            <Icon
                boxSize={boxSize ?? '18px'}
                as={isFav ? AiFillStar : AiOutlineStar}
                color={
                    variant === 'base'
                        ? isFav
                            ? colorTheme('#FFA723', '#F6A712')
                            : colorTheme('gray.300', 'gray.400')
                        : isFav
                        ? colorTheme('white', '#F6A712')
                        : colorTheme('gray.300', 'gray.400')
                }
            />
        </Center>
    );
};
