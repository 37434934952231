import { Icon } from '@chakra-ui/react';
import { RiDashboardFill } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';

export const DashboardLinkButton: React.FC = () => {
    const history = useHistory();

    return (
        <CustomButton
            variant="tertiary"
            w="34px"
            h="34px"
            borderRadius="3px"
            text={<Icon as={RiDashboardFill} boxSize="28px" px="3px" />}
            onClick={() => {
                history.push('/farm');
            }}
        ></CustomButton>
    );
};
