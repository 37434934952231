import { createModel } from '@rematch/core';
import { RootModel } from '../index';
import produce from 'immer';

export interface LiquidityState {
    showWarning: boolean,
}

export const liquidity = createModel<RootModel>()({
    state: {
        showWarning: true,
    } as LiquidityState,
    reducers: {
        setShowWarning: (state: LiquidityState, isShow:boolean, ) => produce(state, draft => {
            draft.showWarning = isShow;
        }),
    },
});
