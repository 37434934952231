import React from 'react';
import { useSelector } from 'react-redux';
import { Box, HStack, Stack } from '@chakra-ui/react';

import PoolLiquidityList from './components/PoolList';
import PageHeader from '../../../../components/PageHeader/PageHeader';
import PageLayout, { AdaptationMode } from '../../../../components/layout/PageLayout';
import Controls from '../../components/Controls';
import useDocumentTitle from '../../../../hooks/useDocumentTitle';
import { RootState, RootDispatch } from '../../../../state/store';
import { useRematchDispatch } from '../../../../hooks/useRematchDispatch';
import IZiLinkButton from '../../../../components/Buttons/iZiLinkButton/iZiLinkButton';
import ValidChainWrapper from '../../../components/ChainNotValidBlock';
import { DashboardLinkButton } from '../../components/DashboardLinkButton';
import TypeSelect from '../../components/TypeSelect';
import NetworkSelect from '../../../../components/Select/NetworkSelect/NetworkSelect';
import { useTranslation } from 'react-i18next';

type FarmProps = {
    type?: AdaptationMode;
};

const Farm: React.FC<FarmProps> = (props) => {
    const { type } = props;
    useDocumentTitle('Farm | iZiSwap');
    const { farmFixRangeiZi: farm } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { t } = useTranslation();

    return type === 'mobile' ? (
        <PageLayout
            body={
                <ValidChainWrapper
                    content={
                        <Stack w="100%">
                            <Controls farm={farm} dispatch={dispatch.farmFixRangeiZi} />
                            <TypeSelect dex="iZi" type="mobile" />
                            <Box h="10px" />
                            <PoolLiquidityList />;
                        </Stack>
                    }
                    app="farmiZi"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={t('Liquidity Mining')}
                    subtitle={t('Stake your iZiSwap NFTs to earn rewards')}
                    element={
                        <HStack spacing="10px">
                            <DashboardLinkButton />
                            <IZiLinkButton />
                            <NetworkSelect />
                        </HStack>
                    }
                />
            }
            body={
                <ValidChainWrapper
                    content={
                        <Stack w="100%">
                            <Controls farm={farm} dispatch={dispatch.farmFixRangeiZi} />
                            <TypeSelect dex="iZi" />
                            <Box h="10px" />
                            <PoolLiquidityList />;
                        </Stack>
                    }
                    app="farmiZi"
                />
            }
            internalWidth={{ base: '700px', xl: '840px', xxl: '1100px', xlp1: '1150px' }}
        />
    );
};

export default Farm;
