
import BigNumber from 'bignumber.js';
import { amount2Decimal } from '../../../../utils/tokenMath';
import { tick2Price, tick2PriceSqrt } from '../../../../utils/tickMath';
import { isNumeric } from '../../../../utils/valid';
import { StateResponse } from '../../../../types/abis/iZiSwap/Pool';
import { price2PriceDecimal } from '../../farm/UniswapV3/fixRange/funcs';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { LiquidityDetail } from './types';
import { getERC20ContractByAddress } from '../../../../net/iZUMi-endpoints/src/web3/contract/ERC/ErcContractFactory';
import Web3 from 'web3';

export const getLiquidityYRange = (
    leftPoint: number, rightPoint: number, currentPoint: number
): {leftPoint: number, rightPoint: number} => {
    const yRightPoint = Math.min(rightPoint, currentPoint + 1);
    return {leftPoint, rightPoint: yRightPoint};
};

export const getLiquidityXRange = (
    leftPoint: number, rightPoint: number, currentPoint: number
): {leftPoint: number, rightPoint: number} => {
    const xLeftPoint = Math.max(leftPoint, currentPoint + 1);
    return {leftPoint: xLeftPoint, rightPoint};
};

export const getAmountY = (
    liquidity: BigNumber,
    sqrtPriceL: number,
    sqrtPriceR: number,
    sqrtRate: number,
    upper: boolean,
): BigNumber => {
    const numerator = sqrtPriceR - sqrtPriceL;
    const denominator = sqrtRate - 1;
    if (!upper) {
        const amount = new BigNumber(liquidity.times(numerator).div(denominator).toFixed(0, 1));
        return amount;
    } else {
        const amount = new BigNumber(liquidity.times(numerator).div(denominator).toFixed(0, 2));
        return amount;
    }
};

export const liquidity2AmountYAtPoint = (
    liquidity: BigNumber,
    sqrtPrice: number,
    upper: boolean
): BigNumber => {
    const amountY = liquidity.times(sqrtPrice);
    if (!upper) {
        return new BigNumber(amountY.toFixed(0, 1));
    } else {
        return new BigNumber(amountY.toFixed(0, 2));
    }
};

export const getAmountX = (
    liquidity: BigNumber,
    leftPt: number,
    rightPt: number,
    sqrtPriceR: number,
    sqrtRate: number,
    upper: boolean,
): BigNumber => {
    const sqrtPricePrPc = Math.pow(sqrtRate, rightPt - leftPt + 1);
    const sqrtPricePrPd = Math.pow(sqrtRate, rightPt + 1);

    const numerator = sqrtPricePrPc - sqrtRate;
    const denominator = sqrtPricePrPd - sqrtPriceR;

    if (!upper) {
        const amount = new BigNumber(liquidity.times(numerator).div(denominator).toFixed(0, 1));
        return amount;
    } else {
        const amount = new BigNumber(liquidity.times(numerator).div(denominator).toFixed(0, 2));
        return amount;
    }
};

export const liquidity2AmountXAtPoint = (
    liquidity: BigNumber,
    sqrtPrice: number,
    upper: boolean
): BigNumber => {
    const amountX = liquidity.div(sqrtPrice);
    if (!upper) {
        return new BigNumber(amountX.toFixed(0, 1));
    } else {
        return new BigNumber(amountX.toFixed(0, 2));
    }
};

export const getAmountYNoRound = (
    liquidity: BigNumber,
    sqrtPriceL: number,
    sqrtPriceR: number,
    sqrtRate: number,
): BigNumber => {
    const numerator = sqrtPriceR - sqrtPriceL;
    const denominator = sqrtRate - 1;
    const amount = liquidity.times(numerator).div(denominator);
    return amount;
};

export const getAmountXNoRound = (
    liquidity: BigNumber,
    leftPt: number,
    rightPt: number,
    sqrtPriceR: number,
    sqrtRate: number,
): BigNumber => {
    const sqrtPricePrPc = Math.pow(sqrtRate, rightPt - leftPt + 1);
    const sqrtPricePrPd = Math.pow(sqrtRate, rightPt + 1);

    const numerator = sqrtPricePrPc - sqrtRate;
    const denominator = sqrtPricePrPd - sqrtPriceR;

    const amount = liquidity.times(numerator).div(denominator);
    return amount;
};

export const getLiquidityValue = (
    liquidity: LiquidityDetail,
): {amountXDecimal: number, amountYDecimal: number, amountX: BigNumber, amountY: BigNumber} => {
    
    let amountX = new BigNumber(0);
    let amountY = new BigNumber(0);
    const liquid = Number(liquidity.liquidity);
    const sqrtRate = Math.sqrt(1.0001);
    const leftPtNum = Number(liquidity.leftPt);
    const rightPtNum = Number(liquidity.rightPt);
    // compute amountY without currentPt
    if (leftPtNum < liquidity.currentPt) {
        const rightPt: number = Math.min(liquidity.currentPt, rightPtNum);
        const sqrtPriceR = tick2PriceSqrt(rightPt);
        const sqrtPriceL = tick2PriceSqrt(leftPtNum);
        amountY = getAmountY(new BigNumber(liquid), sqrtPriceL, sqrtPriceR, sqrtRate, false);
    }
    
    // compute amountX without currentPt
    if (rightPtNum > liquidity.currentPt + 1) {
        const leftPt: number = Math.max(liquidity.currentPt + 1, leftPtNum);
        const sqrtPriceR = tick2PriceSqrt(rightPtNum);
        amountX = getAmountX(new BigNumber(liquid), leftPt, rightPtNum, sqrtPriceR, sqrtRate, false);
    }

    // compute amountX and amountY on currentPt
    if (leftPtNum <= liquidity.currentPt && rightPtNum > liquidity.currentPt) {
        const liquidityValue = new BigNumber(liquidity.liquidity);
        const maxLiquidityYAtCurrentPt = new BigNumber(liquidity.currentLiquidity).minus(liquidity.currentLiquidityX);
        const liquidityYAtCurrentPt = liquidityValue.gt(maxLiquidityYAtCurrentPt) ? maxLiquidityYAtCurrentPt : liquidityValue;
        const liquidityXAtCurrentPt = liquidityValue.minus(liquidityYAtCurrentPt);
        const currentSqrtPrice = tick2PriceSqrt(liquidity.currentPt);
        amountX = amountX.plus(liquidity2AmountXAtPoint(liquidityXAtCurrentPt, currentSqrtPrice, false));
        amountY = amountY.plus(liquidity2AmountYAtPoint(liquidityYAtCurrentPt, currentSqrtPrice, false));
    }
    const amountXDecimal:number = amount2Decimal(
        amountX, liquidity.tokenX
    )?? 0;
    const amountYDecimal:number = amount2Decimal(
        amountY, liquidity.tokenY
    )?? 0;
    return {
        amountX, amountXDecimal,
        amountY, amountYDecimal
    };
};

export const getWithdrawLiquidityValue = (
    liquidity: LiquidityDetail,
    withdrawLiquidity: BigNumber
): {amountXDecimal: number, amountYDecimal: number, amountX: BigNumber, amountY: BigNumber} => {
    
    let amountX = new BigNumber(0);
    let amountY = new BigNumber(0);

    const sqrtRate = Math.sqrt(1.0001);
    const leftPtNum = Number(liquidity.leftPt);
    const rightPtNum = Number(liquidity.rightPt);
    // compute amountY without currentPt
    if (leftPtNum < liquidity.currentPt) {
        const rightPt: number = Math.min(liquidity.currentPt, rightPtNum);
        const sqrtPriceR = tick2PriceSqrt(rightPt);
        const sqrtPriceL = tick2PriceSqrt(leftPtNum);
        amountY = getAmountY(withdrawLiquidity, sqrtPriceL, sqrtPriceR, sqrtRate, false);
    }
    
    // compute amountX without currentPt
    if (rightPtNum > liquidity.currentPt + 1) {
        const leftPt: number = Math.max(liquidity.currentPt + 1, leftPtNum);
        const sqrtPriceR = tick2PriceSqrt(rightPtNum);
        amountX = getAmountX(withdrawLiquidity, leftPt, rightPtNum, sqrtPriceR, sqrtRate, false);
    }

    // compute amountX and amountY on currentPt
    if (leftPtNum <= liquidity.currentPt && rightPtNum > liquidity.currentPt) {
        const liquidityValue = withdrawLiquidity;
        const maxLiquidityYAtCurrentPt = new BigNumber(liquidity.currentLiquidity).minus(liquidity.currentLiquidityX);
        const liquidityYAtCurrentPt = liquidityValue.gt(maxLiquidityYAtCurrentPt) ? maxLiquidityYAtCurrentPt : liquidityValue;
        const liquidityXAtCurrentPt = liquidityValue.minus(liquidityYAtCurrentPt);
        const currentSqrtPrice = tick2PriceSqrt(liquidity.currentPt);
        amountX = amountX.plus(liquidity2AmountXAtPoint(liquidityXAtCurrentPt, currentSqrtPrice, false));
        amountY = amountY.plus(liquidity2AmountYAtPoint(liquidityYAtCurrentPt, currentSqrtPrice, false));
    }
    const amountXDecimal:number = amount2Decimal(
        amountX, liquidity.tokenX
    )?? 0;
    const amountYDecimal:number = amount2Decimal(
        amountY, liquidity.tokenY
    )?? 0;
    return {
        amountX, amountXDecimal,
        amountY, amountYDecimal
    };
};
export const _calciZiLiquidityAmountY = (
    amountX: BigNumber,
    tickLower: number,
    tickUpper: number,
    currentPt: number
): BigNumber=> {
    console.log(' -- calc amount of iZiSwapPool::tokenY');
    if (tickLower > currentPt) {
        console.log(' -- no need to deposit iZiSwapPool::tokenY');
        return new BigNumber(0);
    }
    if (tickUpper <= currentPt) {
        console.log(' -- no need to deposit iZiSwapPool::tokenX');
        return new BigNumber(0);
    }
    console.log(' -- tickLower: ', tickLower);
    console.log(' -- currentPt: ', currentPt);
    console.log(' -- tickUpper: ', tickUpper);

    const sqrtRate = Math.sqrt(1.0001);
    const sqrtPriceR = Math.pow(sqrtRate, tickUpper);
    const unitLiquidityAmountX = getAmountXNoRound(new BigNumber(1), currentPt + 1, tickUpper, sqrtPriceR, sqrtRate);
    const liquidityFloat = amountX.div(unitLiquidityAmountX);

    console.log(' -- liquidityFloat: ', liquidityFloat.toFixed(10));

    const sqrtPriceL = Math.pow(sqrtRate, tickLower);
    const sqrtPriceCurrentPtA1 = Math.pow(sqrtRate, currentPt + 1);
    const amountY = getAmountY(liquidityFloat, sqrtPriceL, sqrtPriceCurrentPtA1, sqrtRate, true);

    console.log(' -- amount of iZiSwapPool::tokenY: ', amountY.toFixed(0));
    return amountY;
};

export const _calciZiLiquidityAmountX = (
    amountY: BigNumber,
    tickLower: number,
    tickUpper: number,
    currentPt: number
): BigNumber => {
    console.log(' -- calc amount of iZiSwapPool::tokenX');
    if (tickUpper <= currentPt) {
        console.log(' -- no need to deposit iZiSwapPool::tokenX');
        return new BigNumber(0);
    }
    if (tickLower > currentPt) {
        console.log(' -- no need to deposit iZiSwapPool::tokenY');
        return new BigNumber(0);
    }

    console.log(' -- tickLower: ', tickLower);
    console.log(' -- currentPt: ', currentPt);
    console.log(' -- tickUpper: ', tickUpper);

    const sqrtRate = Math.sqrt(1.0001);
    const sqrtPriceL = Math.pow(sqrtRate, tickLower);
    const sqrtPriceCurrentPtA1 = Math.pow(sqrtRate, currentPt + 1);
    const unitLiquidityAmountY = getAmountYNoRound(new BigNumber(1), sqrtPriceL, sqrtPriceCurrentPtA1, sqrtRate);
    const liquidityFloat = amountY.div(unitLiquidityAmountY);

    console.log(' -- liquidityFloat: ', liquidityFloat.toFixed(10));

    const sqrtPriceR = Math.pow(sqrtRate, tickUpper);
    const amountX = getAmountX(liquidityFloat, currentPt + 1, tickUpper, sqrtPriceR, sqrtRate, true);

    console.log (' -- amount of iZiSwapPool::tokenX: ', amountX.toFixed(0));
    return amountX;
};

export const calciZiLiquidityAmountDesired = (
    tickLeft: number,
    tickUpper: number,
    currentPt: number,
    amount: BigNumber,
    amountIsTokenA: boolean,
    tokenAAddress: string,
    tokenBAddress: string,
): BigNumber => {
    if (amountIsTokenA) {
        if (tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()) {
            return _calciZiLiquidityAmountY(amount, tickLeft, tickUpper, currentPt);
        } else {
            return _calciZiLiquidityAmountX(amount, tickLeft, tickUpper, currentPt);
        }
    } else {
        if (tokenAAddress.toLowerCase() < tokenBAddress.toLowerCase()) {
            return _calciZiLiquidityAmountX(amount, tickLeft, tickUpper, currentPt);
        } else {
            return _calciZiLiquidityAmountY(amount, tickLeft, tickUpper, currentPt);
        }
    }
};

export const _computeWithdrawXYAtCurrPt = (
    liquidDelta: number,
    sqrtPrice: number,
    currY: number
): number[] => {
    let x = 0;
    let y = 0;
    const amountY = liquidDelta * sqrtPrice;
    y = Math.min(amountY, currY);
    const liquidY: number = y / sqrtPrice;
    const liquidX: number = Math.max(0, liquidDelta - liquidY);
    x = liquidX / sqrtPrice;
    return [x, y];
};

export const _computeWithdrawXY = (
    currY: number,
    currPt: number,
    liquidDelta: number,
    leftPt: number,
    rightPt: number
): number[] => {
    let x = 0;
    let y = 0;
    const sqrtRate = Math.sqrt(1.0001);
    if (leftPt < currPt) {
        const tokenYRightPt: number = Math.min(currPt, rightPt);
        const tokenYLeftSqrtPrice: number = tick2PriceSqrt(leftPt);
        const tokenYRightSqrtPrice: number = tick2PriceSqrt(tokenYRightPt);
        y = Number(getAmountY(new BigNumber(liquidDelta), tokenYLeftSqrtPrice, tokenYRightSqrtPrice, sqrtRate, false).toFixed(0));
    }
    if (rightPt > currPt + 1) {
        const tokenXLeftPt: number = Math.max(leftPt, currPt + 1);
        x = Number(getAmountX(new BigNumber(liquidDelta), tokenXLeftPt, rightPt, tick2PriceSqrt(rightPt), sqrtRate, false).toFixed(0));
    }
    if (leftPt <= currPt && rightPt > currPt) {
        const [xc, yc] = _computeWithdrawXYAtCurrPt(liquidDelta, tick2PriceSqrt(currPt), currY);
        x += xc;
        y += yc;
    }
    return [x,y];
};

export const getWithdrawToken = (
    allX: boolean,
    currY: number,
    currPt: number,
    liquidDelta: number,
    leftPt: number,
    rightPt: number,
): number[] => {
    if (allX) {
        currY = 0;
    }
    return _computeWithdrawXY(currY, currPt, liquidDelta, leftPt, rightPt);
};


export const validateCreatePoolParam = (tokenX: TokenInfoFormatted, tokenY: TokenInfoFormatted, fee: FeeTier, initPriceDecimal: number): string[] | undefined => {
    if (!tokenX || !tokenX.symbol) {
        return ['tokenX', 'tokenX.symbol is needed !'];
    }
    if (!tokenY || !tokenY.symbol) {
        return ['tokenY', 'tokenY.symbol is needed !'];
    }
    if (!isNumeric(fee)) {
        return ['fee', 'fee is invalid !'];
    }

    if (!isNumeric(initPriceDecimal) || initPriceDecimal <= 0) {
        return ['initPriceDecimal', 'initPriceDecimal is invalid !'];
    }

    return undefined;
};

export const calPoolPrice = (r: StateResponse, tokenLower: TokenInfoFormatted, tokenUpper: TokenInfoFormatted, isFlipped: boolean): number[] => {
    if (!r) { return [0, 0]; }
    let poolPrice = tick2Price(Number(r.currentPoint));
    let poolPriceDecimal = price2PriceDecimal(
        poolPrice,
        { left: tokenLower, right: tokenUpper },
    );
    if (isFlipped) {
        poolPrice = 1 / poolPrice;
        poolPriceDecimal = 1 / poolPriceDecimal;
    }
    return [poolPriceDecimal, poolPrice];
};

export const isERC20Token = async (web3: Web3, addr: string): Promise<boolean> => {
    if (!addr) {
        return false;
    }
    const token = getERC20ContractByAddress(web3, addr);
    if (!token) {
        return false;
    }
    try{
        await token?.methods.decimals().call();
    } catch (err) {
        return false;
    }
    return true;
};