import { BoxProps } from '@chakra-ui/layout';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { Box, HStack, Tooltip, Icon, IconButton, useColorMode, Stack } from '@chakra-ui/react';
import JumpButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/JumpButton/JumpButton';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { NFTId } from '../../../iZUMi-UI-toolkit/src/components/NFTId/NFTId';
import { getColorThemeSelector } from '../../../utils/funcs';
import { VeiZiAmount } from '../../VeiZi/components/VeiZiAmount';
import { i_text_copy_bold, i_text_d } from '../../../style';
import { useTranslation } from 'react-i18next';

export type Props = {
    entry: any;
} & BoxProps;

export const VeNFTBlock: React.FC<Props> = (props) => {
    const { entry, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return (
        <Card variant="grey" p="10px 40px 20px 40px" h="60px" ml="20px" {...rest}>
            {entry ? (
                <Stack spacing="10px" direction={{ base: 'column', sm: 'row' }} alignItems={{ base: 'start', sm: 'center' }}>
                    <NFTId direction="column" id={entry.nftId} w="75px" link={'https://www.izumi.finance'} name="veiZi NFTid" />
                    <HStack spacing="5px">
                        <Box w="11px" h="11px" borderRadius="6px" bg="#60DA00" />
                        <Text className={i_text_copy_bold} color="#60DA00" variant="caption">
                            {'Staked'}
                        </Text>
                    </HStack>
                    <VeiZiAmount balance={entry.veiZiDecimal} ml={{ base: '0px !important', sm: '60px !important' }} />
                    <JumpButton
                        variant="tertiary"
                        text={t('Manage')}
                        w="104px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="12px"
                        mt={{ base: '10px !important', sm: '-8px !important' }}
                        target="/veiZi/veNFT-manage"
                        param={{
                            nftId: entry.nftId,
                        }}
                    />
                    <Tooltip label={'You can stake a veiZi NFT. The veiZi in the NFT can then be used to boost up to 2.5X APR.'}>
                        <IconButton
                            size="sm"
                            aria-label=""
                            variant="ghost"
                            isRound={true}
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                        />
                    </Tooltip>
                </Stack>
            ) : (
                <HStack spacing="30px" pt="8px">
                    <Text variant="caption" className={i_text_d} fontSize="12px" color={colorTheme('tertiary.400', 'tertiary.500')}>
                        {t('Currently you have no staked veiZi NFT to boost APR.')}
                    </Text>
                    <JumpButton
                        variant="tertiary"
                        text={t('Manage veiZi NFT')}
                        w="154px"
                        h="40px"
                        fontClass={i_text_copy_bold}
                        fontSize="12px"
                        mt="-8px !important"
                        target="/veiZi/locker"
                    />
                </HStack>
            )}
        </Card>
    );
};
