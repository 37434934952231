import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { tokenSymbol2token } from '../config/tokens';
import { RootState } from '../state/store';
import { ChainId, TokenSymbol } from '../types/mod';
import { useTokenList } from './useTokenList';
import { useWeb3WithDefault } from './useWeb3WithDefault';

export interface TokenInfoFormatted {
    chainId: number;
    name: string;
    symbol: string;
    icon: string;
    address: string;
    wrapTokenAddress?: string;
    decimal: number;
    addTime?: Date;
    custom: boolean;
}

export type TokenConfigData = {
    loading: boolean;
    tokenConfig: TokenInfoFormatted[];
};

export const useTokenListFormatted = (): {
    loading: boolean;
    tokenList: TokenInfoFormatted[];
} => {
    const [tokenListFormatted, setTokenListFormatted] = useState([] as unknown as any);
    const { chainId } = useWeb3WithDefault();
    const { customTokens } = useSelector((state: RootState) => state);
    const { loading, tokenList } = useTokenList();

    useEffect(() => {
        if (!loading) {
            console.log('Load tokenListFormatted for ChainId:', chainId);
            const tokens = tokenList
                .filter((e) => e.chains.includes(chainId))
                .map((e: any) => {
                    return {
                        chainId,
                        name: e.name,
                        symbol: e.symbol,
                        icon: e.icon,
                        address: e.contracts[chainId].address,
                        decimal: e.contracts[chainId].decimal,
                        wrapTokenAddress: e.contracts[chainId].wrapTokenAddress,
                        custom: false,
                    } as TokenInfoFormatted;
                });
            if (tokens.length === 0) {
                // add iZi
                const tokeniZi = tokenSymbol2token(TokenSymbol.IZI, chainId);
                if (tokeniZi.name) {
                    tokens.push(tokeniZi);
                }
                // add eth
                const tokenETH = tokenSymbol2token(TokenSymbol.ETH, chainId);
                if (tokenETH.name) {
                    tokens.push(tokenETH);
                }
            }
            // append custom tokens
            let cusTokens = Object.keys(customTokens.tokens).map((k) => {
                return {
                    ...customTokens.tokens[k],
                    custom: true,
                    icon: '/assets/tokens/default.svg',
                };
            });
            cusTokens = cusTokens.filter((e) => e.chainId === chainId);
            cusTokens = cusTokens.filter((e) => !tokens.find((t) => t.address.toLowerCase() === e.address.toLowerCase()));

            setTokenListFormatted([...tokens, ...cusTokens]);
        }
    }, [customTokens, chainId, loading, tokenList]);

    return {
        loading: loading,
        tokenList: tokenListFormatted,
    };
};
