import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, HStack, Stack, Text, Image, useColorMode } from '@chakra-ui/react';

import { RootState } from '../../../../../state/store';
import { noto_t3, noto_t3_bold } from '../../../../../style';
import { formatNumber } from '../../../../../utils/tokenMath';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import useTokenEntity from '../../../../../state/models/hooks/useTokenEntity';
import { LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES, SWAP_ADDRESS } from '../../../../../config/trade/tradeContracts';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';
import { useTranslation } from 'react-i18next';

type AssetsProps = {
    themeColor: any;
    currentType: string;
};

const Assets: React.FC<AssetsProps> = (props) => {
    const { themeColor, currentType } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();
    const { pro, proControlState } = useSelector((state: RootState) => state);

    const [visible, setVisible] = useState(true);

    const limitOrderWithSwapManagerAddress = LIMIT_ORDER_WITH_SWAP_MANAGER_ADDRESSES[chainId];
    const swapAddress = SWAP_ADDRESS[chainId];

    const tokenXIsGasToken = isGasOrWrappedGasToken(pro.tokenX, chainId);
    const tokenYIsGasToken = isGasOrWrappedGasToken(pro.tokenY, chainId);
    const gasToken = useGasToken();

    const limitXEntry = useTokenEntity(
        proControlState.useGasToken && tokenXIsGasToken ? gasToken : pro.tokenX,
        limitOrderWithSwapManagerAddress
    );
    const limitYEntry = useTokenEntity(
        proControlState.useGasToken && tokenYIsGasToken ? gasToken : pro.tokenY,
        limitOrderWithSwapManagerAddress
    );
    const marketXEntry = useTokenEntity(proControlState.useGasToken && tokenXIsGasToken ? gasToken : pro.tokenX, swapAddress);
    const marketYEntry = useTokenEntity(proControlState.useGasToken && tokenYIsGasToken ? gasToken : pro.tokenY, swapAddress);

    const limitBalance = [limitXEntry, limitYEntry];
    const marketBalance = [marketXEntry, marketYEntry];
    const resultBalance = currentType === 'Limit' ? limitBalance : marketBalance;

    return (
        <Stack className={noto_t3} bg={themeColor} borderRadius="6px" py="16px" px="10px">
            <HStack>
                <Text className={noto_t3_bold} color={colorTheme('#767676', '#83789B')}>
                    {t('Assets')}
                </Text>
                <Image
                    cursor="pointer"
                    boxSize="15px"
                    src={
                        visible
                            ? colorTheme('/assets/pro/orderForm/show.svg', '/assets/pro/orderForm/darkShow.svg')
                            : colorTheme('/assets/pro/orderForm/hide.svg', '/assets/pro/orderForm/darkHide.svg')
                    }
                    onClick={() => {
                        setVisible(!visible);
                    }}
                ></Image>
            </HStack>
            <Divider></Divider>
            <Stack>
                {resultBalance.map((item, index) => {
                    return (
                        <Stack
                            key={index}
                            justifyContent="space-between"
                            direction={item.tokenBalance() < 1000000000000 ? 'row' : 'column'}
                        >
                            <Text color={colorTheme('#767676', '#83789B')}>{item.token.symbol} Available</Text>
                            <HStack ml="auto !important">
                                <Text color={colorTheme('#332D43', '#C9C3D7')}>
                                    {visible ? formatNumber(item.tokenBalance(), 2, 2, true) : '********'}
                                </Text>
                                <Text color={colorTheme('#767676', '#83789B')}>{item.token.symbol}</Text>
                            </HStack>
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};

export default Assets;
