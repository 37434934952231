import { BoxProps, HStack, Image, useColorMode, Text } from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { getColorThemeSelector } from '../../../utils/funcs';
import { Heading } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { TokenSelectModal } from '../components/TokenSelectModal';
import { useState } from 'react';
import placeholder from '../../../assets/placeholder.png';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';
import { i_text_copy } from '../../../style';
import { TokenInfoFormatted } from '../../../hooks/useTokenListFormatted';
import { useTranslation } from 'react-i18next';
import { AdaptationMode } from '../../../components/layout/PageLayout';
import truncateString from '../../../utils/truncateString';

type CardSizeProperty = {
    tokenIconSize: string;
    btnImgSize: string;
    headingLevel: '1' | '2' | '3' | '4' | '5' | '6';
    borderRadius: string;
};
type TokenSelectButtonProps = {
    token: TokenInfoFormatted;
    handleSelect: (tokenInfo: TokenInfoFormatted) => void;
    size?: 'xs' | 'sm' | 'md' | 'lg';
    type?: AdaptationMode;
} & BoxProps;

export const TokenSelectButton: React.FC<TokenSelectButtonProps> = (props) => {
    const { size = 'lg', token, handleSelect, type, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [openModal, setOpenModal] = useState(false);
    const { tradeMode } = useSelector((state: RootState) => state);
    const isSimpleMode = tradeMode.isSimpleMode;

    const sizeProperty = (function (): CardSizeProperty {
        switch (size) {
            case 'xs':
                return {
                    tokenIconSize: '25px',
                    btnImgSize: '20px',
                    headingLevel: '5',
                    borderRadius: '5px',
                };
            case 'sm':
                return {
                    tokenIconSize: '25px',
                    btnImgSize: '20px',
                    headingLevel: '6',
                    borderRadius: '5px',
                };
            default:
                return {
                    tokenIconSize: '50px',
                    btnImgSize: '30px',
                    headingLevel: '4',
                    borderRadius: '5px',
                };
        }
    })();
    return type === 'mobile' ? (
        <>
            <HStack
                h="100%"
                onClick={() => {
                    setOpenModal(true);
                }}
                {...rest}
            >
                {token.symbol ? (
                    <HStack>
                        <Image
                            w={sizeProperty.tokenIconSize}
                            h={sizeProperty.tokenIconSize}
                            borderRadius={sizeProperty.borderRadius}
                            src={token.icon}
                            fallbackSrc={placeholder}
                        ></Image>
                        <Text className={i_text_copy} fontSize="14px !important" color={colorTheme('#24193B', '#B59DE7')}>
                            {truncateString(token.symbol)}
                        </Text>
                    </HStack>
                ) : (
                    <Heading level={sizeProperty.headingLevel} color={colorTheme('tertiary.800', 'tertiary.200')}>
                        {t('Select a Token')}
                    </Heading>
                )}

                <Image
                    boxSize="10px"
                    borderRadius="50%"
                    ml="10px !important"
                    cursor="pointer"
                    src={process.env.PUBLIC_URL + colorTheme('/assets/tokenSelect/arrowDown.svg', '/assets/tokenSelect/arrowDownDark.svg')}
                    fallbackSrc={placeholder}
                />
            </HStack>
            <TokenSelectModal
                isOpen={openModal}
                handleSelect={handleSelect}
                onClose={() => {
                    setOpenModal(false);
                }}
            />
        </>
    ) : (
        <>
            <Card
                variant="lightGary"
                w={isSimpleMode ? '286px' : '100%'}
                h={isSimpleMode ? { base: '80px', sm: '60px', xxl: '80px' } : '40px'}
                pr={{ base: '8px', sm: '16px', xxl: '24px' }}
                pl={isSimpleMode ? '44px' : '15px'}
                onClick={() => {
                    setOpenModal(true);
                }}
                {...rest}
            >
                <HStack spacing="0" w="100%" h="100%" justifyContent="space-between" alignItems="center">
                    {token.symbol ? (
                        <HStack>
                            <Image
                                mr={isSimpleMode ? { base: '0px', sm: '8px', xxl: '27px' } : '0px'}
                                w={
                                    isSimpleMode
                                        ? { base: sizeProperty.tokenIconSize, sm: '25px', xxl: sizeProperty.tokenIconSize }
                                        : sizeProperty.tokenIconSize
                                }
                                h={
                                    isSimpleMode
                                        ? { base: sizeProperty.tokenIconSize, sm: '25px', xxl: sizeProperty.tokenIconSize }
                                        : sizeProperty.tokenIconSize
                                }
                                borderRadius={sizeProperty.borderRadius}
                                src={token.icon}
                                fallbackSrc={placeholder}
                            ></Image>
                            {!isSimpleMode ? (
                                <Text className={i_text_copy} fontSize={token.symbol.length > 4 ? '12px !important' : '14px !important'}>
                                    {' '}
                                    {truncateString(token.symbol)}
                                </Text>
                            ) : (
                                <Heading
                                    level={sizeProperty.headingLevel}
                                    fontSize={
                                        token.symbol.length > 4
                                            ? '14px !important'
                                            : { base: '18px !important', sm: '14px !important', xxl: '18px !important' }
                                    }
                                    color={colorTheme('tertiary.800', 'tertiary.200')}
                                >
                                    {truncateString(token.symbol)}
                                </Heading>
                            )}
                        </HStack>
                    ) : (
                        <Heading
                            level={sizeProperty.headingLevel}
                            color={colorTheme('tertiary.800', 'tertiary.200')}
                            fontSize={{ base: '18px !important', sm: '14px !important', xxl: '18px !important' }}
                        >
                            {t('Select a Token')}
                        </Heading>
                    )}

                    <Image
                        borderRadius="50%"
                        //border="1.5px solid #6A5E86"
                        cursor="pointer"
                        w={sizeProperty.btnImgSize}
                        h={sizeProperty.btnImgSize}
                        src={process.env.PUBLIC_URL + colorTheme('/assets/trade/lightClick.svg', '/assets/trade/darkClick.svg')}
                        fallbackSrc={placeholder}
                    />
                </HStack>
            </Card>
            <TokenSelectModal
                isOpen={openModal}
                handleSelect={handleSelect}
                onClose={() => {
                    setOpenModal(false);
                }}
            />
        </>
    );
};
