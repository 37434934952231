import {
    Box,
    BoxProps,
    HStack,
    Image,
    useColorMode,
    VStack,
    Divider,
    Center,
    Stack,
    Switch,
    Tooltip,
    Icon,
    IconButton,
    useInterval,
} from '@chakra-ui/react';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { default as React, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IZISWAP_MINT_CONFIG } from '../../../../../config/bizConfig';
import { getChain, getTxLink } from '../../../../../config/chains';
import { MULTICALL_ADDRESS } from '../../../../../config/multicall/multicallContracts';
import { LIQUIDITY_MANAGER_ADDRESS, BOX_ADDRESS, QUOTER_TYPE } from '../../../../../config/trade/tradeContracts';
import { useBoxContract, useLiquidityManagerContract } from '../../../../../hooks/useContracts';
import { useGasPrice } from '../../../../../hooks/useGasPrice';
import { useRematchDispatch } from '../../../../../hooks/useRematchDispatch';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { useWeb3WithDefault } from '../../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ErrorInfo } from '../../../../../iZUMi-UI-toolkit/src/components/ErrorInfo/ErrorInfo';
import { useCustomToast, ToastLink } from '../../../../../iZUMi-UI-toolkit/src/components/Toast/Toast';
import { getSortedToken } from '../../../../../state/models/common/positionPoolHelper';
import { point2PriceDecimal } from '../../../../../state/models/farm/iZiSwap/price';
import useTokenEntity, { TokenEntity } from '../../../../../state/models/hooks/useTokenEntity';
import { SwapTag } from '../../../../../state/models/trade/aggregator/config';
import { PreQueryParams } from '../../../../../state/models/trade/aggregator/model';
import { validateCreatePoolParam } from '../../../../../state/models/trade/liquidity/funcs';
import { validateMintForm, getFieldValidateInfo } from '../../../../../state/models/trade/liquidity/validationHelper';
import { fetchPoolState, getiZiSwapPoolKey, createPool } from '../../../../../state/models/trade/pools/controllers';
import { PoolState } from '../../../../../state/models/trade/pools/types';
import { TAP_PROXY_ADDRESS } from '../../../../../state/models/trade/tap/config';
import { QueryConfig, TapModeStatus } from '../../../../../state/models/trade/tap/types';
import { RootState, RootDispatch } from '../../../../../state/store';
import { i_text_copy_bold, i_h2, i_text_copy, i_text_Bold } from '../../../../../style';
import { getMulticallContract } from '../../../../../utils/contractHelpers';
import { getColorThemeSelector, defaultPrecision, identity } from '../../../../../utils/funcs';
import { izumiFeeToTickSpacingMapping } from '../../../../../utils/tickMath';
import { getSwapTokenAddress, formatNumber } from '../../../../../utils/tokenMath';
import { isNumeric } from '../../../../../utils/valid';
import { AmountInput } from '../../../components/AmountInput';
import NotConnectModal from '../../../components/NotConnectModal';
import { TokenBalanceBlock } from '../../../components/TokenBalanceBlock';
import { mintNeedTokenA } from '../../components/funcs';
import { TapConfirmPopularModal } from './TapConfirmPopularModal';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import placeholder from '../../../../../assets/placeholder.png';
import { Chart } from '../../../components/Chart/Chart';
import { CustomNumberInput } from '../components/NumberInput/NumberInput';
import { PopularMintFormParams, SetMintFormCurrentPriceParams } from '../../../../../state/models/trade/liquidity/models/popularMintModels';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { isGasOrWrappedGasToken } from '../../../../../config/tokens';
import { useGasToken } from '../../../../../state/models/hooks/useGasToken';

type AddPopularLiquidityFormProps = {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    fee: number;
    leftRange: number;
    rightRange: number;
} & BoxProps;

const AddPopularLiquidityForm: React.FC<AddPopularLiquidityFormProps> = (props) => {
    const { tokenA, tokenB, fee, leftRange, rightRange, ...rest } = props;
    const { t } = useTranslation();
    const toast = useCustomToast();
    const isMobile = useIsMobile();
    const gasToken = useGasToken();
    const { chainId, web3, account } = useWeb3WithDefault();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { popularMintForm: mintForm, swapPreQueryModel } = useSelector((state: RootState) => state);
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const liquidityManagerContract = useLiquidityManagerContract();
    const boxContract = useBoxContract();

    const liquidityManagerAddress = LIQUIDITY_MANAGER_ADDRESS[chainId];
    const boxAddress = BOX_ADDRESS[chainId];

    const { gasPrice } = useGasPrice();

    let token0Entity = undefined as unknown as TokenEntity;
    let token1Entity = undefined as unknown as TokenEntity;

    const token0NotWrapToken = !mintForm.token0.wrapTokenAddress;
    const token1NotWrapToken = !mintForm.token1.wrapTokenAddress;

    const noWrapToken = token0NotWrapToken && token1NotWrapToken;

    if (noWrapToken) {
        token0Entity = useTokenEntity(mintForm.token0, liquidityManagerAddress);
        token1Entity = useTokenEntity(mintForm.token1, liquidityManagerAddress);
    } else {
        token0Entity = token0NotWrapToken
            ? useTokenEntity(mintForm.token0, boxAddress)
            : useTokenEntity(mintForm.token0, mintForm.token0.wrapTokenAddress, boxAddress);
        token1Entity = token1NotWrapToken
            ? useTokenEntity(mintForm.token1, boxAddress)
            : useTokenEntity(mintForm.token1, mintForm.token1.wrapTokenAddress, boxAddress);
    }

    const amount0Desired = mintForm?.amount0Desired?.toFixed(0) ?? '0';
    const amount1Desired = mintForm?.amount1Desired?.toFixed(0) ?? '0';

    const token0AllApproved = token0Entity.isApproved(amount0Desired) && (token0NotWrapToken ? true : token0Entity.isDepositApproved());
    const token1AllApproved = token1Entity.isApproved(amount1Desired) && (token1NotWrapToken ? true : token1Entity.isDepositApproved());

    const [poolState, setPoolState] = useState<PoolState>(undefined as unknown as PoolState);
    const poolExist = !!poolState;

    const tokenFeeFilled = mintForm.token0 && mintForm.token1 && mintForm.fee ? true : false;

    const [showTapConfirmModal, setShowTapConfirmModal] = useState(false);

    const [initPoolPriceDecimal, setInitPoolPriceDecimal] = useState<number>(0);

    const [isInputFromFocus, setIsInputFromFocus] = useState(false);
    const [isInputToFocus, setIsInputToFocus] = useState(false);

    const multicallContract = getMulticallContract(MULTICALL_ADDRESS[chainId], web3);

    const pathQueryPluginConfig: { [swapTag: string]: any } = { [SwapTag.iZiSwap]: QUOTER_TYPE.limit };
    const queryConfig = {
        swapTags: swapPreQueryModel.swapTag,
        preQueryResult: swapPreQueryModel.preQueryResult,
        pathQueryPluginConfig,
    } as QueryConfig;

    const tapProxyAddress = TAP_PROXY_ADDRESS[chainId];

    const fetchPoolInfo = (
        token0: TokenInfoFormatted,
        token1: TokenInfoFormatted,
        fee: FeeTier,
        isTapMode: boolean,
        leftRange: number,
        rightRange: number
    ) => {
        if (!token0.symbol || !token1.symbol || !fee || !chainId) {
            setPoolState(undefined as unknown as PoolState);
            return;
        }
        const [tokenLower, tokenUpper] = getSortedToken(token0, token1);

        fetchPoolState({
            web3,
            chainId,
            baseContract: liquidityManagerContract,
            tokenA: tokenLower,
            tokenB: tokenUpper,
            fee,
        }).then((r: PoolState) => {
            setPoolState(r);
            if (r && r.currentPoint) {
                const currentPoint = Number(r.currentPoint);
                if (!isTapMode) {
                    dispatch.popularMintForm.initMintForm({
                        chainId,
                        token0,
                        token1,
                        currentPoint,
                        fee,
                        spacingMapping: izumiFeeToTickSpacingMapping,
                        leftRange,
                        rightRange,
                        isTapMode,
                    } as PopularMintFormParams);
                } else {
                    dispatch.swapPreQueryModel
                        .preQuery({
                            chainId,
                            web3,
                            tokenIn: token0,
                            tokenOut: token1,
                            multicall: multicallContract,
                        } as PreQueryParams)
                        .then(() => {
                            dispatch.popularMintForm.initMintForm({
                                chainId,
                                token0,
                                token1,
                                currentPoint,
                                fee,
                                spacingMapping: izumiFeeToTickSpacingMapping,
                                leftRange,
                                rightRange,
                                isTapMode,
                            } as PopularMintFormParams);
                        })
                        .catch((e: any) => {
                            console.info(e.message);
                        });
                }
            }
        });
    };
    useEffect(() => {
        if (!tokenA || !tokenB) {
            return;
        }
        const tokenAIsGasToken = isGasOrWrappedGasToken(tokenA, chainId);
        const tokenBIsGasToken = isGasOrWrappedGasToken(tokenB, chainId);
        if (!tokenA.wrapTokenAddress || !mintForm.isTapMode) {
            const tokenInfo = tokenAIsGasToken ? gasToken : tokenA;
            dispatch.popularMintForm.setMintFormToken({
                isUpper: false,
                chainId,
                tokenInfo,
            });
        }
        if (!tokenB.wrapTokenAddress || !mintForm.isTapMode) {
            const tokenInfo = tokenBIsGasToken ? gasToken : tokenB;
            dispatch.popularMintForm.setMintFormToken({
                isUpper: true,
                chainId,
                tokenInfo,
            });
        }

        dispatch.popularMintForm.setMintFormFee(fee as FeeTier);
        if (tokenAIsGasToken) {
            fetchPoolInfo(gasToken, tokenB, fee as FeeTier, mintForm.isTapMode, leftRange, rightRange);
        } else if (tokenBIsGasToken) {
            fetchPoolInfo(tokenA, gasToken, fee as FeeTier, mintForm.isTapMode, leftRange, rightRange);
        } else {
            fetchPoolInfo(tokenA, tokenB, fee as FeeTier, mintForm.isTapMode, leftRange, rightRange);
        }
    }, [dispatch.popularMintForm, liquidityManagerContract, web3, chainId, leftRange, rightRange, gasToken]);

    const refreshPoolInfo = (token0: TokenInfoFormatted, token1: TokenInfoFormatted, fee: FeeTier) => {
        if (!token0.symbol || !token1.symbol || !fee || !chainId) {
            setPoolState(undefined as unknown as PoolState);
            return;
        }
        const [tokenLower, tokenUpper] = getSortedToken(token0, token1);

        fetchPoolState({
            web3,
            chainId,
            baseContract: liquidityManagerContract,
            tokenA: tokenLower,
            tokenB: tokenUpper,
            fee,
        }).then((r: PoolState) => {
            setPoolState(r);
            if (r && r.currentPoint) {
                const currentPoint = Number(r.currentPoint);
                dispatch.popularMintForm.setCurrentPointUnTap({
                    chainId,
                    token0,
                    token1,
                    currentPoint,
                    fee,
                } as SetMintFormCurrentPriceParams);
            }
        });
    };

    useInterval(() => {
        if (!mintForm.isTapMode) {
            refreshPoolInfo(mintForm.token0, mintForm.token1, mintForm.fee);
        }
    }, IZISWAP_MINT_CONFIG.AUTO_REFRESH_POOL_STATE_INTERVAL);

    const validateResult = validateMintForm(mintForm, [token0Entity.tokenBalance(), token1Entity.tokenBalance()]);
    const isShowError = tokenFeeFilled && poolExist && validateResult && validateResult?.length > 0;
    const errorInfo0 = getFieldValidateInfo(validateResult, 'amount0Desired');
    const errorInfo1 = getFieldValidateInfo(validateResult, 'amount1Desired');

    const initPoolValidateResult = validateCreatePoolParam(mintForm.token0, mintForm.token1, mintForm.fee, initPoolPriceDecimal);

    if (!account) {
        return (
            <>
                <NotConnectModal mt="0px" />
            </>
        );
    }

    let needToken0 = true;
    let needToken1 = true;
    let price0By1Decimal = 0;
    if (poolState) {
        const currentPoint = Number(poolState.currentPoint);
        const currentPoolKey = getiZiSwapPoolKey(mintForm.token0, mintForm.token1, mintForm.fee);
        if (currentPoolKey === poolState.poolKey) {
            needToken0 = mintNeedTokenA(
                getSwapTokenAddress(mintForm.token0),
                getSwapTokenAddress(mintForm.token1),
                mintForm.leftPoint,
                mintForm.rightPoint,
                currentPoint
            );
            needToken1 = mintNeedTokenA(
                getSwapTokenAddress(mintForm.token1),
                getSwapTokenAddress(mintForm.token0),
                mintForm.leftPoint,
                mintForm.rightPoint,
                currentPoint
            );
            price0By1Decimal = point2PriceDecimal(mintForm.token0, mintForm.token1, currentPoint);
        }
    }

    return (
        <>
            <VStack w={{ base: '100%', sm: '704px' }} alignSelf="center" {...rest}>
                <Stack w="100%" justifyContent="space-between" direction={{ base: 'column', sm: 'row' }} position="relative">
                    <HStack
                        w={{ base: '100%', sm: '486px' }}
                        h={{ base: '58px', sm: '80px' }}
                        bg={colorTheme('#F8F8FB', '#302543')}
                        px={{ base: '20px', sm: '50px' }}
                        spacing="0px"
                    >
                        <HStack w="50%" spacing={{ base: '7px', sm: '18px' }} justifyContent={{ base: 'start', sm: 'center' }}>
                            <Image boxSize={{ base: '25px', sm: '30px' }} src={mintForm.token0 && mintForm.token0.icon}></Image>
                            <Text variant="caption" fontSize={{ base: '18px', sm: '20px' }} fontWeight="600">
                                {mintForm.token0 && mintForm.token0.symbol}
                            </Text>
                        </HStack>
                        <Image
                            w="18px"
                            src={process.env.PUBLIC_URL + '/assets/swap/transicon_h.svg'}
                            fallbackSrc={placeholder}
                            disabled={true}
                            // onClick={() => {
                            //     dispatch.popularMintForm.toggleTokenOrder();
                            // }}
                            cursor="pointer"
                        />
                        <HStack w="50%" spacing={{ base: '7px', sm: '18px' }} justifyContent={{ base: 'end', sm: 'center' }}>
                            <Image boxSize={{ base: '25px', sm: '30px' }} src={mintForm.token1 && mintForm.token1.icon}></Image>
                            <Text variant="caption" fontSize={{ base: '18px', sm: '20px' }} fontWeight="600">
                                {mintForm.token1 && mintForm.token1.symbol}
                            </Text>
                        </HStack>
                    </HStack>
                    <VStack
                        w={{ base: '82px', sm: '186px' }}
                        h={{ base: '21px', sm: '80px' }}
                        className={i_text_Bold}
                        justifyContent="center"
                        color={colorTheme('#0166FF', '#5B85C6')}
                        bg={colorTheme('#EDF4FF', '#262A4A')}
                        borderRadius="5px"
                        spacing="3px"
                        position={{ base: 'absolute', sm: 'unset' }}
                        top="-18px"
                        right="10px"
                        border={{ base: '0.4px solid #0166FF', sm: 'unset' }}
                    >
                        <Stack direction={{ base: 'row', sm: 'column' }}>
                            <Text variant="caption" fontSize={{ base: '12px', sm: '16px' }}>
                                {isMobile ? 'Fee' : 'Fee Rate'}
                            </Text>
                            <Text
                                variant="caption"
                                fontSize={{ base: '12px', sm: '18px' }}
                                fontWeight="600"
                                color={colorTheme('#0166FF', '#AECEFF')}
                            >
                                {mintForm.fee + '%'}
                            </Text>
                        </Stack>
                    </VStack>
                </Stack>

                {!tokenFeeFilled && (
                    <Card w="100%" h="60px" spacing="16px" mt="30px !important">
                        <Center w="100%" h="100%">
                            <Text variant="caption" color="tertiary.400" fontSize="14px">
                                {t('Please choose tokens and fees tier')} ...
                            </Text>
                        </Center>
                    </Card>
                )}
                {tokenFeeFilled && !poolExist && (
                    <Card w="100%" h="260px" spacing="16px" mt="30px !important">
                        <VStack w="100%" h="256px" bg={colorTheme('undefined', '#18102D9E')} alignItems="left" p="22px 35px 10px 30px">
                            <Text variant="caption-bold" color={colorTheme('tertiary.500', 'tertiary.300')}>
                                {t('Initialize the pool')} :
                            </Text>
                            <Text variant="caption" mt="30px !important" color={colorTheme('tertiary.500', 'tertiary.400')}>
                                {t('This pool is not initialized before.')} <br />
                                {t(
                                    'To initialize, select a starting price for the pool and the enter your liquidity price range and deposit amount.'
                                )}
                            </Text>

                            <Text
                                variant="caption-bold"
                                color={colorTheme('secondary.700', 'secondary.200')}
                                className={i_text_copy_bold}
                                mt="20px !important"
                            >
                                {t('Gas fees will be higher than usual!')}
                            </Text>

                            <HStack mt="30px !important" alignItems="center">
                                <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.300')}>
                                    {t('Starting price')} :
                                </Text>

                                <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.300')} ml="30px !important">
                                    1 {mintForm.token0.symbol} =
                                </Text>
                                <CustomNumberInput
                                    w="150px"
                                    inputBg={colorTheme('#F8F7FF', '#211834')}
                                    inputValue={initPoolPriceDecimal.toPrecision(8)}
                                    onBlur={(value) => {
                                        isNumeric(value) && setInitPoolPriceDecimal(Number(value));
                                    }}
                                />
                                <Text variant="caption-bold" color={colorTheme('tertiary.700', 'tertiary.300')}>
                                    {mintForm.token1.symbol}
                                </Text>
                            </HStack>
                        </VStack>
                    </Card>
                )}

                {tokenFeeFilled && poolExist && (
                    <Stack
                        w="100%"
                        h={{ base: 'unset', sm: '430px' }}
                        direction={{ base: 'column', sm: 'row' }}
                        spacing="16px"
                        mt="30px !important"
                    >
                        <VStack w={{ base: '100%', sm: '293px' }} h="100%" position="relative">
                            <HStack
                                w={{ base: '100%', sm: 'unset' }}
                                mt="-10px !important"
                                mb="10px !important"
                                p="0 !important"
                                spacing={{ base: '20px', sm: '30px' }}
                            >
                                <Text variant="caption-bold" alignSelf="center" color={colorTheme('#6A5E86', '#F5F5F5')}>
                                    {t('Volume')}
                                </Text>
                                <Card
                                    w={{ base: 'unset', sm: '100%' }}
                                    variant="deep"
                                    py="2px"
                                    px="10px"
                                    display="flex"
                                    boxShadow={{ base: 'unset', sm: colorTheme('base', 'undefined') }}
                                    //justifyContent="space-between"
                                >
                                    <HStack spacing="10px" justifyItems="center">
                                        <Text variant="caption-bold" color="#938CA4">
                                            {t('Tap')}
                                        </Text>

                                        <HStack>
                                            <Switch
                                                isChecked={mintForm.isTapMode ? true : false}
                                                disabled={!tapProxyAddress}
                                                onChange={(e) => {
                                                    if (
                                                        !e.target.checked ||
                                                        (!mintForm.token0.wrapTokenAddress && !mintForm.token1.wrapTokenAddress)
                                                    ) {
                                                        dispatch.popularMintForm.setMintFormTapMode(e.target.checked);
                                                        fetchPoolInfo(
                                                            mintForm.token0,
                                                            mintForm.token1,
                                                            mintForm.fee,
                                                            e.target.checked,
                                                            leftRange,
                                                            rightRange
                                                        );
                                                    }
                                                }}
                                                size="md"
                                            />
                                            <Tooltip
                                                label={
                                                    'If switch on, some tokens A may swap into token B first and then add as a Liquidity together.'
                                                }
                                            >
                                                <IconButton
                                                    size="sm"
                                                    aria-label=""
                                                    variant="ghost"
                                                    isRound={true}
                                                    icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                                                />
                                            </Tooltip>
                                        </HStack>
                                    </HStack>
                                </Card>
                            </HStack>

                            <Card
                                variant="tabCard"
                                h="172px"
                                w="100%"
                                p="10px"
                                border={
                                    isInputFromFocus
                                        ? '2px solid #A880FF'
                                        : errorInfo0
                                        ? '2px solid #E05757'
                                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                                }
                            >
                                <TokenBalanceBlock token={mintForm.token0} balance={token0Entity.tokenBalance()} p="10px" />
                                <Divider m="0 !important" borderColor={colorTheme('#EBEAEC', '#4A4868')} borderBottomWidth="2px" />

                                <Box w="100%" px="10px">
                                    {needToken0 ? (
                                        <AmountInput
                                            handleSetValue={(value: number) => {
                                                isNumeric(value) &&
                                                    dispatch.popularMintForm.setMintFormAmountDesired({
                                                        isDesired0: true,
                                                        desiredAmountDecimal: value,
                                                        currentPoint: Number(poolState.currentPoint),
                                                    });
                                            }}
                                            token={mintForm.token0}
                                            price={token0Entity.tokenPrice()}
                                            balance={token0Entity.tokenBalance()}
                                            inputValue={mintForm.amount0DecimalDesired && Number(mintForm.amount0DecimalDesired).toFixed(4)}
                                            fontClass={i_h2}
                                            setIsInputFocus={setIsInputFromFocus}
                                        />
                                    ) : (
                                        <Center w="100%" h="50px">
                                            <Text color="tertiary.300" variant="caption">
                                                {t('no need')}
                                            </Text>
                                        </Center>
                                    )}
                                </Box>
                            </Card>

                            <Card
                                h="170px"
                                w="100%"
                                variant="deep"
                                mt="30px !important"
                                p="10px"
                                border={
                                    isInputToFocus
                                        ? '2px solid #A880FF'
                                        : errorInfo1
                                        ? '2px solid #E05757'
                                        : colorTheme('2px solid #ffffff', '2px solid #211834')
                                }
                            >
                                <TokenBalanceBlock token={mintForm.token1} balance={token1Entity.tokenBalance()} p="10px" />
                                <Divider m="0 !important" borderColor={colorTheme('#EBEAEC', '#4A4868')} borderBottomWidth="2px" />

                                <Box w="100%" px="10px">
                                    {needToken1 ? (
                                        <AmountInput
                                            handleSetValue={(value: number) => {
                                                isNumeric(value) &&
                                                    dispatch.popularMintForm.setMintFormAmountDesired({
                                                        isDesired0: false,
                                                        desiredAmountDecimal: value,
                                                        currentPoint: Number(poolState.currentPoint),
                                                    });
                                            }}
                                            token={mintForm.token1}
                                            price={token1Entity.tokenPrice()}
                                            balance={token1Entity.tokenBalance()}
                                            inputValue={mintForm.amount1DecimalDesired && Number(mintForm.amount1DecimalDesired).toFixed(4)}
                                            fontClass={i_h2}
                                            setIsInputFocus={setIsInputToFocus}
                                        />
                                    ) : (
                                        <Center w="100%" h="50px">
                                            <Text color="tertiary.300" variant="caption">
                                                {t('no need')}
                                            </Text>
                                        </Center>
                                    )}
                                </Box>
                            </Card>

                            <Box
                                position="absolute"
                                borderRadius="50%"
                                border={colorTheme('6px solid #F7F6F9', '6px solid #150E29')}
                                w="54px"
                                h="54px"
                                top="220px"
                                left="50%"
                                transform="translateX(-50%) translateY(-50%)"
                                backgroundImage={colorTheme(
                                    process.env.PUBLIC_URL + '/assets/addLiquidity/add.svg',
                                    process.env.PUBLIC_URL + '/assets/addLiquidity/darkAdd.svg'
                                )}
                                backgroundSize="auto"
                                backgroundRepeat="no-repeat"
                                backgroundPosition="center"
                            />
                        </VStack>

                        <VStack w={{ base: '100%', sm: '394px' }} h="100%">
                            <Text variant="caption-bold" color={colorTheme('#6A5E86', '#F5F5F5')} alignSelf="center" mb="20px !important">
                                {t('Price Range')}
                            </Text>

                            <Card h="372px" w="100%" variant="deep" justifyContent="center">
                                <VStack w="100%" h="256px" bg={colorTheme('undefined', '#18102D9E')} alignItems="center">
                                    <HStack alignSelf="center">
                                        <Text
                                            variant="caption"
                                            className={i_text_copy_bold}
                                            color={colorTheme('tertiary.400', 'tertiary.300')}
                                        >
                                            {t('Current Price')} :
                                        </Text>
                                        <Text variant="caption" color={colorTheme('tertiary.700', 'tertiary.50')} className={i_text_copy}>
                                            1 {mintForm.token0.symbol} = {formatNumber(price0By1Decimal, 2, 2, true)}{' '}
                                            {mintForm.token1.symbol}
                                        </Text>
                                    </HStack>
                                    <Chart
                                        dim={{
                                            width: 345,
                                            height: 220,
                                        }}
                                        zoom={0}
                                        currentPrice={price0By1Decimal}
                                        minPrice={mintForm.lowerPrice0By1Decimal}
                                        maxPrice={mintForm.upperPrice0By1Decimal}
                                    />
                                </VStack>
                                <VStack pt="10px">
                                    <HStack w="80%" justifyContent="space-between">
                                        <VStack w="149px">
                                            <Text
                                                variant="caption"
                                                className={i_text_copy_bold}
                                                color={colorTheme('tertiary.400', 'tertiary.300')}
                                            >
                                                {t('Min Price')} :
                                            </Text>
                                            <Card variant="deep">
                                                <CustomNumberInput
                                                    inputValue={mintForm.lowerPrice0By1Decimal?.toPrecision(
                                                        defaultPrecision(mintForm.lowerPrice0By1Decimal)
                                                    )}
                                                    size="md"
                                                    errorInfo={getFieldValidateInfo(validateResult, 'leftPoint')}
                                                />
                                            </Card>
                                        </VStack>
                                        <VStack w="149px">
                                            <Text
                                                variant="caption"
                                                className={i_text_copy_bold}
                                                color={colorTheme('tertiary.400', 'tertiary.300')}
                                            >
                                                {t('Max Price')} :
                                            </Text>
                                            <Card variant="deep">
                                                <CustomNumberInput
                                                    inputValue={mintForm.upperPrice0By1Decimal?.toPrecision(
                                                        defaultPrecision(mintForm.upperPrice0By1Decimal)
                                                    )}
                                                    size="md"
                                                    errorInfo={getFieldValidateInfo(validateResult, 'rightPoint')}
                                                />
                                            </Card>
                                        </VStack>
                                    </HStack>
                                </VStack>
                            </Card>
                        </VStack>
                    </Stack>
                )}
                {isShowError && <ErrorInfo content={validateResult && validateResult[1]} mt="13px !important"></ErrorInfo>}
                <Box h="37px"></Box>

                <CustomButton
                    hidden={!poolExist || !mintForm.isTapMode}
                    disabled={validateResult !== undefined || !(mintForm.amount0DecimalDesired > 0 || mintForm.amount1DecimalDesired > 0)}
                    mt="-24px !important"
                    variant="purple"
                    text={t('Tap And Mint')}
                    w={{ base: '100%', sm: '469px' }}
                    h={{ base: '50px', sm: '62px' }}
                    fontSize="14px"
                    onClick={() => {
                        setShowTapConfirmModal(true);
                    }}
                />
                <CustomButton
                    hidden={![token0AllApproved, token1AllApproved].every(identity) || !poolExist || mintForm.isTapMode}
                    disabled={
                        validateResult !== undefined || (mintForm.amount0DecimalDesired === 0 && mintForm.amount1DecimalDesired === 0)
                    }
                    mt="-24px !important"
                    variant="purple"
                    text={t('Create')}
                    w={{ base: '100%', sm: '469px' }}
                    h={{ base: '50px', sm: '62px' }}
                    fontSize="14px"
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        if (noWrapToken) {
                            dispatch.popularMintForm
                                .mintLiquidity({
                                    account,
                                    chainId,
                                    liquidityManagerContract: liquidityManagerContract,
                                    gasPrice,
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                })
                                .then((e: any) => {
                                    if (mintForm.amount0Desired.gt(0)) {
                                        token0Entity.handleApproveSuccess();
                                    }
                                    if (mintForm.amount1Desired.gt(0)) {
                                        token1Entity.handleApproveSuccess();
                                    }
                                    console.log(e);
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'add liquidity successfully', undefined, toastLink);
                                })
                                .catch((e: any) => {
                                    console.log(e.message);
                                    toast('info', e.message);
                                });
                        } else {
                            dispatch.popularMintForm
                                .mintBoxLiquidity({
                                    account,
                                    chainId,
                                    boxContract,
                                    gasPrice,
                                    onGoingCallback: (toastLink?: ToastLink) => {
                                        toast('info', 'Ongoing ...', undefined, toastLink);
                                    },
                                })
                                .then((e: any) => {
                                    if (mintForm.amount0Desired.gt(0)) {
                                        token0Entity.handleApproveSuccess();
                                    }
                                    if (mintForm.amount1Desired.gt(0)) {
                                        token1Entity.handleApproveSuccess();
                                    }
                                    console.log(e);
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'add liquidity successfully', undefined, toastLink);
                                })
                                .catch((e: any) => {
                                    console.log(e.message);
                                    toast('info', e.message);
                                });
                        }
                    }}
                />

                <CustomButton
                    hidden={!(tokenFeeFilled && !poolExist)}
                    disabled={initPoolValidateResult !== undefined}
                    mt="-24px !important"
                    variant="purple"
                    text={t('Create Pool')}
                    w={{ base: '100%', sm: '469px' }}
                    h="62px"
                    fontSize="14px"
                    onClick={() => {
                        createPool({
                            account,
                            chainId,
                            liquidityManagerContract,
                            tokenA: mintForm.token0,
                            tokenB: mintForm.token1,
                            initPriceDecimalAByB: initPoolPriceDecimal,
                            fee: mintForm.fee,
                            gasPrice,
                            onGoingCallback: (toastLink?: ToastLink) => {
                                toast('info', 'Ongoing ...', undefined, toastLink);
                            },
                        }).then((e: any) => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;
                            if (chain) {
                                toastLink.title = 'View on ' + chain.name;
                                toastLink.link = getTxLink(e.transactionHash, chain);
                            }
                            toast('success', 'create pool successfully', undefined, toastLink);
                            fetchPoolInfo(mintForm.token0, mintForm.token1, mintForm.fee, mintForm.isTapMode, leftRange, rightRange);
                        });
                    }}
                />
                <CustomButton
                    hidden={
                        amount0Desired === '0' ||
                        token0Entity.isApproved(amount0Desired) ||
                        !account ||
                        !mintForm.token0.symbol ||
                        mintForm.amount0DecimalDesired === 0 ||
                        (tokenFeeFilled && !poolExist) ||
                        mintForm.isTapMode
                    }
                    mt="-24px !important"
                    variant="purple"
                    text={t('Approve') + ' ' + mintForm.token0.symbol}
                    w={{ base: '100%', sm: '469px' }}
                    h="62px"
                    fontSize="14px"
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;
                        token0Entity
                            .handleApprove()
                            .on('transactionHash', (hash: string) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(hash, chain);
                                }
                                toast('info', 'Ongoing', undefined, toastLink);
                            })
                            .then((e: any) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(e.transactionHash, chain);
                                }
                                toast('success', 'Approve successfully', undefined, toastLink);
                                token0Entity.handleApproveSuccess();
                            })
                            .catch((e: any) => {
                                console.info('error   :', e.message);
                            });
                    }}
                />
                {!token0NotWrapToken && (
                    <CustomButton
                        hidden={
                            token0Entity.isDepositApproved() ||
                            !account ||
                            !mintForm.token0.symbol ||
                            (tokenFeeFilled && !poolExist) ||
                            mintForm.isTapMode
                        }
                        mt="-24px !important"
                        variant="purple"
                        text={t('Approve Deposit') + ' ' + mintForm.token0.symbol}
                        w="469px"
                        h="62px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;

                            token0Entity
                                .handleDepositApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })

                                .then((e: any) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'Approve successfully', undefined, toastLink);
                                    token0Entity.handleDepositApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error:  ', e.message);
                                });
                        }}
                    />
                )}
                <CustomButton
                    hidden={
                        amount1Desired === '1' ||
                        token1Entity.isApproved(amount1Desired) ||
                        !account ||
                        !mintForm.token1.symbol ||
                        (tokenFeeFilled && !poolExist) ||
                        mintForm.amount1DecimalDesired == 0 ||
                        mintForm.isTapMode
                    }
                    mt="24px !important"
                    variant="purple"
                    text={t('Approve') + ' ' + mintForm.token1.symbol}
                    w={{ base: '100%', sm: '469px' }}
                    h="62px"
                    fontSize="14px"
                    onClick={() => {
                        const chain = getChain(chainId);
                        const toastLink = {} as ToastLink;

                        token1Entity
                            .handleApprove()
                            .on('transactionHash', (hash: string) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(hash, chain);
                                }
                                toast('info', 'Ongoing', undefined, toastLink);
                            })
                            .then((e: any) => {
                                if (chain) {
                                    toastLink.title = 'View on ' + chain.name;
                                    toastLink.link = getTxLink(e.transactionHash, chain);
                                }
                                toast('success', 'Approve successfully', undefined, toastLink);
                                token1Entity.handleApproveSuccess();
                            })
                            .catch((e: any) => {
                                console.info('error   :', e.message);
                            });
                    }}
                />
                {!token1NotWrapToken && (
                    <CustomButton
                        hidden={
                            token1Entity.isDepositApproved() ||
                            !account ||
                            !mintForm.token1.symbol ||
                            (tokenFeeFilled && !poolExist) ||
                            mintForm.isTapMode
                        }
                        mt="24px !important"
                        variant="purple"
                        text={t('Approve Deposit') + ' ' + mintForm.token1.symbol}
                        w="469px"
                        h="62px"
                        fontSize="14px"
                        onClick={() => {
                            const chain = getChain(chainId);
                            const toastLink = {} as ToastLink;

                            token1Entity
                                .handleDepositApprove()
                                .on('transactionHash', (hash: string) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(hash, chain);
                                    }
                                    toast('info', 'Ongoing', undefined, toastLink);
                                })
                                .then((e: any) => {
                                    if (chain) {
                                        toastLink.title = 'View on ' + chain.name;
                                        toastLink.link = getTxLink(e.transactionHash, chain);
                                    }
                                    toast('success', 'Approve deposit successfully', undefined, toastLink);

                                    token1Entity.handleDepositApproveSuccess();
                                })
                                .catch((e: any) => {
                                    console.info('error:  ', e.message);
                                });
                        }}
                    />
                )}
            </VStack>

            {showTapConfirmModal && (
                <TapConfirmPopularModal
                    isOpen={showTapConfirmModal}
                    onClose={() => {
                        dispatch.popularMintForm.setTapModeStatus(TapModeStatus.Modified);
                        setShowTapConfirmModal(false);
                    }}
                    currentPoint={Number(poolState.currentPoint)}
                    onSuccess={() => {}}
                />
            )}
        </>
    );
};

export default AddPopularLiquidityForm;
