import { VStack, HStack, Text, Flex, Image, Icon, useColorMode, Stack, useBreakpointValue } from '@chakra-ui/react';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { FiExternalLink } from 'react-icons/fi';
import { TokenInfoFormatted, useTokenListFormatted } from '../../../hooks/useTokenListFormatted';
import { i_text_copy } from '../../../style';
import { getColorThemeSelector, viewTrans } from '../../../utils/funcs';
import { FeeRate } from '../../Trade/components/FeeRate';
import { TokenIcons } from '../../Trade/components/TokenIcons';
import { Pagination } from './Pagination';
import { formatNumber } from '../../../utils/tokenMath';
import moment from 'moment';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { TransListSetting } from '../OrderHistory';
import { TransRecordTypeEnum } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { Loading } from '../../components/Loading';
import { useTranslation } from 'react-i18next';
import useIsMobile from '../../../iZUMi-UI-toolkit/src/hooks/useIsMobile';

export interface HistoryListProps {
    data: [];
    netWorkloading: boolean;
    totalPage: number;
    setting: TransListSetting;
    setCurrentPage: (page: number) => void;
    setPageSize: (pageSize: number) => void;
}
const LimitOrderList: React.FC<HistoryListProps> = (pros) => {
    const { data, netWorkloading, totalPage, setting, setCurrentPage, setPageSize } = pros;
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const isXl = useBreakpointValue({ base: false, sm: true, xl: false, '2xl': false });

    const { loading, tokenList } = useTokenListFormatted();

    const getIcon = (symbol: string) => {
        const tempToken = {
            ...tokenList.find((e) => e.symbol === symbol),
        } as unknown as any;
        return tempToken.icon ?? '/assets/tokens/default.svg';
    };

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const tabs = [
        [t('Pool/fees'), isXl ? '222px' : '260px'],
        [t('Type'), isXl ? '69px' : '100px'],
        [t('Transaction'), isXl ? '324px' : '360px'],
        [t('Order Date'), isXl ? '177px' : '180px'],
        ['', '108px'],
    ];

    return isMobile ? (
        <Card
            variant="deep2"
            w={{ base: '100%', sm: '1150px' }}
            alignItems="left"
            pt="12px"
            pb="16px"
            mt={{ base: '10px', sm: '23px' }}
            mb={{ base: '80px', sm: '0px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
        >
            <VStack spacing="11px" alignItems="left">
                {(netWorkloading || loading) && (
                    <Flex alignItems="center" justifyContent="center" pt="40px">
                        <Loading />
                    </Flex>
                )}

                {!netWorkloading &&
                    !loading &&
                    data &&
                    data.map((item: any, i: number) => {
                        return (
                            <Stack direction={{ base: 'column', sm: 'row' }} key={i}>
                                <HStack w="260px" mt="2px !important" cursor="pointer" px="32px">
                                    <TokenIcons
                                        tokenA={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenX,
                                                symbol: item.tokenX,
                                                icon: getIcon(item.tokenX),

                                                address: item.tokenX_address,
                                                decimal: item.tokenX,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        tokenB={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenY,
                                                symbol: item.tokenY,
                                                icon: getIcon(item.tokenY),

                                                address: item.tokenY_address,
                                                decimal: item.tokenY,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        initialToggle={false}
                                    />

                                    <FeeRate
                                        tokenA={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenX,
                                                symbol: item.tokenX,
                                                icon: getIcon(item.tokenX),
                                                address: item.tokenX_address,
                                                decimal: item.tokenX,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        tokenB={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenY,
                                                symbol: item.tokenY,
                                                icon: getIcon(item.tokenY),
                                                address: item.tokenY_address,
                                                decimal: item.tokenY,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        feeTier={(item.fee / 10000).toFixed(2) as any}
                                        initialToggle={false}
                                        direction="row"
                                        variant="purple"
                                    />
                                    <HStack w={{ base: 'unset', sm: '100px' }}>
                                        <Flex h="24px" py="6px" px="10px" bg={colorTheme('secondary.50', '#2E2650')} borderRadius="3px">
                                            <Text className={i_text_copy} fontSize="12px">
                                                {item.type === TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER && 'Add'}
                                                {item.type === TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER && 'Dec'}
                                            </Text>
                                        </Flex>
                                    </HStack>
                                </HStack>
                                <Stack
                                    bg={colorTheme('linear-gradient(180deg, #F5F7FF 0%, rgba(245, 247, 255, 0.59) 100%)', 'inherit')}
                                    px="32px"
                                    py="10px"
                                >
                                    <HStack w={{ base: 'unset', sm: '360px' }}>
                                        <Flex>
                                            <Text className={i_text_copy} fontSize="13px" mr="5px" w="80px">
                                                {'sell'}
                                            </Text>
                                            <Stack>
                                                <HStack>
                                                    <Image
                                                        boxSize="15px"
                                                        mr="6px"
                                                        src={getIcon(item.sellXEarnY ? item.tokenX : item.tokenY)}
                                                    ></Image>

                                                    <Text className={i_text_copy} fontSize="13px">
                                                        {formatNumber(item.amount, 2, 5, true)}{' '}
                                                        {item.sellXEarnY ? item.tokenX : item.tokenY}
                                                        {' ' + '@' + ' '}
                                                    </Text>
                                                </HStack>
                                                <HStack>
                                                    <Text className={i_text_copy} fontSize="13px">
                                                        {formatNumber(item.sellXEarnY ? item.price : 1 / item.price, 2, 2, true)}
                                                    </Text>

                                                    <Image
                                                        boxSize="15px"
                                                        mx="6px"
                                                        src={getIcon(item.sellXEarnY ? item.tokenY : item.tokenX)}
                                                    ></Image>
                                                    <Text className={i_text_copy} fontSize="13px" mr="5px">
                                                        {'/'}
                                                    </Text>
                                                    <Image
                                                        boxSize="15px"
                                                        mr="6px"
                                                        src={getIcon(item.sellXEarnY ? item.tokenX : item.tokenY)}
                                                    ></Image>
                                                </HStack>
                                            </Stack>
                                        </Flex>
                                    </HStack>

                                    <HStack w={{ base: 'unset', sm: '180px' }} pt={{ base: '10px', sm: '0px' }}>
                                        <Text className={i_text_copy} fontSize="13px" w="80px">
                                            {'Time'}
                                        </Text>
                                        <Text className={i_text_copy}>
                                            {moment(Number(item.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                        </Text>
                                    </HStack>

                                    <HStack
                                        cursor="pointer"
                                        pt={{ base: '10px', sm: '0px' }}
                                        onClick={() => {
                                            viewTrans(item.chainId, item.txHash);
                                        }}
                                    >
                                        <Text color={colorTheme('#0166FF', '#B08EFF')} className={i_text_copy} fontSize="13px" w="75px">
                                            {truncateWalletAddress(item.txHash, 5, 2)}
                                        </Text>
                                        <Icon
                                            as={FiExternalLink}
                                            boxSize="16px"
                                            onClick={() => {}}
                                            color={colorTheme('#0166FF', '#B08EFF')}
                                        />
                                    </HStack>
                                </Stack>
                            </Stack>
                        );
                    })}

                <Pagination
                    totalPage={totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                    alignItems="center"
                />
            </VStack>
        </Card>
    ) : (
        <Card
            variant="deep2"
            w={{ base: '100%', xxl: '1100px', xlp1: '1150px' }}
            minW={{ base: '100%', sm: '810px' }}
            alignItems="left"
            p={{ base: '10px', sm: '30px 20px 30px 20px', xxl: '30px 40px 30px 40px' }}
            mt={{ base: '10px', sm: '23px' }}
            mb={{ base: '80px', sm: '0px' }}
            color={colorTheme('tertiary.700', 'tertiary.200')}
        >
            <VStack spacing="11px" alignItems="left">
                {!isMobile && (
                    <HStack mb="10px">
                        {tabs.map((e: any, i: any) => {
                            return (
                                <Text key={i} w={e[1]} className={i_text_copy} color={colorTheme('#9B8FAE', 'tertiary.100')}>
                                    {e[0]}
                                </Text>
                            );
                        })}
                    </HStack>
                )}
                {(netWorkloading || loading) && (
                    <Flex alignItems="center" justifyContent="center" pt="40px">
                        <Loading />
                    </Flex>
                )}

                {!netWorkloading &&
                    !loading &&
                    data &&
                    data.map((item: any, i: number) => {
                        return (
                            <Stack direction={{ base: 'column', sm: 'row' }} key={i}>
                                <HStack w={isXl ? '245px' : '260px'} mt="2px !important" cursor="pointer">
                                    <TokenIcons
                                        tokenA={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenX,
                                                symbol: item.tokenX,
                                                icon: getIcon(item.tokenX),

                                                address: item.tokenX_address,
                                                decimal: item.tokenX,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        tokenB={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenY,
                                                symbol: item.tokenY,
                                                icon: getIcon(item.tokenY),

                                                address: item.tokenY_address,
                                                decimal: item.tokenY,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        initialToggle={false}
                                        type={isXl ? 'small' : ''}
                                    />

                                    <FeeRate
                                        tokenA={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenX,
                                                symbol: item.tokenX,
                                                icon: getIcon(item.tokenX),
                                                address: item.tokenX_address,
                                                decimal: item.tokenX,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        tokenB={
                                            {
                                                chainId: item.chainId,
                                                name: item.tokenY,
                                                symbol: item.tokenY,
                                                icon: getIcon(item.tokenY),
                                                address: item.tokenY_address,
                                                decimal: item.tokenY,
                                                custom: false,
                                            } as unknown as TokenInfoFormatted
                                        }
                                        feeTier={(item.fee / 10000).toFixed(2) as any}
                                        initialToggle={false}
                                        direction="row"
                                        variant="purple"
                                        type={isXl ? 'small' : ''}
                                        ml={isXl ? '0px !important' : '8px !important'}
                                    />
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '80px', xxl: '100px' }} pt={{ base: '10px', sm: '0px' }}>
                                    <Flex h="24px" py="6px" px="10px" bg={colorTheme('secondary.50', '#2E2650')} borderRadius="3px">
                                        <Text className={i_text_copy} fontSize="12px">
                                            {item.type === TransRecordTypeEnum.IZI_SWAP_INC_LIMIT_ORDER && 'Add'}
                                            {item.type === TransRecordTypeEnum.IZI_SWAP_DEC_LIMIT_ORDER && 'Dec'}
                                        </Text>
                                    </Flex>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '360px' }} pt={{ base: '10px', sm: '0px' }}>
                                    <Flex>
                                        <Text className={i_text_copy} fontSize="13px" mr="5px">
                                            {'sell'}
                                        </Text>

                                        <Image boxSize="15px" mr="6px" src={getIcon(item.sellXEarnY ? item.tokenX : item.tokenY)}></Image>

                                        <Text className={i_text_copy} fontSize="13px">
                                            {formatNumber(item.amount, 2, 5, true)} {item.sellXEarnY ? item.tokenX : item.tokenY}
                                            {' ' + '@' + ' '}
                                            {formatNumber(item.sellXEarnY ? item.price : 1 / item.price, 2, 2, true)}
                                        </Text>

                                        <Image boxSize="15px" mx="6px" src={getIcon(item.sellXEarnY ? item.tokenY : item.tokenX)}></Image>
                                        <Text className={i_text_copy} fontSize="13px" mr="5px">
                                            {'/'}
                                        </Text>
                                        <Image boxSize="15px" mr="6px" src={getIcon(item.sellXEarnY ? item.tokenX : item.tokenY)}></Image>
                                    </Flex>
                                </HStack>

                                <HStack w={{ base: 'unset', sm: '180px' }} pt={{ base: '10px', sm: '0px' }}>
                                    <Text className={i_text_copy}>
                                        {moment(Number(item.timestamp) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                                    </Text>
                                </HStack>

                                <HStack
                                    cursor="pointer"
                                    pt={{ base: '10px', sm: '0px' }}
                                    onClick={() => {
                                        viewTrans(item.chainId, item.txHash);
                                    }}
                                >
                                    <Text color={colorTheme('#0166FF', '#B08EFF')} className={i_text_copy} fontSize="13px" w="75px">
                                        {truncateWalletAddress(item.txHash, 5, 2)}
                                    </Text>
                                    <Icon as={FiExternalLink} boxSize="16px" onClick={() => {}} color={colorTheme('#0166FF', '#B08EFF')} />
                                </HStack>
                            </Stack>
                        );
                    })}

                <Pagination
                    totalPage={totalPage}
                    currentPage={setting.page}
                    pageSize={setting.pageSize}
                    setPageSize={setPageSize}
                    setCurrentPage={setCurrentPage}
                    mt="50px !important"
                />
            </VStack>
        </Card>
    );
};
export default LimitOrderList;
