import React from 'react';
import { observable } from 'mobx';
import i18n from '../i18n';

type Language = 'en' | 'zh-CN' | 'zh-TW' | 'zh-JAPAN';

interface Store {
    language: Language;
    setLanguage: (newLanguage: Language) => void;
}

export const languageStore: Store = observable({
    language: 'en' as Language,
    setLanguage: function (newLanguage) {
        this.language = newLanguage;
        i18n.changeLanguage(newLanguage);
    },
});

export default React.createContext(languageStore);
