import { BoxProps, useColorMode, HStack, VStack, Text, Image, Center, Tooltip, Stack } from '@chakra-ui/react';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_copy_bold, i_text_copy } from '../../../style';
import Card from '../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { formatNumber } from '../../../utils/tokenMath';
import { InfoOutlineIcon } from '@chakra-ui/icons';
import { FARM_CONFIG } from '../../../config/bizConfig';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useTranslation } from 'react-i18next';

type ModalAprProps = {
    beforeBoostAPR: number;
    afterBoostAPR: number;
    baseAPR?: number;
} & BoxProps;

export const ModalAprBlock: React.FC<ModalAprProps> = (props) => {
    const { baseAPR, beforeBoostAPR, afterBoostAPR, ...rest } = props;
    const { t } = useTranslation();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useWeb3WithDefault();

    return (
        <Center
            w="100%"
            bg={colorTheme(
                'linear-gradient(180deg, #F5F5F5 0%, rgba(241, 241, 241, 0.72) 100%)',
                'linear-gradient(179.48deg, #40325C 3.65%, #2C1E42 42.65%, #221739 92.15%)'
            )}
            {...rest}
        >
            <Stack h={{ base: 'unset', sm: '155px' }} direction={{ base: 'column', sm: 'row' }} alignItems="center" spacing="20px">
                <Card variant="deep2" w="130px" h="60px" mt={{ base: '20px', sm: '0px' }}>
                    <VStack alignItems="center" pt="10px">
                        <Text className={i_text_copy} color={colorTheme('tertiary.400', 'tertiary.400')}>
                            {t('Current APR')}
                        </Text>
                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.600', 'tertiary.100')} fontSize="18px">
                            {formatNumber(beforeBoostAPR * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0))} %
                        </Text>
                    </VStack>
                </Card>

                <Image w="24px" h="24px" src={process.env.PUBLIC_URL + '/assets/farm/lightTo.svg'} />

                <Card variant="deep2" w={{ base: '200px', sm: '258px' }} h="87px">
                    <VStack alignItems="center" pt="15px" spacing="15px">
                        <HStack alignItems="center">
                            <Text className={i_text_copy} color={colorTheme('secondary.500', 'secondary.300')} fontSize="16px">
                                {t('Boosted APR') + ' '} {baseAPR && `(${formatNumber(afterBoostAPR / baseAPR, 0, 2)}x)`}
                            </Text>
                            <Tooltip
                                label={t(
                                    'The multiplier only considers the rewards provided by izumi, the fees gained in UniswapV3 is added directly to the APR.'
                                )}
                            >
                                <InfoOutlineIcon />
                            </Tooltip>
                        </HStack>

                        <Text className={i_text_copy_bold} color={colorTheme('tertiary.600', 'tertiary.100')} fontSize="32px">
                            {formatNumber(afterBoostAPR * 100 + (FARM_CONFIG.APR_FEE_GAINS[chainId] ?? 0))} %
                        </Text>
                    </VStack>
                </Card>
            </Stack>
        </Center>
    );
};
