import React from 'react';
import { chakra, HStack, IconButton } from '@chakra-ui/react';
import { RepeatIcon } from '@chakra-ui/icons';
import Info from '../../../../../iZUMi-UI-toolkit/src/components/Info/Info';
import { Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { formatNumber } from '../../../../../utils/tokenMath';
import { useBreakpointValue } from '@chakra-ui/media-query';
import { MiningPoolData, MiningPoolMeta, LiquidityEntry } from '../../../../../state/models/farm/iZiSwap/fixRange/types';
import { AdaptationMode } from '../../../../../components/layout/PageLayout';

function isLiquidityEntry(data: MiningPoolData | LiquidityEntry): data is LiquidityEntry {
    return 'nftId' in data;
}

export const RewardOrPriceRange = chakra(
    ({
        data,
        meta,
        label,
        handleToggle,
        type,
        ...props
    }: {
        data: MiningPoolData | LiquidityEntry;
        meta: MiningPoolMeta;
        handleToggle?: () => void;
        label: string;
        type?: AdaptationMode;
    }) => {
        const oneLineMode = useBreakpointValue({ base: false, lg: true })!;

        let min!: number;
        let max!: number;
        if (isLiquidityEntry(data)) {
            min = data.minPriceDecimal;
            max = data.maxPriceDecimal;
        } else {
            min = data.rewardMinPriceDecimalAByB;
            max = data.rewardMaxPriceDecimalAByB;
        }
        const range =
            min === undefined && max === undefined
                ? '...'
                : formatNumber(meta.initialToggle ? 1 / max : min, 4, 4) + '-' + formatNumber(meta.initialToggle ? 1 / min : max, 4, 4);
        return type === 'mobile' ? (
            <Info
                direction={oneLineMode ? 'column' : 'row'}
                label={label}
                labelIcon={
                    <IconButton size="sm" isRound={true} aria-label="" icon={<RepeatIcon />} variant="ghost" onClick={handleToggle} />
                }
                value={
                    <HStack whiteSpace="nowrap">
                        <Text variant="caption">{range}</Text>
                    </HStack>
                }
                labelStyle={{ color: '#A398BD' }}
                {...props}
            />
        ) : (
            <Info
                direction={oneLineMode ? 'column' : 'row'}
                label={label}
                labelIcon={
                    <IconButton size="sm" isRound={true} aria-label="" icon={<RepeatIcon />} variant="ghost" onClick={handleToggle} />
                }
                tooltip={`1 ${meta.initialToggle ? meta.tokenB.symbol : meta.tokenA.symbol} = ${range} ${
                    meta.initialToggle ? meta.tokenA.symbol : meta.tokenB.symbol
                }`}
                // tooltip={`${range} ${
                //     meta.initialToggle ? meta.tokenB.symbol : meta.tokenA.symbol
                // } /  ${meta.initialToggle ? meta.tokenA.symbol : meta.tokenB.symbol}`}
                value={
                    <HStack whiteSpace="nowrap">
                        <Text variant="caption">{range}</Text>
                    </HStack>
                }
                {...props}
            />
        );
    }
);
