import { HStack, VStack, useColorMode, BoxProps } from '@chakra-ui/react';
import CustomButton from '../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { CustomNumberInput } from '../../../iZUMi-UI-toolkit/src/components/Inputs/NumberInput/NumberInput';
import React, { useState } from 'react';
import { Text } from '../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { getColorThemeSelector } from '../../../utils/funcs';
import { i_text_copy_bold, i_text_d } from '../../../style';
import { TokenSymbol } from '../../../types/mod';
import { formatNumber } from '../../../utils/tokenMath';
import { isNumeric } from '../../../utils/valid';

type BoostAmountInputProps = {
    token: TokenSymbol;
    maxRequire: number | string | null;
    price?: number | null;
    inputValue: number;
    handleSetValue?: (v: any) => void;
    setBoostRate: (v: number) => void;
    errorInfo?: string;
    fontClass?: any;
} & BoxProps;

export const BoostAmountInput: React.FC<BoostAmountInputProps> = (props) => {
    const { token, maxRequire, price, handleSetValue, setBoostRate, errorInfo, inputValue, ...rest } = props;

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    return (
        <VStack spacing="0" w="100%" h="100%">
            <HStack pl="4px" h="49px" w="100%" justifyContent="space-between">
                <CustomNumberInput onBlur={handleSetValue} errorInfo={errorInfo} inputValue={formatNumber(inputValue)} {...rest} />

                <Text variant="caption" color={colorTheme('tertiary.600', 'tertiary.400')} pr="6px !important" fontSize="12px" w="40%">
                    ~ {isNumeric(inputValue) ? formatNumber(inputValue * (price || 0)) : 0} USD
                </Text>
            </HStack>
            <HStack spacing="5px" w={{ base: '90%', sm: '70%' }} h="22px" justifyContent="space-between">
                {['Clear', '1.5x', '2x', '2.5x'].map((item, index) => (
                    <CustomButton
                        onClick={() => {
                            // if (item === 'Clear') {
                            //     item = 0;
                            // }
                            // if (item === 'MAX') {
                            //     item = 100;
                            // }
                            // handleSetValue?.(
                            //     (Number(item) / 100) * Number(balance)
                            // );
                            let rate = 1;

                            if (item === '1.5x') {
                                rate = 1.5;
                            }
                            if (item === '2x') {
                                rate = 2;
                            }
                            if (item === '2.5x') {
                                rate = 2.5;
                            }
                            setBoostRate(rate);
                        }}
                        key={index}
                        w="46px"
                        h="22px"
                        borderRadius="3px"
                        alignItems="center"
                        justifyContent="center"
                        variant={item === 'Clear' ? 'orange' : 'primary2'}
                        text={typeof item === 'number' ? item + '%' : item}
                        fontClass={i_text_copy_bold}
                        fontSize="12px"
                    />
                ))}
            </HStack>
        </VStack>
    );
};
