import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmDynamicRangeContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    initialToggle?: boolean;

    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    contractVersion?: FarmDynamicRangeContractVersion;
    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    priceRangeRatio: number;
    isEnded?: boolean;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
              [ChainId.EthereumMainnet]: [
                  {
                      ...sortedToken(TokenSymbol.IZI, TokenSymbol.IUSD, ChainId.EthereumMainnet),
                      miningContract: '0xdc035e4b6fbc48103e213f9638a81defc9323b98',
                      feeTier: 0.3,
                      twoRewards: false,
                      iZiBoost: false,
                      veiZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 4,
                      contractVersion: FarmDynamicRangeContractVersion.VEIZI,
                      isEnded: true,
                  },
                  {
                      ...sortedToken(TokenSymbol.BIT, TokenSymbol.ETH, ChainId.EthereumMainnet),
                      miningContract: '0x48737f645e1e8dD67CAe1311b2c42e6935c6A5E1',
                      feeTier: 0.3,
                      twoRewards: false,
                      iZiBoost: false,
                      veiZiBoost: true,
                      noFee: false,
                      feeCharged: 1,
                      priceRangeRatio: 1.25,
                      contractVersion: FarmDynamicRangeContractVersion.VEIZI,
                      isEnded: true,
                  },
              ],
              [ChainId.Matic]: [
                  {
                      ...sortedToken(TokenSymbol.DDAO, TokenSymbol.WETH, ChainId.Matic),
                      miningContract: '0xaFD5f7a790041761F33bFbf3dF1b54DF272F2576',
                      feeTier: 0.3,
                      twoRewards: true,
                      iZiBoost: true,
                      noFee: false,
                      feeCharged: 0.4,
                      priceRangeRatio: 4,
                      isEnded: true,
                  },
              ],
          }
        : {
              [ChainId.Rinkeby]: [],
              [ChainId.BSCTestnet]: [
                  {
                      ...sortedToken(TokenSymbol.IUSD, TokenSymbol.IZI, ChainId.BSCTestnet),
                      miningContract: '0x38572CB3624beD7Fe03fA3bbF173f87c0c27b985',
                      feeTier: 0.3,
                      twoRewards: false,
                      iZiBoost: false,
                      veiZiBoost: true,
                      contractVersion: FarmDynamicRangeContractVersion.VEIZI,
                      noFee: false,
                      feeCharged: 0.4,
                      priceRangeRatio: 4,
                  },
              ],
          };
