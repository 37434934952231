import { BoxProps, HStack, Icon, IconButton, Stack, Text, Tooltip, useColorMode, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { RiskEnum } from '../../../../../config/bizConfig';
import useIsMobile from '../../../../../hooks/useIsMobile';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import CustomButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import { i_text_copy, i_text_d } from '../../../../../style';
import { getColorThemeSelector, toFeeNumber } from '../../../../../utils/funcs';
import { formatNumber } from '../../../../../utils/tokenMath';
import { FeeRate } from '../../../components/FeeRate';
import { TokenIcons } from '../../../components/TokenIcons';
import Risk from './Risk';

type PopularEntryProps = {
    index: number;
    poolIndex: number;
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    fee: number;
    leftRange: number;
    rightRange: number;
    apr: number;
    slideApr: number;
    level: RiskEnum;
    setSelect: any;
    setSelectPoolIndex: any;
} & BoxProps;

const PopularEntry: React.FC<PopularEntryProps> = (props) => {
    const { index, poolIndex, tokenA, tokenB, fee, leftRange, rightRange, apr, slideApr, level, setSelect, setSelectPoolIndex } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { t } = useTranslation();
    const isMobile = useIsMobile();

    return isMobile ? (
        <Stack>
            <HStack flex="20">
                <TokenIcons tokenA={tokenA} tokenB={tokenB} />
                <FeeRate direction="row" tokenA={tokenA} tokenB={tokenB} feeTier={toFeeNumber(fee) as FeeTier} />
            </HStack>
            <HStack flex="20" spacing="4px" justifyContent="space-between">
                <HStack spacing="4px">
                    <Text className={i_text_copy} color={colorTheme('#6A5E86', '#89809F')}>
                        Range
                    </Text>
                    <Tooltip
                        label={t('Range means the price range that liquidity will be added uniformly in it around the current price.')}
                    >
                        <IconButton
                            w="16px"
                            minW="16px"
                            h="16px"
                            px="0px"
                            bg="unset"
                            aria-label=""
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                            color={colorTheme('#6A5E86', '#89809F')}
                        />
                    </Tooltip>
                </HStack>
                <Text
                    className={i_text_d}
                    lineHeight="17px"
                    letterSpacing="0.015em !important"
                    color={colorTheme('#000000', '#FBFBFB')}
                    fontWeight="600"
                    fontFamily="Montserrat"
                    fontSize="14px !important"
                >
                    {leftRange * 100 + '%'} ~ {'+' + rightRange * 100 + '%'}
                </Text>
            </HStack>
            <HStack flex="20" spacing="4px" justifyContent="space-between">
                <Text className={i_text_copy} color={colorTheme('#6A5E86', '#89809F')}>
                    Fee APR
                </Text>
                <Text
                    className={i_text_d}
                    lineHeight="17px"
                    letterSpacing="0.015em !important"
                    fontSize="14px !important"
                    color={colorTheme('#000000', '#FBFBFB')}
                >
                    {formatNumber(slideApr * 100, 2, 2, true) + '%'}
                </Text>
            </HStack>
            <HStack flex="20" spacing="4px">
                <HStack w="100%" justifyContent="space-between">
                    <HStack spacing="4px">
                        <Text className={i_text_copy} color={colorTheme('#6A5E86', '#89809F')}>
                            ImLoss
                        </Text>
                        <Tooltip
                            label={t(
                                'Impermanent loss risk level. Remember that mpermanent loss is temporal! High means the current price may cross the selected range in 1 month with a large probability at least one time. Medium means with probability lower than 50%, and low means with probability lower than 20%.'
                            )}
                        >
                            <IconButton
                                w="16px"
                                minW="16px"
                                h="16px"
                                px="0px"
                                bg="unset"
                                aria-label=""
                                icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                                color={colorTheme('#6A5E86', '#89809F')}
                            />
                        </Tooltip>
                    </HStack>
                    <Risk level={level}></Risk>
                </HStack>
            </HStack>
            <CustomButton
                variant="purple"
                w={{ base: 'unset', xl: '104px' }}
                h="30px"
                text={'Select'}
                className={i_text_d}
                fontWeight="600"
                px="10px"
                py="8px"
                borderRadius="4px"
                mt="10px !important"
                onClick={() => {
                    setSelect(index);
                    setSelectPoolIndex(poolIndex);
                }}
            ></CustomButton>
        </Stack>
    ) : (
        <HStack>
            <HStack flex="20">
                <TokenIcons tokenA={tokenA} tokenB={tokenB} />
                <FeeRate tokenA={tokenA} tokenB={tokenB} feeTier={toFeeNumber(fee) as FeeTier} />
            </HStack>
            <Stack flex="20" spacing="4px">
                <HStack spacing="4px">
                    <Text className={i_text_copy} color={colorTheme('#6A5E86', '#89809F')}>
                        Range
                    </Text>
                    <Tooltip
                        label={t('Range means the price range that liquidity will be added uniformly in it around the current price.')}
                    >
                        <IconButton
                            w="16px"
                            minW="16px"
                            h="16px"
                            px="0px"
                            bg="unset"
                            aria-label=""
                            icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                            color={colorTheme('#6A5E86', '#89809F')}
                        />
                    </Tooltip>
                </HStack>
                <Text
                    className={i_text_d}
                    lineHeight="17px"
                    letterSpacing="0.015em !important"
                    color={colorTheme('#000000', '#FBFBFB')}
                    fontWeight="600"
                    fontFamily="Montserrat"
                    fontSize="14px !important"
                >
                    {leftRange * 100 + '%'} ~ {'+' + rightRange * 100 + '%'}
                </Text>
            </Stack>
            <Stack flex="20" spacing="4px">
                <Text className={i_text_copy} color={colorTheme('#6A5E86', '#89809F')}>
                    Fee APR
                </Text>
                <Text
                    className={i_text_d}
                    lineHeight="17px"
                    letterSpacing="0.015em !important"
                    fontSize="14px !important"
                    color={colorTheme('#000000', '#FBFBFB')}
                >
                    {formatNumber(slideApr * 100, 2, 2, true) + '%'}
                </Text>
            </Stack>
            <Stack flex="20" spacing="4px">
                <VStack w="60px">
                    <HStack spacing="4px">
                        <Text className={i_text_copy} color={colorTheme('#6A5E86', '#89809F')}>
                            ImLoss
                        </Text>
                        <Tooltip
                            label={t(
                                'Impermanent loss risk level. Remember that mpermanent loss is temporal! High means the current price may cross the selected range in 1 month with a large probability at least one time. Medium means with probability lower than 50%, and low means with probability lower than 20%.'
                            )}
                        >
                            <IconButton
                                w="16px"
                                minW="16px"
                                h="16px"
                                px="0px"
                                bg="unset"
                                aria-label=""
                                icon={<Icon as={AiOutlineInfoCircle} boxSize="16px" />}
                                color={colorTheme('#6A5E86', '#89809F')}
                            />
                        </Tooltip>
                    </HStack>
                    <Risk level={level}></Risk>
                </VStack>
            </Stack>
            <CustomButton
                variant="purple"
                w={{ base: 'unset', xl: '104px' }}
                h="30px"
                text={'Select'}
                className={i_text_d}
                fontWeight="600"
                px="10px"
                py="8px"
                borderRadius="4px"
                onClick={() => {
                    setSelect(index);
                    setSelectPoolIndex(poolIndex);
                }}
            ></CustomButton>
        </HStack>
    );
};
export default PopularEntry;
