import Web3 from 'web3';
import { getChain } from '../../../../../config/chains';
import { isGasOrWrappedGasToken, isGasToken, tokenSymbol2token } from '../../../../../config/tokens';
import { TokenInfoFormatted } from '../../../../../hooks/useTokenListFormatted';
import { Weth9DepositWithdrawContract } from '../../../../../types/abis/Weth9DepositWithdraw';
import { ChainId, TokenSymbol } from '../../../../../types/mod';
import { getWeth9DepositWithdrawContract } from '../../../../../utils/contractFactory';
import { BasePathQueryPlugin, TokenSpenderInfo } from '../BaseDexPlugin';
import { Path, PathQuery, PathQueryResult, PreQueryResult, SwapDirection } from '../utils';

enum Mode {
    deposit = 0,
    withdraw = 1,
    unknown = 2
}
export class WethPathQueryPlugin extends BasePathQueryPlugin {

    private chainId: ChainId
    private tokenIn: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private tokenOut: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted
    private wrappedChainToken: TokenInfoFormatted = undefined as unknown as TokenInfoFormatted

    private wrappedChainTokenContract: Weth9DepositWithdrawContract;
    private mode: Mode = Mode.unknown;

    private getWrappedChainToken(): TokenInfoFormatted {
        const chain = getChain(this.chainId);
        const symbol = chain?.token.symbol;
        if (!symbol) {
            return undefined as unknown as TokenInfoFormatted;
        }
        const token = tokenSymbol2token(symbol, this.chainId);
        const wrappedSymbol = chain?.wrappedTokenSymbol ?? token.symbol;
        return { ...token, symbol: wrappedSymbol as TokenSymbol };
    }

    public constructor(preQueryResult: PreQueryResult, config: any, chainId: ChainId, web3: Web3) {
        super(preQueryResult);
        this.chainId = chainId;
        this.wrappedChainToken = this.getWrappedChainToken();
        this.wrappedChainTokenContract = getWeth9DepositWithdrawContract(
            web3, this.wrappedChainToken.address
        ) as Weth9DepositWithdrawContract;
    }


    private getMode(tokenIn: TokenInfoFormatted, tokenOut: TokenInfoFormatted): Mode {
        if (!isGasOrWrappedGasToken(tokenIn, this.chainId) || !isGasOrWrappedGasToken(tokenOut, this.chainId)) {
            return Mode.unknown;
        }
        if (isGasToken(tokenIn, this.chainId)) {
            if (!isGasToken(tokenOut, this.chainId)) {
                return Mode.deposit;
            }
        } else {
            if (isGasToken(tokenOut, this.chainId)) {
                return Mode.withdraw;
            }
        }
        return Mode.unknown;
    }
    
    override getPathQuery(tokenIn: TokenInfoFormatted, tokenOut: TokenInfoFormatted, direction: SwapDirection, amount: string): PathQuery[] {
        this.mode = this.getMode(tokenIn, tokenOut);
        if (this.mode === Mode.unknown) {
            return [] as PathQuery[];
        }
        const pathQueryResult = {
            amount,
            path: {
                tokenChain: [tokenIn, tokenOut],
                feeContractNumber: [0],
                feeRate: [0]
            } as Path,
            noSufficientLiquidity: false,
            initDecimalPriceEndByStart: 1,
            priceImpact: 0,
            feesDecimal: 0
        } as PathQueryResult;
        return [
            {
                path: pathQueryResult.path,
                pathQueryResult
            } as PathQuery,
        ];
    }

    override getTokenSpenderInfo(path: Path, direction: SwapDirection): TokenSpenderInfo {
        const tokenToPay = path.tokenChain[0];
        return {tokenToPay};
    }
    override getSwapTransaction(
        path: Path, 
        direction: SwapDirection, 
        amountIn: string, 
        amountOut: string, 
        account: string, 
        maxDelay: number,
        slippagePercent: number
    ): {calling: any, options: any} {
        if (this.mode === Mode.deposit) {
            return {
                calling: this.wrappedChainTokenContract.methods.deposit(),
                options: {
                    value: amountIn,
                }
            };
        } else if (this.mode === Mode.withdraw) {
            return {
                calling: this.wrappedChainTokenContract.methods.withdraw(amountIn),
                options: {}
            };
        } else {
            return {
                calling: undefined,
                options: {}
            };
        }
    }

}