import { ChevronDownIcon, SearchIcon } from '@chakra-ui/icons';
import {
    HStack,
    Input,
    InputGroup,
    InputRightElement,
    useColorMode,
    InputLeftElement,
    Button,
    FormControl,
    FormLabel,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Stack,
} from '@chakra-ui/react';
import { i_text_copy, i_text_copy_bold } from '../../../style';
import { useCallback } from 'react';
import { getColorThemeSelector } from '../../../utils/funcs';
import useIsMobile from '../../../hooks/useIsMobile';
import { useTranslation } from 'react-i18next';
import { Filter } from '../../Farm/Gallery/components/Filter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../state/store';

type ControlsProps = {
    dispatch: any;
};

const Controls: React.FC<ControlsProps> = (props) => {
    const { dispatch } = props;
    const { t } = useTranslation();

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const { pools } = useSelector((state: RootState) => state);

    const onSearchKeyChange = useCallback(
        (e: any) => {
            console.log(e.target.value);
            const poolsControl = { ...pools.poolsControl };
            poolsControl.searchKey = e.target.value;
            dispatch.setPoolsControl(poolsControl);
        },
        [pools, dispatch]
    );
    const onSortByChange = useCallback(
        (e: any) => {
            const poolsControl = { ...pools.poolsControl };
            poolsControl.sortBy = e;
            dispatch.setPoolsControl(poolsControl);
        },
        [pools, dispatch]
    );
    const SortByBlock = () => (
        <FormControl display="flex" alignItems="center" w="unset">
            <FormLabel className={i_text_copy} fontSize="12px" fontWeight="500" pt="6px">
                {t('Sort by')}
            </FormLabel>
            <Menu>
                {() => (
                    <>
                        <MenuButton
                            as={Button}
                            rightIcon={<ChevronDownIcon />}
                            className={i_text_copy_bold}
                            fontSize="14px"
                            w={{ base: '140px', sm: '168px' }}
                        >
                            {pools.poolsControl.sortBy || t('None')}
                        </MenuButton>
                        <MenuList className={i_text_copy}>
                            <MenuItem onClick={() => onSortByChange('Liquidity')}>{t('Liquidity')} </MenuItem>
                            <MenuItem onClick={() => onSortByChange('Volume(24h)')}>{t('Volume(24h)')} </MenuItem>
                            <MenuItem onClick={() => onSortByChange('APR')}>{t('APR')} </MenuItem>
                        </MenuList>
                    </>
                )}
            </Menu>
        </FormControl>
    );

    return isMobile ? (
        <HStack
            w="100%"
            alignItems="start"
            pt="16px"
            pb="8px"
            justifyContent={{ base: 'space-between' }}
            spacing={{ base: '10px', xxl: '20px' }}
        >
            <InputGroup h="35px" flex="1">
                <InputLeftElement boxSize="20px" mt="7px" ml="10px">
                    <SearchIcon boxSize={5} />
                </InputLeftElement>
                <Input
                    w="230px"
                    h="35px"
                    placeholder={t('Search Tokens or Pools')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e: any) => onSearchKeyChange(e)}
                    value={pools.poolsControl.searchKey || ''}
                    bg={colorTheme('#FFFFFF', 'rgba(37, 32, 59, 0.5)')}
                    color="tertiary.300"
                    letterSpacing="0.02em"
                    fontFamily="Montserrat"
                />
            </InputGroup>

            <Filter
                h="100%"
                ml="17px !important"
                alignItems="center"
                filterTitle={t('Pools Filter')}
                filterElement={[
                    <Stack ml="auto !important" key={4}>
                        <SortByBlock />
                    </Stack>,
                ]}
            ></Filter>
        </HStack>
    ) : (
        <HStack
            w="100%"
            alignItems="start"
            my="30px !important"
            justifyContent={{ base: 'space-between' }}
            spacing={{ base: '10px', xxl: '20px' }}
        >
            <InputGroup w={{ base: '280px', sm: '310px' }} h="41px" mt="-4px">
                <Input
                    variant="filled"
                    placeholder={t('Search Tokens or Pools')}
                    size="lg"
                    className={i_text_copy_bold}
                    fontSize="12px"
                    onChange={(e: any) => onSearchKeyChange(e)}
                    value={pools.poolsControl.searchKey}
                />
                <InputRightElement h="100%" mt="4px">
                    <SearchIcon boxSize={5} />
                </InputRightElement>
            </InputGroup>
        </HStack>
    );
};

export default Controls;
