export const isContract = async (web3: any, address: any) => {
    if (!address) return false;
    if (!web3) return false;

    const code = await web3.eth.getCode(address);
    if (code === '0x') {
        return false;
    }
    return true;
};
